(function () {
    'use strict';

    let _$rootScope;
    let _$route;
    let _$location;
    let _$routeParams;
    let _errorService;
    let _roleService;
    let _userType;

    fileUploadHelperService.$inject = [
        '$location',
        '$rootScope',
        '$route',
        '$routeParams',
        'errorService',
        'roleService',
        'userType'
    ];

    function fileUploadHelperService($location, $rootScope, $route, $routeParams, errorService, roleService, userType) {
        _$location = $location;
        _$rootScope = $rootScope;
        _$route = $route;
        _$routeParams = $routeParams;
        _errorService = errorService;
        _roleService = roleService;
        _userType = userType;

        return {
            getFirmId: getFirmId,
            handleError: handleError,
            isClientUserType: isClientUserType,
            reloadPage: reloadPage,
            redirectToFirmHomePage: redirectToFirmHomePage,
            triggerUploadComplete: triggerUploadComplete,
            triggerUploadStart: triggerUploadStart
        };
    }

    function getFirmId() {
        return _$routeParams.firmId;
    }

    function handleError(response, message) {
        _errorService.handleError(response, message);
    }

    function isClientUserType() {
        return _roleService.getUserType() === _userType.client;
    }

    function reloadPage() {
        _$route.reload()
    }

    function redirectToFirmHomePage(firmId) {
        _$location.path('/' + firmId);
    }

    function triggerUploadComplete() {
        _$rootScope.$broadcast('upload-complete');
    }

    function triggerUploadStart() {
        _$rootScope.$broadcast('upload-start');
    }

    module.exports = fileUploadHelperService;

})();