﻿(function () {

    'use strict';

    fileUploadService.$inject = [
        '$q',
        'fileShareApi'
    ];

    /**
     * @deprecated since version 4.0
     * @param {any} $q Angular promise.
     * @param {any} fileShareApi The File Share API service.
     * @returns {fileUploadService} The V3 file upload service.
     */
    function fileUploadService($q, fileShareApi) {

        let service = {
            chunkSize: 4 * 1024 * 1024,
            getAssignableUsers: getAssignableUsers,
            uploadComplete: uploadComplete,
            uploadFile: uploadFile
        };

        return service;

        function uploadFile(file, isSharedWithEveryone, recipientId, progressCallback) {
            return $q(function (resolve, reject) {
                let fileId;
                let hash;
                let timestamp;
                let chunkNumber = 1;
                let totalChunks = Math.ceil(file.size / service.chunkSize);

                function uploadChunk() {
                    let formData = new FormData();

                    formData.append('isSharedWithEveryone', isSharedWithEveryone);
                    formData.append('chunkNumber', chunkNumber);
                    formData.append('totalChunks', totalChunks);

                    if (chunkNumber > 1) {
                        formData.append('fileId', fileId);
                        formData.append('hash', hash);
                        formData.append('timestamp', timestamp);
                    }

                    if (!isSharedWithEveryone) {
                        formData.append('assignedToUserId', recipientId);
                    }

                    let slice = readChunk(file, chunkNumber);

                    formData.append('File', slice, slice.name);

                    fileShareApi.uploadFileChunk(formData)
                        .then(function (result) {
                            fileId = result.data.fileId;
                            timestamp = result.data.timestamp;
                            hash = result.data.hash;
                            let progress = Math.ceil(chunkNumber / totalChunks * 100);
                            progressCallback(file, progress);
                            ++chunkNumber;
                            if (chunkNumber <= totalChunks) {
                                uploadChunk();
                            } else {
                                resolve(file);
                            }
                        }, function (reason) {
                            reject({ file: file, reason: reason });
                        });
                }

                uploadChunk();

            });
        }

        function uploadComplete(formData) {
            return fileShareApi.uploadComplete(formData);
        }

        function readChunk(file, chunkNumber) {
            let start = (chunkNumber - 1) * service.chunkSize;
            let end = start + service.chunkSize;

            let blob = file.slice(start, end);
            blob.name = file.name;
            return blob;
        }

        function getAssignableUsers() {
            return fileShareApi.getAssignableUsers();
        }
    }

    module.exports = fileUploadService;
})();