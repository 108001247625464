﻿(function () {

    'use strict';

    fsUserDeleteConfirmDialog.$inject = [
        'growl',
        'errorService',
        'userService'
    ];

    function fsUserDeleteConfirmDialog(growl, errorService, userService) {
        return {
            restrict: 'A',
            scope: {
                title: '@',
                okButtonText: '@',
                okCallback: '&'
            },
            template: require('./fs-user-delete-confirm-dialog.html'),
            link: function (scope, element) {
                scope.deleteUser = function () {
                    let vm = scope.$parent.vm;
                    userService.deleteUser(scope.userId)
                        .then(function () {
                            growl.success('The user and their files have been removed.');
                            if (vm.users.length === 1 && vm.selectedPage > 1) {
                                vm.selectedPage--;
                            }
                            vm.updatePage(vm.selectedPage);
                        }, function (response) {
                            if (response === 'unable-to-delete-firm-administrator') {
                                growl.error('You cannot delete the primary administrator.');
                            } else if (response === 'unable-to-delete-last-user-visible-for-upload') {
                                growl.error('You cannot delete the last user with sharing option enabled.');
                            } else {
                                errorService.handleError(response);
                            }
                        });
                };
                scope.$on('user-delete-confirm-dialog', function (_event, args) {
                    userService.getUser(args.userId)
                        .then(function (user) {
                            scope.userId = user.id;
                            scope.userName = user.firstName + ' ' + user.lastName;
                            scope.userEmail = user.email;

                            let tooltip = element[0].querySelector('#user-delete-dialog-email');
                            angular.element(tooltip).tooltip('dispose').tooltip({title: user.email.length > 55 ? user.email : ''});
                            
                            scope.$broadcast('show-confirm-dialog');
                        }, errorService.handleError);
                });
            }
        };
    }

    module.exports = fsUserDeleteConfirmDialog;
})();