﻿(function () {
    'use strict';

    FileUploadStorageService.$inject = [
        'fileService',
        'firmService',
        'userService',
        '$q'
    ];

    function FileUploadStorageService(fileService, firmService, userService, $q) {
        let firmStorageAvailable = 0;
        let userStorageLimit = null;
        let userStorageUsed = 0;

        let storageAvailable = 0;

        let service = {
            getStorageAvailable: getStorageAvailable
        };

        return service;

        function getStorageAvailable() {
            return $q.all([
                    firmService.getStorageLimits().then(setFirmStorageLimits),
                    userService.getUserProfile().then(setUserProfile),
                    fileService.getTotalFileSizeByUser().then(setUserStorageLimit)
                ])
                .then(function () {
                    setStorageAvailable();
                    return storageAvailable;
                });
        }

        function setUserStorageLimit(totalFileSize) {
            userStorageUsed = totalFileSize;
        }

        function setUserProfile(userProfile) {
            if (userProfile) {
                if (userProfile.totalSpaceLimitInMegabytes !== null) {
                    userStorageLimit = userProfile.totalSpaceLimitInMegabytes * 1024 * 1024;
                }
            }
        }

        function setFirmStorageLimits(firmStorageLimits) {
            firmStorageAvailable = getFirmStorageAvailable(firmStorageLimits);
        }

        function setStorageAvailable() {
            storageAvailable = firmStorageAvailable;

            if (userStorageLimit === null) {
                return;
            }

            let useStorageAvailable = userStorageLimit - userStorageUsed;
            if (useStorageAvailable < firmStorageAvailable) {
                storageAvailable = useStorageAvailable;
            }
        }

        function getFirmStorageAvailable(firmStorageLimits) {
            let isfirmStorageAvailable = firmStorageLimits ? firmStorageLimits.available - firmStorageLimits.used : 0;

            if (isfirmStorageAvailable < 0) {
                isfirmStorageAvailable = 0;
            }

            return isfirmStorageAvailable;
        }
    }

    module.exports = FileUploadStorageService;
})();