﻿(function () {

    'use strict';

    fsBulkDeleteConfirmDialog.$inject = [
        '$log',
        'fileService',
        'growl'
    ];

    function fsBulkDeleteConfirmDialog($log, fileService, growl) {
        return {
            restrict: 'A',
            scope: {
                title: '@',
                okButtonText: '@',
                okCallback: '&'
            },
            template: require('./fs-bulk-delete-confirm-dialog.html'),
            link: function (scope) {
                scope.bulkDeleteFiles = function () {
                    let vm = scope.$parent.vm;
                    let fileIds = [];
                    angular.forEach(scope.files, function (file) {
                        fileIds.push(file.id);
                    });
                    vm.dataLoading = true;
                    fileService.bulkDeleteFiles(fileIds)
                        .then(function () {
                            growl.success('Your files were successfully deleted.');
                            if (vm.files.length === fileIds.length && vm.selectedPage > 1) {
                                vm.selectedPage--;
                            }
                            vm.updatePage(vm.selectedPage);
                        }, function (response) {
                            growl.error('The files could not be deleted.  Please refresh the page and try again.');
                            $log.debug(response);
                        })
                        .finally(function () {
                            vm.dataLoading = false;
                        });
                }

                scope.$on('file-bulk-delete-confirm-dialog', function (_event, args) {
                    let files = args.files;
                    scope.files = files;
                    scope.$broadcast('show-confirm-dialog');
                });
            }
        }
    }

    module.exports = fsBulkDeleteConfirmDialog;
})();