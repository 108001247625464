﻿(function () {

    'use strict';

    FileAssignmentController.$inject = [
        '$scope',
        '$window',
        'growl',
        'fileService',
        'fileShareApi',
        'errorService'
    ];

    let vm;
    let _$scope;
    let _$window;
    let _growl;
    let _fileService;
    let _fileShareApi;
    let _errorService

    function FileAssignmentController($scope, $window, growl, fileService, fileShareApi, errorService) {
        _$scope = $scope;
        _$window = $window;
        _growl = growl;
        _fileService = fileService;
        _fileShareApi = fileShareApi;
        _errorService = errorService;

        vm = this;

        vm.cancel = cancel;
        vm.reassign = reassignFiles;
        vm.getDisplayName = getDisplayName;
        vm.isValidUserSelected = isValidUserSelected;

        vm.files = _fileService.selectedFiles;
        vm.users = [];
        vm.author = '';
        vm.assignee = '';

        vm.isAuthorDirty = false;
        vm.isAssigneeDirty = false;

        // TODO: rewrite picklist so that form can detect change without watching it.
        _$scope.$watch('vm.author', function (newValue) {
            if (newValue !== undefined) {
                vm.isAuthorDirty = true;
            }
        });

        _$scope.$watch('vm.assignee', function (newValue) {
            if (newValue !== undefined) {
                vm.isAssigneeDirty = true;
            }
        });

        _fileService.getReassignableUsers()
            .then(function (response) {
                loadReassignableUsers(response);
            });

        return vm;
    }

    // TODO: Taken from file-upload controller, needs to be consolidated.
    function loadReassignableUsers(users) {
        for (let i = 0; i < users.length; i++) {
            let user = users[i];
            vm.users.push({
                value: user.lastName + ', ' + user.firstName + ' ' + '(' + user.email + ')',
                id: user.id
            });
        }
    }

    function getUserId(userField) {
        // TODO: Needs refactoring. When should users return ''..?
        let users = vm.users.filter(function (user) { return user.value === userField; });
        return users.length === 1 ? users[0].id : '';
    }

    // TODO: This is a temporary function. It will be replaced once the Web API returns sub-objects.
    // TODO: Replace this with a service or directive.
    function getDisplayName(firstName, lastName, email) {
        let displayName = '';

        if (firstName && lastName && email) {
            displayName = lastName + ', ' + firstName + ' (' + email + ')';
        }

        return displayName;
    }

    function isValidUserSelected(userField) {
        return userField === '' || vm.users.some(function (user) { return user.value === userField; });
    }

    function reassignFiles() {
        if (isValidUserSelected(vm.author) === false) return;

        if (isValidUserSelected(vm.assignee) === false) return;

        let fileIds = vm.files.map(function (f) { return f.id; });
        let authorId = null;
        let assigneeId = null;

        if (vm.author !== '') {
            authorId = getUserId(vm.author);
        }

        if (vm.assignee !== '') {
            assigneeId = getUserId(vm.assignee);
        }

        if (authorId || assigneeId) {
            _fileShareApi.reassignFiles(fileIds, authorId, assigneeId)
                .then(function () {
                    _$window.history.back();
                    _growl.success('Files were successfully reassigned.');
                }, function (response) {
                    let message = getErrorMessage(response.data.message);
                    _errorService.handleError(response, message);
                });
        } else {
            _$window.history.back();
        }
    }

    function cancel() {
        _$window.history.back();
    }

    function getErrorMessage(response) {
        let message;

        switch (response) {
            case 'user-not-found':
                message = 'The selected user is not available.  Please refresh the page and try again.';
                break;
            case 'inactive-user':
                message = 'The selected user is not valid.  Please refresh the page and try again.';
                break;
            default:
                message = 'An unknown error occurred during the file assignment process.';
                break;
        }

        return message;
    }

    module.exports = FileAssignmentController;
})();