const _ = require("angular-animate");

(function () {
    'use strict';

    let _$window;
    let _defaultLogoWidth;
    let _maximumLogoDimension;
    let _maximumLogoFileSize;
    let _maximumLogoHeight;
    let _maximumLogoWidth;
    let _roleService;

    firmEditService.$inject = [
        '$window',
        'defaultLogoWidth',
        'maximumLogoDimension',
        'maximumLogoFileSize',
        'maximumLogoHeight',
        'maximumLogoWidth',
        'roleService'
    ];

    function firmEditService($window, defaultLogoWidth, maximumLogoDimension, maximumLogoFileSize, maximumLogoHeight, maximumLogoWidth, roleService) {
        _$window = $window;
        _defaultLogoWidth = defaultLogoWidth;
        _maximumLogoDimension = maximumLogoDimension;
        _maximumLogoFileSize = maximumLogoFileSize;
        _maximumLogoHeight = maximumLogoHeight;
        _maximumLogoWidth = maximumLogoWidth;
        _roleService = roleService;
            
        return {
            backToPreviousPage: backToPreviousPage,
            canEditFirm: canEditFirm,
            getDefaultLogoWidth: getDefaultLogoWidth,
            getMaximumLogoDimension: getMaximumLogoDimension,
            getMaximumLogoFileSize: getMaximumLogoFileSize,
            getMaximumLogoHeight: getMaximumLogoHeight, 
            getMaximumLogoWidth: getMaximumLogoWidth
        };
    }

    function backToPreviousPage() {
        _$window.history.back();        
    }

    function canEditFirm() {
        return _roleService.canEditFirm();        
    }

    function getDefaultLogoWidth() {
        return _defaultLogoWidth;
    }

    function getMaximumLogoDimension() {
        return _maximumLogoDimension;
    }

    function getMaximumLogoFileSize() {
        return _maximumLogoFileSize;
    }

    function getMaximumLogoHeight() {
        return _maximumLogoHeight;
    }

    function getMaximumLogoWidth() {
        return _maximumLogoWidth;
    }

    module.exports = firmEditService;

})();