﻿(function () {

    'use strict';

    fsPhoneNumber.$inject = [];

    const northAmericanRegEx = /^\d{3}[ -]?\d{3}[ -]?\d{4}$/;
    const internationalNumberRegEx = /(^\+1 \d{3}[ -]?\d{3}[ -]?\d{4}$)|(^\+[2-9]\d{1,2} \d[0-9 -]{5,14}$)/;

    function fsPhoneNumber() {

        return {
            require: 'ngModel',
            restrict: 'A',
            link: function (_scope, _element, attributes, ngModel) {
                ngModel.$validators.phoneNumber = function (modelValue, viewValue) {
                    return validatePhoneNumber(attributes, modelValue, viewValue);
                }

                ngModel.$parsers.push(function (value) {
                    return parsePhoneNumber(value);
                });
            }
        };
    }

    function validatePhoneNumber(attributes, modelValue, viewValue) {
        let isRequired = (attributes.required || false);
        let value = viewValue || modelValue;

        // If it is not required and not set, then it is valid.
        if (!isRequired && (value == null)) return true;

        if (northAmericanRegEx.test(value)) return true;
        if (internationalNumberRegEx.test(value)) return true;

        return false;        
    }

    function parsePhoneNumber(value) {
        if (value === '') {
            return null;
        }
        // Convert numbers to be international (with country code)
        if (northAmericanRegEx.test(value)) {
            return `+1 ${value}`;
        }
        return value;
    }

    module.exports = fsPhoneNumber;
})();