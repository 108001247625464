﻿'use strict';

let angular = require('angular');

angular.module('app').directive('appendMetaTag', require('./directives/append-meta-tag.directive'));
angular.module('app').controller('EditEmailController', require('./directives/edit-email.controller'));
angular.module('app').directive('editEmail', require('./directives/edit-email.directive'));
angular.module('app').directive('fsCopyright', require('./directives/fs-copyright.directive'));
angular.module('app').directive('fsKeyEnter', require('./directives/fs-key-enter.directive'));
angular.module('app').directive('fsMaxWidth', require('./directives/fs-max-width.directive'));
angular.module('app').directive('fsNumbersOnly', require('./directives/fs-numbers-only.directive'));
angular.module('app').directive('fsScaleText', require('./directives/fs-scale-text.directive'));
angular.module('app').directive('fsTooltip', require('./directives/fs-tooltip.directive'));
angular.module('app').directive('fsTruncate', require('./directives/fs-truncate.directive'));
angular.module('app').directive('showFormErrors', require('./directives/show-form-errors.directive'));


angular.module('app').filter('fileSize', require('./filters/file-size.filter'));
angular.module('app').filter('dataUri', require('./filters/data-uri.filter'));
angular.module('app').filter('truncate', require('./filters/truncate.filter'));

angular.module('app').factory('apiEndpointValidationService', require('./services/api-endpoint-validation.service'));
angular.module('app').factory('appRunService', require('./services/app.run.service'));
angular.module('app').factory('authenticationHelperService', require('./services/authentication-helper.service'));
angular.module('app').factory('authenticationService', require('./services/authentication.service'));
angular.module('app').factory('browserService', require('./services/browser.service'));
angular.module('app').factory('bulkFileActionsService', require('./services/bulk-file-actions.service'));
angular.module('app').factory('dataShareService', require('./services/data-share.service'));
angular.module('app').factory('emailTemplateService', require('./services/email-template.service'));
angular.module('app').service('errorMessageService', require('./services/error-message.service'));
angular.module('app').service('errorService', require('./services/error.service'));
angular.module('app').factory('$exceptionHandler', require('./services/exception-handler.service'));
angular.module('app').factory('fileDownloadService', require('./services/file-download.service'));
angular.module('app').factory('fileSelectionService', require('./services/file-selection.service'));
angular.module('app').factory('fileUploadHelperService', require('./services/file-upload-helper.service'));
angular.module('app').factory('fileUploadPromiseHelperService', require('./services/file-upload-promise-helper.service'));
angular.module('app').factory('fileUploadStorageService', require('./services/file-upload-storage.service'));
angular.module('app').factory('fileUploadService', require('./services/file-upload.service'));
angular.module('app').factory('fileService', require('./services/file.service'));
angular.module('app').factory('fileNameValidationService', require('./services/filename-validation.service'));
angular.module('app').factory('fileShareApi', require('./services/fileshare-api.service'));
angular.module('app').factory('firmEditService', require('./services/firm-edit.service'));
angular.module('app').factory('firmService', require('./services/firm.service'));
angular.module('app').factory('globalDisplaySettings', require('./services/global-display-settings.service'));
angular.module('app').factory('helpService', require('./services/help.service'));
angular.module('app').factory('passwordValidationService', require('./services/password-validation.service'));
angular.module('app').factory('loginService', require('./services/login.service'));
angular.module('app').factory('reCaptchaService', require('./services/recaptcha.service'));
angular.module('app').factory('redirectService', require('./services/redirect.service'));
angular.module('app').factory('roleService', require('./services/role.service'));
angular.module('app').factory('routeCheckerService', require('./services/route-checker.service'));
angular.module('app').factory('sessionStorageService', require('./services/session-storage.service'));
angular.module('app').service('timezoneService', require('./services/timezone.service'));
angular.module('app').factory('userEditService', require('./services/user-edit.service'));
angular.module('app').factory('userService', require('./services/user.service'));
angular.module('app').factory('v2RedirectionService', require('./services/v2-redirection.service'));
angular.module('app').factory('v4FileDownloadService', require('./services/v4-file-download.service'));
angular.module('app').factory('v4FileUploadService', require('./services/v4-file-upload.service'));
angular.module('app').factory('maintenanceApiService', require('./services/maintenance-api.service'));
angular.module('app').factory('maintenanceService', require('./services/maintenance.service'));
angular.module('app').factory('userAddService', require('./services/user-add.service'));
angular.module('app').factory('userProfileService', require('./services/user-profile.service'));
angular.module('app').factory('userRegistrationService', require('./services/user-registration.service'));
angular.module('app').factory('viewDisplaySettings', require('./services/view-display-settings.service'));
