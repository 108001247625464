﻿(function () {
    'use strict';

    V2RedirectionService.$inject = ['$location', 'growl'];

    function V2RedirectionService($location, growl) {
        let service = {
            redirect: redirect
        };

        return service;

        function redirect(firmId, path) {
            let message = path === '/requestPasswordReset'
                ? 'This link is no longer valid. Please request another password reset.'
                : 'You have been redirected to the new File Share. Please update your bookmarks.';

            growl.info(message, { ttl: -1 });
            $location.path(firmId + path);
        }
    }

    module.exports = V2RedirectionService;
})();