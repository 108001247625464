﻿(function () {

    'use strict';

    function fsValidateEmail() {
        let emailUsernameRegEx = /^([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*$/i;

        // TODO Revisit email domain part vallidation. Consider using some generic email validation logic instead of using regex.
        let emailDomainBeforeFirstDotRegEx = /^(?:([a-z]|\d)|(([a-z]|\d)([a-z]|\d|-|\.|_|~)*([a-z]|\d)))$/i;
        let emailDomainAfterFirstDotRegEx = /^(([a-z])|(([a-z])([a-z]|\d|-|\.|_|~)*([a-z])))\.?$/i;

        return {
            require: 'ngModel',
            restrict: '',
            link: function (_scope, _elm, _attrs, ctrl) {
                if (ctrl && ctrl.$validators.email) {
                    ctrl.$validators.email = function (modelValue) {
                        return ctrl.$isEmpty(modelValue) || isValidEmail(modelValue);
                    };
                }
            }
        }

        function isValidEmail(value) {
            if (value.indexOf('@') < 0) {
                return false;
            }
            let username = value.slice(0, value.indexOf('@'));
            let domain = value.slice(value.indexOf('@') + 1);

            if (domain.indexOf('.') < 0) {
                return false;
            }
            let domainFirstPart = domain.slice(0, domain.indexOf('.'));
            let domainRemainingPart = domain.slice(domain.indexOf('.') + 1);

            return emailUsernameRegEx.test(username) && emailDomainBeforeFirstDotRegEx.test(domainFirstPart) && emailDomainAfterFirstDotRegEx.test(domainRemainingPart);
        }
    }

    module.exports = fsValidateEmail;
})();