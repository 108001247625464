﻿(function () {

    'use strict';

    FileUploadController.$inject = [
        '$q',
        '$scope',
        'growl',
        'fileUploadHelperService',
        'fileUploadPromiseHelperService',
        'fileUploadService',
        'v4FileUploadService'
    ];

    const everyoneRecipientId = 'everyone';
    const publicRecipientId = 'ffffffff-ffff-ffff-ffff-ffffffffffff';

    let vm;
    let isUserTypeClient;

    let _$q;
    let _$scope;
    let _growl;
    let _fileUploadHelperService;
    let _fileUploadPromiseHelperService;
    let _fileUploadService;
    let _v4FileUploadService;

    function FileUploadController(
        $q,
        $scope,
        growl,
        fileUploadHelperService,
        fileUploadPromiseHelperService,
        fileUploadService,
        v4FileUploadService) {

        _$q = $q;
        _$scope = $scope;
        _growl = growl;
        _fileUploadHelperService = fileUploadHelperService;
        _fileUploadPromiseHelperService = fileUploadPromiseHelperService;
        _fileUploadService = fileUploadService;
        _v4FileUploadService = v4FileUploadService;

        vm = this;
        
        isUserTypeClient = _fileUploadHelperService.isClientUserType();

        vm.firmId = _fileUploadHelperService.getFirmId();
        vm.cancel = cancel;
        vm.getRecipientId = getRecipientId;
        vm.isEditEmailSectionDisplayed = isEditEmailSectionDisplayed;
        vm.isFileValid = isFileValid;
        vm.isRecipientValid = isRecipientValid;
        vm.ok = ok;
        vm.sendFiles = sendFiles;
        vm.reload = reload;

        vm.files = [];
        vm.filesUploaded = [];
        vm.filesNotUploaded = [];
        vm.canSendFile = false;
        vm.sendEmailToAllUsers = false;
        vm.isEditEmailOpened = false;
        vm.isFileDirty = false;
        vm.isRecipientDirty = false;
        vm.isUploadStarted = false;
        vm.isUploadCompleted = false;
        vm.message = '';
        vm.recipients = [];
        vm.isCopyFileOwner = false;
        vm.totalEmails = 0;
        vm.totalFileSizeToUpload = 0;
        vm.templateSlug = 'upload-notification';
        vm.dataLoading = false;
        vm.isAvailableStorageSizeInitialized = false;
        vm.everyoneRecipientId = everyoneRecipientId;

        loadInitialPageInfo();

        _$scope.$on('remove-file', function (_event, args) {
            vm.files.splice(args, 1);
            updateTotalFileSizeToUpload();
        });

        _$scope.$on('add-files', function (_event, args) {
            vm.isFileDirty = true;
            vm.files.push.apply(vm.files, args);
            updateTotalFileSizeToUpload();
        });

        _$scope.$watch('vm.recipient', function (newValue) {
            if (newValue !== undefined) {
                vm.isRecipientDirty = true;
            }

            if (newValue === 'Everyone') {
                vm.templateSlug = 'upload-everyone';
            } else {
                vm.templateSlug = 'upload-notification';
            }
        });
    }

    function loadInitialPageInfo() {
        vm.dataLoading = true;

        loadRecipients();

        _$q.all([
                _fileUploadPromiseHelperService.getCanUploadFiles().then(checkPermission),
                _fileUploadPromiseHelperService.getStorageAvailable().then(setStorageAvailable),
                _fileUploadPromiseHelperService.getUserProfile().then(function (response) {
                    vm.isCopyFileOwner = response.notifyOnUpload;
                })
            ])
            .finally(function () {
                vm.dataLoading = false;
            });

        if (!isUserTypeClient) {
            _fileUploadPromiseHelperService.getNumberOfActiveUsers()
                .then(function (numberOfUsers) {
                    vm.totalEmails = numberOfUsers;
                });
        }
    }

    function checkPermission(canUploadFiles) {
        if (!canUploadFiles) {
            _growl.error('You no longer have permission to share files.');
            _fileUploadHelperService.redirectToFirmHomePage(vm.firmId);
        }
    }

    function loadRecipients() {
        loadEveryoneRecipient();

        _fileUploadService.getAssignableUsers()
            .then(function (assignableUsers) {
                loadAssignableRecipients(assignableUsers);
            }, _fileUploadHelperService.handleError);
    }

    function loadEveryoneRecipient() {
        if (!isUserTypeClient) {
            vm.recipients.push({ value: 'Everyone', id: everyoneRecipientId });
        }
    }

    function loadAssignableRecipients(users) {
        if (users === null || users === undefined) {
            return;
        }

        for (let i = 0; i < users.length; i++) {
            let user = users[i];
            vm.recipients.push({
                value: user.firstName + ' ' + user.lastName + ' ' + '(' + user.email + ')',
                id: user.id
            });
        }
    }

    function setStorageAvailable(storageAvailable) {
        vm.storageAvailable = storageAvailable;
    }

    function updateTotalFileSizeToUpload() {
        let size = 0;
        for (let i = 0; i < vm.files.length; i++) {
            size += vm.files[i].size;
        }
        vm.totalFileSizeToUpload = size;
    }

    function cancel() {
        _fileUploadHelperService.redirectToFirmHomePage(vm.firmId);
    }

    function ok() {
        if (vm.isUploadCompleted) {
            _fileUploadHelperService.redirectToFirmHomePage(vm.firmId);
        } else {
            vm.isUploadCompleted = true;
        }

    }

    function isFileValid() {
        return vm.files.length > 0;
    }

    function isRecipientValid() {
        return getRecipientId() !== '';
    }

    function isFormValid() {
        return _$scope.sendFileForm.$valid && isRecipientValid() && isFileValid();
    }

    function sendFiles() {
        if (!isFormValid()) {
            if (_$scope.sendFileForm.subject.$invalid || _$scope.sendFileForm.body.$invalid) {
                vm.isEditEmailOpened = true;
            }
            return;
        }
        _fileUploadPromiseHelperService.getCanUploadFiles().then(checkPermission, _fileUploadHelperService.handleError);

        if (!_$scope.sendFileForm.$valid) {
            _growl.error('The form has one or more errors.');
            vm.isEditEmailOpened = true;
            return;
        }

        startUploading();

        let recipientId = getRecipientId();
        let isSharedWithEveryone = recipientId === everyoneRecipientId;

        uploadFiles(vm.files, isSharedWithEveryone, recipientId, progressCallback)
            .then(null, handleServerError)
            .finally(function () {
                completeUpload(isSharedWithEveryone, recipientId);
            });
    }

    function completeUpload(isSharedWithEveryone, recipientId) {
        finishUploading();
        sendUploadCompleteEmail(isSharedWithEveryone, recipientId);
    }

    function sendUploadCompleteEmail(isSharedWithEveryone, recipientId) {
        if (vm.filesUploaded.length === 0) {
            return;
        }

        let bodyChunks = [];
        let chunkSize = 1024;
        let body = _$scope.sendFileForm.body.$viewValue;

        for (let i = 0, length = body.length; i < length; i += chunkSize) {
            bodyChunks.push(body.slice(i, i + chunkSize));
        }

        let formData = { customMessage: {} }

        formData.files = vm.filesUploaded.map(function (file) { return file.name; });

        formData.customMessage.subject = _$scope.sendFileForm.subject.$viewValue;
        formData.customMessage.bodyChunks = bodyChunks;

        formData.isSharedWithEveryone = isSharedWithEveryone;
        formData.isEmailToEveryone = vm.sendEmailToAllUsers;

        if (!isSharedWithEveryone) {
            formData.assignedToUserId = recipientId;
            formData.isCopyFileOwner = vm.isCopyFileOwner;
        }

        _fileUploadService.uploadComplete(formData);
    }

    function uploadFiles(files, isSharedWithEveryone, recipientId, callback) {
        _fileUploadHelperService.triggerUploadStart();
        return _$q(function (resolve, reject) {

            let fileIndex = 0;

            function uploadNextFile(data, fileNumber) {

                const file = files[fileIndex];
                const fileId = data.uploadFiles[fileNumber - 1].fileId;
                broadcastUploadStart(file);

                _v4FileUploadService.uploadFile(data.url, fileId, file, callback)
                    .then(
                        function (fileUploaded) {
                            handleSuccessfulUpload(fileUploaded);
                            fileIndex++;
                            if (fileIndex < files.length) {
                                uploadNextFile(data, fileNumber + 1);
                            } else {
                                resolve();
                            }
                        }, function (reason) {
                            handleFailedUpload(reason);
                            fileIndex++;
                            if (fileIndex < files.length) {
                                uploadNextFile(data, fileNumber + 1);
                            } else {
                                resolve();
                            }
                        });
            }

            if (isSharedWithEveryone) {
                recipientId = publicRecipientId;
            }

            let fileInformation = {
                recipientId: recipientId,
                files: []
            };

            files.forEach(function (file, index) {
                fileInformation.files.push({ fileNumber: index + 1, fileName: file.name, fileSize: file.size });
            });

            _v4FileUploadService.getUploadUrl(fileInformation)
                .then(function (uploadData) {
                        uploadNextFile(uploadData, 1);
                    }, function () {
                        reject({ reason: 'server-error' });
                    }
                );
        });
    }

    function reload($event) {
        $event.preventDefault();
        _fileUploadHelperService.reloadPage();
    }

    function broadcastUploadStart(file) {
        _$scope.$broadcast('file-upload-started', { file: file });
    }

    function handleSuccessfulUpload(file) {
        vm.filesUploaded.push(file);
        _$scope.$broadcast('file-upload-completed', { status: 'success', file: file });
    }

    function handleFailedUpload(response) {
        let file = response.file;
        vm.filesNotUploaded.push(file);
        let message = getFileUploadErrorMessage(response.reason);
        if (file.size === 0) {
            message = 'The file does not contain any information.';
        } else {
            _fileUploadHelperService.handleError(response.reason, message);
        }
        _$scope.$broadcast('file-upload-completed', { status: 'error', file: file, reason: message });
    }

    /**
     * Handle when there is a server error.  Set all files as upload is complete with error.
     */
    function handleServerError() {
        vm.files.forEach(
            function (file) {
                _$scope.$broadcast('file-upload-started', { file: file });
                _$scope.$broadcast('file-upload-completed', { status: 'error', file: file, reason: 'Server Error.' });
            });

        const message = getFileUploadErrorMessage('server-error');
        _fileUploadHelperService.handleError('Server Error', message);
    }

    function isEditEmailSectionDisplayed() {
        let recipientId = vm.getRecipientId();
        return recipientId === everyoneRecipientId ? vm.sendEmailToAllUsers : recipientId !== '';
    }

    function getRecipientId() {
        // TODO: Needs refactoring. When should users return ''..?
        let recipients = vm.recipients.filter(function (recipient) { return recipient.value === vm.recipient });
        return recipients.length === 1 ? recipients[0].id : '';
    }

    function startUploading() {
        vm.dataLoading = true;
        vm.isUploadStarted = true;
        showUploadStatusMessage('Sharing in progress, please wait.');
        _$scope.$broadcast('pre-upload-files');
    }

    function finishUploading() {
        _fileUploadHelperService.triggerUploadComplete();
        vm.dataLoading = false;
        _$scope.$broadcast('all-files-handled');
        if (vm.filesNotUploaded.length === 0) {
            vm.isUploadCompleted = true;
            showUploadStatusMessage('Sharing completed successfully.');
        } else {
            _growl.warning('One or more files failed to upload.', { ttl: -1 });
            showUploadStatusMessage('The following files failed to share properly:');
        }
    }

    function showUploadStatusMessage(message) {
        vm.message = message;
    }

    function progressCallback(file, progress) {
        _$scope.$broadcast('file-upload-progress', { file: file, progress: progress });
    }

    // TODO Need to figure out how to handle other errors.
    function getFileUploadErrorMessage(response) {
        let message;

        switch (response) {
            case 'out-of-space':
                message =
                    'There is not enough space available to share this file, probably due to other users sharing files.' +
                    ' Please delete some files and try sharing again.';
                break;
            case 'user-not-found':
                message = 'The selected user is not available.  Please refresh the page and try again.';
                break;
            case 'invalid-user':
                message = 'The selected user is not valid.  Please refresh the page and try again.';
                break;
            case 'server-error':
                message = 'An error occurred communicating with the server.  Please try again later.';
                break;
            default:
                message = 'An unknown error occurred during the file share process.';
                break;
        }

        return message;
    }

    module.exports = FileUploadController;
})();