﻿(function() {
    'use strict';

    GlobalDisplaySettings.$inject = [
        '$q',
        '$http',
        'apiUrlPrefix',
        'apiUrlPort'
    ];

    function GlobalDisplaySettings($q, $http, apiUrlPrefix, apiUrlPort) {
        let apiServerUrl = apiUrlPrefix + (apiUrlPort ? ':' + apiUrlPort : '');
        let baseApiUrl = apiServerUrl + '/api';
        let displaySettingsUrl = baseApiUrl + '/settings/display';

        let filePageSize = null;
        let fileSort = null;

        let userPageSize = null;
        let userSort = null;

        let service = {
            getDisplaySettings: getDisplaySettings,
            saveDisplaySettings: saveDisplaySettings,

            getFilePageSize: getFilePageSize,
            getFileSort: getFileSort,

            getUserPageSize: getUserPageSize,
            getUserSort: getUserSort
        };

        return service;

        function getDisplaySettings() {
            return $http.get(displaySettingsUrl, { cache: true })
                .then(function (response) {
                    if (response.data) {
                        filePageSize = response.data.filePageSize;
                        fileSort = response.data.fileSort;
                        userPageSize = response.data.userPageSize;
                        userSort = response.data.userSort;
                    }
                }, function(response) {
                    $q.reject('Failed to get display settings for user: ' + response);
                });
        }

        function saveDisplaySettings(settings) {
            return $http.post(displaySettingsUrl, settings)
                .then(function (response) {

                    filePageSize = settings.filePageSize;
                    fileSort = settings.fileSort;
                    userPageSize = settings.userPageSize;
                    userSort = settings.userSort;

                    return response;
                },
                function(response) {
                    $q.reject('Failed to save display settings for user: ' + response);
                });
        }

        function getFilePageSize() {
            if (filePageSize === null) {
                return getDisplaySettings()
                    .then(function () {
                        return filePageSize;
                    });
            }
            return $q.resolve(filePageSize);
        }

        function getFileSort() {
            if (fileSort === null) {
                return getDisplaySettings()
                    .then(function() {
                        return fileSort;
                    });
            }
            return $q.resolve(fileSort);
        }

        function getUserPageSize() {
            if (userPageSize === null) {
                return getDisplaySettings()
                    .then(function() {
                        return userPageSize;
                    });
            }
            return $q.resolve(userPageSize);
        }

        function getUserSort() {
            if (userSort === null) {
                return getDisplaySettings()
                    .then(function() {
                        return userSort;
                    });
            }
            return $q.resolve(userSort);
        }
    }

    module.exports = GlobalDisplaySettings;
})();