﻿(function () {
    'use strict';

    HelpService.$inject = [
        'helpUrl'
    ];

    function HelpService(helpUrl) {

        let service = {
            getUrl: getUrl
        };

        return service;

        function getUrl(route) {
            let helpSlug = getHelpSlugFromRoute(route);

            return helpSlug !== null ? helpUrl + '#cshid=' + helpSlug : helpUrl;
        }

        function getHelpSlugFromRoute(route) {
            if (route.data && route.data.helpSlug) {
                return route.data.helpSlug;
            } else {
                return null;
            }
        }
    }

    module.exports = HelpService;
})();