﻿(function () {

    'use strict';

    function fsCompare () {

        return {
            restrict: 'A',
            require: '?ngModel',
            link: function (scope, elem, attrs, ngModel) {
                if (!ngModel) return;
                let validate = function () {
                    if (attrs.fsCompare !== '') {
                        let value1 = ngModel.$viewValue;
                        let value2 = attrs.fsCompare;
                        if (value1 !== undefined && value2 !== undefined) {
                            ngModel.$setValidity('match', value1 === value2);
                        }
                    }
                };

                attrs.$observe('fsCompare', function () {
                    validate();
                });

                elem.on('change', function () {
                    scope.$apply(validate);
                });

                scope.$on('show-form-errors-check-validity', function () {
                    validate();
                });
            }
        };
    }

    module.exports = fsCompare;
})();