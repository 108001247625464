﻿(function () {

    'use strict';

    function fsKeyEnter() {
        return {
            restrict: 'A',
            link: function ($scope, $element, $attrs) {
                $element.bind("keyup", function (event) {
                    if (event.which === 13) {
                        $scope.$apply(function () {
                            $scope.$eval($attrs.fsKeyEnter);
                        });
                        event.preventDefault();
                    }
                });
            }
        }
    }
    module.exports = fsKeyEnter;
})();