(function () {

    'use strict';

    v4FileUploadService.$inject = [
        '$http',
        '$q',
        'apiUrlPrefix',
        'apiUrlPort',
        'Upload'
    ];

    // ReSharper disable once InconsistentNaming
    /**
     * Construct the v4 file upload service.
     *
     * @param {any} $http The HTTP Backend service.
     * @param {any} $q Angular promises.
     * @param {string} apiUrlPrefix The base prefix for the API.
     * @param {number} apiUrlPort The port to use for the API.  If not set, will default to no port.
     * @param {any} Upload The Angular Upload service.
     * @returns {v4FileUploadService} The upload service.
     */
    function v4FileUploadService($http, $q, apiUrlPrefix, apiUrlPort, Upload) {
        let baseApiUrl = apiUrlPrefix + (apiUrlPort ? `:${apiUrlPort}` : '') + '/api';

        const service = {
            getUploadUrl: getUploadUrl,
            uploadFile: uploadFile
        };

        return service;

        function getUploadUrl(fileInformation) {
            return $http.put(baseApiUrl + '/files', fileInformation)
                .then(function (response) {
                    return response.data;
                });
        }

        /**
         * Upload a file into File Share FMS.
         * @param {string} uploadUrl The upload returned by getUploadUrl.
         * @param {string} fileId The fileId returned by getUploadUrl.
         * @param {File} file The file contents.
         * @param {function} progressCallback call back
         *
         * @returns {Promise} A promise for the upload.
         */
        function uploadFile(uploadUrl, fileId, file, progressCallback) {
            return $q(function (resolve, reject) {
                const fullUploadUrl = `${uploadUrl}&fileId=${fileId}`;
                Upload.upload({
                    url: fullUploadUrl,
                    headers: {
                        'Content-Type': 'application/octet-stream'
                    },
                    data: { file }
                }).then(
                    function () {
                        resolve(file);
                    },
                    function () {
                        reject({ file: file, reason: 'Failed to upload.' });
                    },
                    function (event) {
                        const progressPercentage = parseInt(100.0 * event.loaded / event.total);
                        progressCallback(file, progressPercentage);
                    });
            });
        }
    }

    module.exports = v4FileUploadService;
})();
