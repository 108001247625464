﻿(function () {

    'use strict';

    appendMetaTag.$inject = [
        '$document'
    ];

    function appendMetaTag($document) {
        return {
            restrict: 'EA',
            scope: {
                'tagName': '@',
                'tagContent': '@'
            },
            link: function ($scope, $element) {
                let tag = angular.element('<meta>');
                tag.attr('name', $scope.tagName);
                tag.attr('content', $scope.tagContent);
                $document[0].head.appendChild(tag[0]);
                $element.remove();
            }
        }
    }

    module.exports = appendMetaTag;
})();