﻿(function () {

    'use strict';

    fsUserActive.$inject = [
        'fileShareApi',
        'growl'
    ];

    function fsUserActive(fileShareApi, growl) {
        return {
            restrict: 'E',
            replace: true,
            template: '<input type="checkbox" aria-label="Activate User" ng-model="user.isActive" tabindex="0">',
            link: function ($scope) {
                $scope.$watch('user.isActive', function (newValue, oldValue) {
                    if(newValue !== oldValue) {
                        fileShareApi.setIsActive($scope.user.id, newValue)
                          .then(null, function (response) {
                              if (response.data.message === 'unable-to-change-firm-administrator') {
                                  growl.error("You cannot change the primary administrator's active status.");
                              } else if (response.data.message === "unable-to-change-last-user-visible-for-upload") {
                                  growl.error("You cannot change active status of last user with sharing option enabled.");
                              } else {
                                  growl.error('Something went wrong.  Please try again.');
                              }
                          });
                    }
                });
            }
        }
    }

    module.exports = fsUserActive;
})();