(function () {
    'use strict';

    V4FileDownloadService.$inject = [
        '$q',
        '$http',
        '$window',
        'growl',
        'apiUrlPrefix',
        'apiUrlPort'
    ];

    function V4FileDownloadService($q, $http, $window, growl, apiUrlPrefix, apiUrlPort) {
        const apiServerUrl = apiUrlPrefix + (apiUrlPort ? `:${apiUrlPort}` : '');
        const fileApiUrl = apiServerUrl + '/api/files';
        const downloadErrorMessage = 'Your file could not be downloaded. Please refresh your page.';


        const service = {
            downloadFile: downloadFile,
            getDownloadUrl: getDownloadUrl,
            getZipDownloadUrl: getZipDownloadUrl
        };

        function getDownloadUrl(fileId) {
            return $http.post(fileApiUrl + '/download/' + fileId)
                .then(function (response) {
                    return response.data;
                }, function () {
                    return $q.reject(downloadErrorMessage);
                });
        }

        function downloadFile(fileId) {
            return getDownloadUrl(fileId)
                .then(
                    function (data) {
                        const url = data.url;
                        $window.open(url, '_self');
                    },
                    function () {
                        growl.error(downloadErrorMessage);
                        return $q.reject(downloadErrorMessage);
                    });
        }
        
        /**
         * Get zip download URL. 
         * @param {Array} fileIds The file IDs of the files to download.
         * @returns {Promise} A promise for the zip download.
         */
        function getZipDownloadUrl(fileIds)
        {
            return $http.post(fileApiUrl + '/get-zip-download-url', fileIds)
                .then(function (response) {
                    return response.data;
                }, function (response) {
                    if (response.status === 503) {
                        return $q.reject(response.data.message);
                    }
                    if (response.status === 400 && response.data.message.indexOf('size-exceeded:') === 0) {
                        return $q.reject(response.data.message);
                    }
                    return $q.reject('Unknown Error');
                });
        }

        return service;
    }

    module.exports = V4FileDownloadService;
})();