﻿(function() {

    'use strict';

    fsFileListAssignmentDisplay.$inject = ['$compile'];

    function fsFileListAssignmentDisplay ($compile) {
          let getDisplayName = function(firstName, lastName) {
              let displayName = '';

              if (firstName && lastName) {
                  displayName = lastName + ', ' + firstName;
              }

              return displayName;
          }
          return {
              restrict: 'EA',
              scope: {
                  file: '<',
                  assignment: '@'
              },
              link: function($scope, $element) {
                  let html;
                  $scope.assignedTo = {};

                  if ($scope.file.isAssignedToAllUsers && $scope.assignment === 'assignedTo') {
                      html = '<span>Everyone</span>';
                  } else {
                      $scope.assignedTo.email = $scope.file[$scope.assignment + 'Email'];
                      $scope.assignedTo.link = $scope.assignedTo.email;
                      $scope.assignedTo.name = getDisplayName($scope.file[$scope.assignment + 'FirstName'], $scope.file[$scope.assignment + 'LastName']);
                      html = '<span class="user-email-link" title="{{ ::assignedTo.name }}">{{ ::assignedTo.name | truncate:40 }}<a wk-icon="mail" aria-label="Email {{ ::assignedTo.name }}" title="{{ ::assignedTo.email }}" href="mailto:{{ ::assignedTo.link }}"></a></span>';
                  }

                  let element = $compile(html)($scope);
                  $element.replaceWith(element);
              }
          }
    }

    module.exports = fsFileListAssignmentDisplay;
})();