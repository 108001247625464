﻿(function () {

    'use strict';

    fsUserListUserType.$inject = [
        'fileShareApi',
        'growl'
    ];

    function fsUserListUserType(fileShareApi, growl) {
        return {
            restrict: 'E',
            template: require('./fs-user-list-user-type.html'),
            scope: {
                userId: "=",
                userTypeId: "=",
                selected: '='
            },
            link: function (scope, element) {
                scope.isDropDownClosed = true;

                scope.userTypes = [
                    { id: 1, name: 'Administrator' },
                    { id: 2, name: 'Staff' },
                    { id: 3, name: 'Client' }
                ];

                scope.checkSelected = function (userType) {
                    return userType.id === scope.userTypeId;
                };

                scope.selectedValue = scope.userTypes.filter(scope.checkSelected);

                scope.changeUserType = function (userType) {
                    scope.userTypeId = userType;
                    fileShareApi.setUserType(scope.userId, scope.userTypeId)
                        .then(null, function (response) {
                            if (response.data.message === 'unable-to-change-firm-administrator') {
                                growl.error("You cannot change the primary administrator's user type.");
                            } else if (response.data.message ===
                                'unable-to-change-last-user-visible-for-upload-to-client') {
                                growl.error('You cannot change user type to client of last user with sharing option enabled.');
                            } else {
                                growl.error('Something went wrong.  Please try again.');
                            }
                        });

                    scope.selectedValue = scope.userTypes.filter(scope.checkSelected);

                    element[0].querySelector('select').blur();

                    scope.setClosed(true);
                };

                scope.isClosed = function () {
                    return !scope.selected && scope.isDropDownClosed;
                };

                scope.setClosed = function (isClosed) {
                    scope.isDropDownClosed = isClosed;
                }
            }
        };
    }

    module.exports = fsUserListUserType;
})();