﻿(function () {
    'use strict';

    UserService.$inject = [
        'fileShareApi'
    ];

    function UserService(fileShareApi) {

        let service = {
            addUser: addUser,
            changePassword: changePassword,
            editUser: editUser,
            editUserProfile: editUserProfile,
            getAllUsers: getAllUsers,
            getCanUploadFiles: getCanUploadFiles,
            getTimezone: getTimezone,
            getUser: getUser,
            getUserType: getUserType,
            getUserProfile: getUserProfile,
            deleteUser: deleteUser,
            registerUser: registerUser,
            requestPasswordReset: requestPasswordReset,
            resetPassword: resetPassword,
            isResetTokenValid: isResetTokenValid
        };

        return service;

        function getAllUsers(pageNumber, pageSize, filterBy, orderBy, isDescending) {
            return fileShareApi.getAllUsers(pageNumber, pageSize, filterBy, orderBy, isDescending);
        }

        function getCanUploadFiles() {
            return fileShareApi.getCanUploadFiles();
        }

        function getTimezone() {
            return fileShareApi.getTimezone()
            .then(function (timezone) {
                    return timezone;
                });
        }

        function getUser(userId) {
            return fileShareApi.getUser(userId);
        }

        function getUserType() {
            return fileShareApi.getUserType();
        }

        function getUserProfile() {
            return fileShareApi.getUserProfile();
        }

        function isResetTokenValid(firmId, userId, token) {
            return fileShareApi.isResetTokenValid(firmId, userId, token);
        }

        function addUser(newUser) {
            return fileShareApi.addUser(newUser);
        }

        function editUser(userObj) {
            return fileShareApi.editUser(userObj);
        }

        function editUserProfile(userProfile) {
            return fileShareApi.editUserProfile(userProfile);
        }

        function deleteUser(userId) {
            return fileShareApi.deleteUser(userId);
        }

        function registerUser(user) {
            return fileShareApi.registerUser(user);
        }

        function requestPasswordReset(email) {
            return fileShareApi.requestPasswordReset(email);
        }

        function resetPassword(data) {
            return fileShareApi.resetPassword(data);
        }

        function changePassword(passwordData) {
            return fileShareApi.changePassword(passwordData);
        }
    }

    module.exports = UserService;
})();