﻿(function () {

    'use strict';

    fsHelp.$inject = [
        '$route',
        '$window',
        'helpService'
    ];

    function fsHelp($route, $window, helpService) {
        return {
            restrict: 'A',
            template: require('./fs-help.html'),
            replace: true,
            link: function (scope) {
                scope.open = function () {
                    let route = $route.current.$$route;
                    scope.url = helpService.getUrl(route);
                    $window.open(scope.url, 'help');
                }
            }
        };
    }

    module.exports = fsHelp;

})();