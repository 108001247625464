(function () {
    'use strict';

    let _roleService;
    let _userType;

    UserAddService.$inject = [
        'roleService',
        'userType'
    ];

    function UserAddService(roleService, userType) {
        _roleService = roleService;
        _userType = userType;

        return {
            canAddUser: canAddUser,
            canChangeUserType: canChangeUserType,
            getUserType: getUserType
        };
    }

    function canAddUser() {
        return _roleService.canAddUser();
    }

    function canChangeUserType() {
        return _roleService.canChangeUserType();
    }

    function getUserType() {
        return _userType.client.toString();
    }

    module.exports = UserAddService;
})();