﻿(function () {

    'use strict';

    FsFileDownload.$inject = [
    ];

    function FsFileDownload() {
        return {
            restrict: 'EA',
            replace: true,
            scope: {
                file: '<'
            },
            controller: 'fileDownloadController',
            template: '<a ng-click="download()" class="fs-text-overflow" title="{{ file.name }}">{{ file.name }}</a>',
            link: function ($scope, _$element, _$attrs, $controller) {
                let clicked = false;

                $scope.download = function () {
                    if (clicked) return;

                    clicked = true;

                    downloadFile()
                        .finally(function () {
                            clicked = false;
                        });
                };

                function downloadFile() {
                    if ($scope.file.isAssignedToAllUsers) {
                        $scope.file.downloadCount++;
                    } else {
                        if ($scope.file.assignedToEmail === $scope.$root.userProfile.currentUser.username) {
                            $scope.file.downloadCount++;
                        }
                    }

                    return $controller.downloadFile($scope.file.id);
                }
            }
        };
    }

    module.exports = FsFileDownload;
})();
