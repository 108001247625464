﻿(function () {

    'use strict';

    fsUserListActions.$inject = [
        '$location',
        '$rootScope',
        'roleService'
    ];

    function fsUserListActions ($location, $rootScope, roleService) {
        return {
            restrict: 'A',
            scope: {
                firmId: '@',
                userId: '@',
                userEmail: '@'
            },
            template: require('./fs-user-list-actions.html'),
            replace: true,
            link: function (scope) {
                scope.disabled = scope.userId === $rootScope.userProfile.currentUser.userId;

                scope.confirmDeleteUser = function () {
                    if (scope.disabled) return;

                    scope.$root.$broadcast('user-delete-confirm-dialog', { userId: scope.userId });
                }

                scope.editUserDetails = function () {
                    $location.url('/' + scope.firmId + '/editUser/' + scope.userId);
                }

                scope.viewUserFiles = function () {
                    $location.url('/' + scope.firmId + '/userFiles/' + scope.userId);
                }

                scope.executeAction = function (event, action) {
                    if (event.which === 13) { // enter
                        event.preventDefault();
                        event.stopPropagation();
                        action();
                    }
                }

                scope.canEditUser = roleService.canEditUser();
                scope.canViewUserFiles = roleService.canViewUserFiles();
                scope.canDeleteUser = roleService.canDeleteUser();
            }
        }
    }

    module.exports = fsUserListActions;
})();