﻿(function () {

    'use strict';

    UserAddController.$inject = [
        '$scope',
        '$window',
        'errorService',
        'firmService',
        'growl',
        'userService',
        'userAddService'
    ];

    function UserAddController($scope, $window, errorService, firmService, growl, userService, userAddService) {
        let vm = this;

        vm.defaultUserTypeId = userAddService.getUserType();
        vm.canAddUser = userAddService.canAddUser();
        vm.canChangeUserType = userAddService.canChangeUserType();

        vm.saveUser = saveUser;
        vm.cancel = cancel;
        vm.totalSpaceLimitInMegabytes = 1024;

        vm.user = {
            canUploadFiles:true
        }

        // TODO Move into a directive.
        $scope.numbersOnly = function ($event) {
            let regex = new RegExp('\\d');
            let key = String.fromCharCode(!$event.charCode ? $event.which : $event.charCode);
            if (!regex.test(key)) {
                $event.preventDefault();
                return false;
            }
            return true;
        };

        function saveUser(user, addUserForm) {
            // For showFormErrors directive.
            $scope.$broadcast('show-form-errors-check-validity');

            if (addUserForm.$valid) {
                vm.dataLoading = true;

                if (!user.limitUploads) {
                    delete user.totalSpaceLimitInMegabytes;
                }

                userService.addUser(user)
                    .then(function () {
                        growl.success('The user has been added successfully.');
                        $window.history.back();
                    }, function (response) {
                        if (response === 'email-taken') {
                            growl.error('The email address already exists.');
                        } else {
                            errorService.handleError(response);
                        }
                    })
                    .finally(function () {
                        vm.dataLoading = false;
                    });

            } else {
                return;
            }
        }

        function cancel() {
            $window.history.back();
        }

        function init() {
            firmService.getStorageLimits()
                .then(function (firmStorageLimits) {
                    vm.totalSpaceLimitInMegabytes = firmStorageLimits.available / (1024*1024);
                });
        }

        init();
    }

    module.exports = UserAddController;
})();