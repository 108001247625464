﻿(function () {

    'use strict';

    ResetPasswordController.$inject = [
        '$scope',
        '$routeParams',
        '$location',
        'growl',
        'errorService',
        'userService'
    ];

    function ResetPasswordController($scope, $routeParams, $location, growl, errorService, userService) {

        let vm = this;

        let token = $routeParams['resetToken'];
        let firmId = $routeParams['firmId'];
        let userId = $routeParams['userId'];

        vm.firmId = $routeParams.firmId;
        vm.dataLoading = false;
        vm.email = $routeParams.email;
        vm.userId = $routeParams.userId;
        vm.token = $routeParams.resetToken;

        vm.isResetTokenValid = false;  

        vm.reset = reset;

        function initialize(resetToken) {
            vm.dataLoading = true;
            userService.isResetTokenValid(firmId, userId, resetToken)
                .then(function (isValid) {
                    vm.isResetTokenValid = isValid;
                    if (!isValid) {
                        handleInvalidResetToken();
                    }
                }, function (response) {
                    errorService.handleError(response);
                })
                .finally(function () {
                    vm.dataLoading = false;
                });
        }

        initialize(token);

        function reset(resetPasswordForm) {
            if (!resetPasswordForm.$valid) {
                $scope.$broadcast('show-form-errors-check-validity');
                return;
            }

            vm.dataLoading = true;

            let data = {
                id: vm.userId,
                resetToken: vm.token,
                password: vm.password,
                confirmPassword: vm.confirmPassword,
                firmId: vm.firmId
            };

            userService.resetPassword(data)
                .then(function () {
                    growl.success('Your password has been reset successfully.');
                    $location.url('/' + vm.firmId + '/login').search('email', encodeURIComponent(vm.email));
                }, function (response) {
                    if (response.data && response.data.message === 'invalid-token') {
                        handleInvalidResetToken();
                    } else {
                        errorService.handleError(response);
                    }
                })
                .finally(function() {
                    vm.dataLoading = false;
                });

        }

        function handleInvalidResetToken() {
            growl.warning('The password link has expired.  ' +
                'Please enter the email address that you registered with in the box below and ' +
                'a new password reset link will be sent to you.',
                { title: 'Expired Password Reset Link' });
            $location.path('/' + firmId + '/requestPasswordReset');
        }
    }

    module.exports = ResetPasswordController;
})();