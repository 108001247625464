﻿'use strict';

require('jquery');
require('bootstrap/dist/css/bootstrap.min.css');
require('angular-growl-v2/src/growl.css');
require('@wk/fonts/dist/wk-fonts.css');
require('@wk/icons/dist/wk-icons-all-filled.css');
require('@wk/icons/dist/wk-icons-all-filled-attrs.css');
require('@wk/icons/dist/wk-icons-all-open.css');
require('@wk/icons/dist/wk-icons-all-open-attrs.css');
require('@wk/logos/dist/wk-logos.css');
require('@wk/css/dist/standard.css');
require('summernote/dist/summernote-lite.css');
require('codemirror/lib/codemirror.css');
require('../assets/css/angular-csp.css');

require('../assets/css/styles.scss');

require('bootstrap');
require('summernote/dist/summernote-lite.js');
require('codemirror');
require('codemirror/mode/htmlembedded/htmlembedded');

require('../modules');
require('../modules/sb-summernote');

(function () {

    const angular = require('angular');
    const ngRoute = require('angular-route');
    const ngCookies = require('angular-cookies');
    const ngMessages = require('angular-messages');
    const angularMoment = require('angular-moment');
    const angularAnimate = require('angular-animate');
    const ngIdle = require('ng-idle');
    const ngFileUpload = require('ng-file-upload');
    const angularGrowl = require('angular-growl-v2-webpack');
    const angularHttpAuth = require('angular-http-auth');
    const uiBootstrap = require('angular-ui-bootstrap');
    const ngSanitize = require('angular-sanitize');

    const app = angular
        .module('app', [
            ngRoute,
            ngCookies,
            angularAnimate,
            ngIdle,
            ngMessages,
            angularHttpAuth,
            uiBootstrap,
            angularMoment,
            angularGrowl,
            ngFileUpload,
            ngSanitize,
            'sb-summernote'
        ]);

    module.exports = app;

})();

angular.module('app').config(['$sanitizeProvider', function ($sanitizeProvider) {
    $sanitizeProvider.addValidAttrs(['role']);
}]);
   
angular.module('app').config(require('./app.route'));
angular.module('app').factory('authInterceptor', require('./auth-interceptor'));
angular.module('app').config(require('./app.config'));
angular.module('app').config(require('./app.idle.config'));
angular.module('app').run(require('./app.run'));

angular.module('app').config(['$qProvider', function ($qProvider) {
    $qProvider.errorOnUnhandledRejections(false);
}]);

require('./index');
require('./app.constants');
require('./fileshareMoment');
require('./shared');
require('./components/core');
require('./components/login');
require('./components/firm');
require('./components/file');
require('./components/user');
