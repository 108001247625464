﻿(function () {
    'use strict';

    function passwordValidationService() {
        let service = {
            isValid: isValid
        };

        return service;

        function isValid(password) {
            let requirementsMeet = 0;

            if (meetsMinimumLengthRequirement(password)) {
                requirementsMeet++;
            }

            if (containsAtLeastOneLowercaseLetter(password)) {
                requirementsMeet++;
            }

            if (containsAtLeastOneUppercaseLetter(password)) {
                requirementsMeet++;
            }

            if (containsAtLeastOneSpecialCharacter(password)) {
                requirementsMeet++;
            }

            if (containsAtLeastOneDigit(password)) {
                requirementsMeet++;
            }

            return requirementsMeet === 5;

        }

        function meetsMinimumLengthRequirement(password) {
            return password.length >= 8;
        }

        function containsAtLeastOneLowercaseLetter(password) {
            let lowercaseLetterPattern = /[a-z]/;
            return lowercaseLetterPattern.test(password);
        }

        function containsAtLeastOneUppercaseLetter(password) {
            let uppercaseLetterPattern = /[A-Z]/;
            return uppercaseLetterPattern.test(password);
        }

        function containsAtLeastOneSpecialCharacter(password) {
            let specialCharacterPattern = /[\W_]/;
            return specialCharacterPattern.test(password);
        }

        function containsAtLeastOneDigit(password) {
            let digitPattern = /\d/;
            return digitPattern.test(password);
        }
    }

    module.exports = passwordValidationService;
})();