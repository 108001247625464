﻿(function () {

    'use strict';

    emailTemplateDialog.$inject = [
        '$location'
    ];

    let _$location;

    function emailTemplateDialog($location) {
        return {
            restrict: 'EA',
            scope: {
                title: '@',
                okButtonText: '@',
                okCallback: '&'
            },
            template: require('./email-template-dialog.view.html'),
            link: function (scope) {
                _$location = $location;

                scope.discardChanges = discardChanges(scope);

                scope.$on('email-template-confirm-dialog', showConfirmDialog(scope) );
            }
        }
    }

    function discardChanges(scope) {
        return function () {
            let vm = scope.$parent.vm;

            if (scope.args.next) {
                vm.isDirty = false;
                let path = getPathFromRoute(scope.args.next);
                _$location.path(path);
            }

            if (scope.args.template) {
                vm.updateTemplateList(vm.defaultTemplate);
                vm.loadTemplate(scope.args.template.slug);
            }
        };
    }

    function showConfirmDialog(scope) {
        return function (_event, args) {
            scope.args = {}

            if (args.next) {
                scope.args.next = args.next;
            }

            if (args.template) {
                scope.args.template = args.template;
            }

            scope.$broadcast('show-confirm-dialog');
        };
    }

    // TODO: This is temporary and can be removed once angular-ui-routes is implemented.
    function getPathFromRoute(route) {
        let path = route.$$route.originalPath;
        for (let property in route.pathParams) {
            if (route.pathParams.hasOwnProperty(property)) {
                let regEx = new RegExp(':' + property, 'gi');
                path = path.replace(regEx, route.pathParams[property].toString());
            }
        }
        return path;
    }

    module.exports = emailTemplateDialog;
})();