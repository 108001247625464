﻿(function () {
    'use strict';


    PaginationController.$inject = [
        '$log'
    ];

    let vm;
    let _$log;

    function PaginationController($log) {
        _$log = $log;

        vm = this;

        vm.currentPage = 1;
        vm.totalPages = 1;
        vm.visiblePageIndex = 0;
        vm.maxPagesToDisplay = 10;

        vm.update = function () { /* Placeholder for delegate */  };

        vm.display = display;
        vm.getPage = getPage;
        vm.getPageArray = getPageArray;
        vm.getPageIndex = getPageIndex;
        vm.isNextBoundaryLinkVisible = isNextBoundaryLinkVisible;
        vm.isPageVisible = isPageVisible;
        vm.isPreviousBoundaryLinkVisible = isPreviousBoundaryLinkVisible;
        vm.nextBoundary = nextBoundary;
        vm.nextPage = nextPage;
        vm.previousBoundary = previousBoundary;
        vm.previousPage = previousPage;
        vm.selectPage = selectPage;
    }

    function getPageArray() {
        let sizeOfArray = vm.maxPagesToDisplay < vm.totalPages ? vm.maxPagesToDisplay : vm.totalPages;
        return new Array(sizeOfArray || 0);
    }

    function getPageIndex(index) {
        return index + vm.visiblePageIndex;
    }

    function getPage(index) {
        return vm.getPageIndex(index) + 1;
    }

    function selectPage(selectedPage) {
        if (selectedPage < 1 || vm.totalPages < selectedPage) {
            _$log.error("Pagination: selected page is out of range. (selected page=" + selectedPage + ", total pages=" + vm.totalPages + ")");
            return;
        }

        if (vm.currentPage !== selectedPage) {
            vm.currentPage = selectedPage;
            vm.update(vm.currentPage);
            updateVisiblePageIndex();
        }
    }

    function nextPage() {
        if (vm.currentPage < vm.totalPages) {
            vm.currentPage++;
            vm.update(vm.currentPage);
            updateVisiblePageIndex();
        }
    }

    function previousPage() {
        if (vm.currentPage > 1) {
            vm.currentPage--;
            vm.update(vm.currentPage);
            updateVisiblePageIndex();
        }
    }

    function previousBoundary() {
        if (vm.visiblePageIndex - vm.maxPagesToDisplay >= 0) {
            vm.visiblePageIndex -= vm.maxPagesToDisplay;
            vm.currentPage = vm.visiblePageIndex + 1;
            vm.update(vm.currentPage);
        }
    }

    function nextBoundary() {
        if (vm.visiblePageIndex + vm.maxPagesToDisplay <= vm.totalPages) {
            vm.visiblePageIndex += vm.maxPagesToDisplay;
            vm.currentPage = vm.visiblePageIndex + 1;
            vm.update(vm.currentPage);
        }
    }

    function display(currentPage, totalPages) {
        vm.currentPage = currentPage;
        vm.totalPages = totalPages;
        updateVisiblePageIndex();
    }

    function isNextBoundaryLinkVisible() {
        return vm.visiblePageIndex + 1 + vm.maxPagesToDisplay < vm.totalPages;
    }

    function isPreviousBoundaryLinkVisible() {
        return vm.visiblePageIndex > 1;
    }

    function isPageVisible(index) {
        return vm.getPage(index) !== 1 && vm.getPage(index) < vm.totalPages;
    }

    function updateVisiblePageIndex() {
        vm.visiblePageIndex = Math.floor((vm.currentPage - 1) / vm.maxPagesToDisplay) * vm.maxPagesToDisplay;
    }

    module.exports = PaginationController;

})();
