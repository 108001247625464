﻿(function () {

    'use strict';

    fsLazyList.$inject = [
        '$window',
        'dotNetMaximumInteger',
        'lazyListNumberOfItemsPerLoad'
    ];

    function fsLazyList($window, dotNetMaximumInteger, lazyListNumberOfItemsPerLoad) {
        return {
            restrict: 'A',
            scope: {
                items: '=',
                itemsPerPage: '=',
                numberOfItemsToLoad: '='
            },
            link: function (scope) {
                scope.$watch('itemsPerPage', function () {
                    if (scope.itemsPerPage === dotNetMaximumInteger) {
                        scope.numberOfItemsToLoad = lazyListNumberOfItemsPerLoad;
                    }
                });

                angular.element($window)
                    .on('scroll', function () {
                        if (scope.itemsPerPage < dotNetMaximumInteger || scope.items.length < scope.numberOfItemsToLoad) {
                            return;
                        }

                        let element = angular.element('.wk-pagination-bar')[0];
                        if (element) {
                            let rect = element.getBoundingClientRect();
                            if (rect.top >= 0 && rect.bottom <= $window.innerHeight) {
                                scope.numberOfItemsToLoad += lazyListNumberOfItemsPerLoad;
                                scope.$apply();
                            }
                        }
                    });

                scope.$on('$destroy', function () {
                    angular.element($window).off('scroll');
                });
            }
        }
    }

    module.exports = fsLazyList;
})();