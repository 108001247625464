﻿(function () {
    'use strict';

    function DataShareService() {

        let memory = new Map();

        let service = {
            setData: setData,
            getData: getData
        };

        return service;

        function setData(key, value) {
            memory.set(key, value);
        }

        function getData(key) {
            let data = memory.get(key);
            if (data) {
                memory.delete(key);
                return data
            } 
            return '';
        }
    }

    module.exports = DataShareService;
})();