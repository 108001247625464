﻿(function() {

    'use strict';

    fsBulkFileActions.$inject = [
        '$q',
        'bulkFileActionsService',
        'roleService',
        'fileDownloadService'
    ];

    let _$q;
    let _bulkFileActionsService;
    let _roleService;
    let _fileDownloadService;

    function fsBulkFileActions(
        $q,
        bulkFileActionsService,
        roleService,
        fileDownloadService) {
          return {
            restrict: 'A',
            scope: {
                files: '=',
                showBulkDelete: '=?'
            },
            replace: true,
            template: require('./fs-bulk-file-actions.view.html'),
            link: function (scope) {
                _$q = $q;
                _bulkFileActionsService = bulkFileActionsService;
                _roleService = roleService;
                _fileDownloadService = fileDownloadService;
            
                scope.downloadDisabled = false;
                scope.bulkActionDisabled = false;
                scope.bulkActionDisabledMessage = _bulkFileActionsService.getBulkActionDisabledMessage();

                if (scope.showBulkDelete === undefined) {
                    scope.showBulkDelete = false;
                }
                scope.isBulkDeleteAllowed = _roleService.canBulkDeleteAdministratorFiles() || scope.showBulkDelete;
                scope.canBulkDownloadAllFiles = _roleService.canBulkDownloadAllFiles();
                scope.canReassignAllFiles = _roleService.canReassignAllFiles();

                scope.$watch('files', function () {
                    _bulkFileActionsService.handleSelectFile(scope);
                }, true);

                scope.bulkDeleteFiles = bulkDeleteFiles(scope);

                scope.bulkReassignFiles = bulkReassignFiles(scope);

                scope.bulkDownloadFiles = function () {
                    if (scope.bulkActionDisabled) return;

                    scope.downloadDisabled = true;

                    downloadZip(scope)
                        .then(function () {
                            scope.downloadDisabled = false;
                        });
                }

                scope.$on('zip-download', function () {
                    if (scope.bulkActionDisabled) {
                        return;
                    }

                    scope.bulkActionDisabled = true;

                    downloadZip(scope).then(function () {
                        scope.bulkActionDisabled = false;
                    });
                });
            }
        }
    }

    function bulkDeleteFiles(scope) {
        return function () {
            if (scope.bulkActionDisabled) return;

            let files = [];
            angular.forEach(scope.files, function (file) {
                if (file.selected === true) {
                    files.push(file);
                }
            });
            scope.$parent.$parent.$broadcast('file-bulk-delete-confirm-dialog', { files: files });
        };        
    }

    function bulkReassignFiles(scope) {
        return function () {
            _bulkFileActionsService.bulkReassignFiles(scope);
        }
    }

    function downloadZip(scope) {
        let files = scope.files;
        let selectedFiles = [];
        let selectedFileIds = [];

        angular.forEach(files, function (file) {
            if (file.selected === true) {
                selectedFiles.push(file);
                selectedFileIds.push(file.id);
            }
        });

        let deferred = _$q.defer();
        _fileDownloadService.downloadZip(selectedFileIds)
            .then(function () {
                updateDownloadCount(scope, selectedFiles);
            });
        deferred.resolve();
        return deferred.promise;
    }

    function updateDownloadCount(scope, selectedFiles) {
        angular.forEach(selectedFiles, function (file) {
            if (file.isAssignedToAllUsers === true) {
                file.downloadCount++;
            } else {
                if (file.assignedToEmail === scope.$root.userProfile.currentUser.username) {
                    file.downloadCount++;
                }
            }
        });
    }

    module.exports = fsBulkFileActions;

})();
