﻿(function () {

    'use strict';

    function fsPageTitle() {
        return {
            restrict: 'E',
            transclude: true,
            scope: {
                subTitle : '@'
            },
            template: require('./fs-page-title.html')
        };
    }

    module.exports = fsPageTitle;
})();