﻿(function () {
    'use strict';


    FooterController.$inject = [
        '$window'
    ];

    function FooterController($window) {
        let vm = this;

        vm.termsOfService = termsOfService;
        vm.privacyPolicy = privacyPolicy;
        vm.proprietaryInformation = proprietaryInformation;

        function termsOfService() {
            $window.open('legal/terms-of-service.html', 'terms-of-service');
        }

        function privacyPolicy() {
            $window.open('legal/privacy-policy.html', 'privacy-policy');
        }

        function proprietaryInformation() {
            $window.open('legal/proprietary-information.html', 'proprietary-information');
        }
    }

    module.exports = FooterController;

})();