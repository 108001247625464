﻿(function () {

    'use strict';

    function fileSize () {
            return function (bytes) {
            if (isNaN(parseFloat(bytes)) || !isFinite(bytes) || bytes === 0) {
                return "0 B";
            }

            let units = ['B', 'KB', 'MB', 'GB', 'TB'];
            let exponent = Math.floor(Math.log(bytes) / Math.log(1024));
            let number = (bytes / Math.pow(1024, Math.floor(exponent))).toFixed(1);

            return (number.match(/\.0*$/) ? number.substr(0, number.indexOf('.')) : number / 1) + ' ' + units[exponent];
        }
    };

    module.exports = fileSize;
})();