(function () {
    'use strict';

    let _$document;
    let _fileNameValidationService;
    let _$rootScope;

    fileSelectionService.$inject = [
        '$document',
        'fileNameValidationService',
        '$rootScope'
    ];

    function fileSelectionService($document, fileNameValidationService, $rootScope) {
        _$document = $document;
        _fileNameValidationService = fileNameValidationService;
        _$rootScope = $rootScope;

        return {
            getFilesWithValidFilename: getFilesWithValidFilename,
            getInvalidExtensions: getInvalidExtensions,
            hasFileNamesWithAsciiHtmlInName: hasFileNamesWithAsciiHtmlInName,
            updateDragAndDropUiEffect: updateDragAndDropUiEffect,
            triggerAddFiles: triggerAddFiles,
            triggerRemoveFiles: triggerRemoveFiles
        };
    }

    function getFilesWithValidFilename(files) {
        let filesWithValidFilenames = [];

        for (let i = 0; i < files.length; i++) {
            if (_fileNameValidationService.isValid(files[i].name)) {
                filesWithValidFilenames.push(files[i]);
            }
        }

        return filesWithValidFilenames;
    }

    /**
     * Returns an array with the list of invalid extensions.  E.g. [".exe", ".zip"]
     * @returns {array} An array of extensions with leading periods.
     */
    function getInvalidExtensions() {
        return _fileNameValidationService
            .getInvalidExtensions()
            .map(function (extension) {
                return `.${extension}`;
            });
    }

    /**
     * Determine if any of the file names have ASCII HTML in the name.
     * @param {file} files The files to check.
     * @returns {boolean} true if any file names have ASCII HTML in name, false otherwise.
     */
     function hasFileNamesWithAsciiHtmlInName(files) {
        for (const file of files) {
            if (_fileNameValidationService.hasAsciiHtmlInName(file.name)) {
                return true;
            }
        }

        return false;
    }

    function triggerAddFiles(filesToUpload) {
        _$rootScope.$broadcast('add-files', filesToUpload);
    }

    function triggerRemoveFiles(index) {
        _$rootScope.$broadcast('remove-file', index);
    }

    function updateDragAndDropUiEffect(e, $scope) {
        let body = _$document.find('body');
        e.type === "dragover" ? body.addClass('drag-and-drop-hover') : body.removeClass('drag-and-drop-hover');

        $scope.$apply();
    }

    module.exports = fileSelectionService;

})();