﻿(function () {

    'use strict';

    MyFilesListController.$inject = [
        '$controller',
        '$scope',
        'errorService',
        'fileService'
    ];
    
    function MyFilesListController($controller, $scope, errorService, fileService) {
        $scope.viewSlug = 'my-files';
        
        let vm = $controller('FileListBaseController', {$scope: $scope});

        vm.getFiles = function () {
            return function () {
                fileService.getMyFiles(vm.selectedPage, vm.filePageSize, vm.sortType, vm.isSortDescending, vm.searchTerm)
                    .then(vm.handleSuccessfulGetFiles(), errorService.handleError)
                    .finally(function () { vm.dataLoading = false; });
            };
        }
           
        return vm;
    }

    module.exports = MyFilesListController;
})();