﻿(function () {

    'use strict';

    function fsValidateFirmLogo() {

        return {
            require: 'ngModel',
            restrict: 'A',
            scope: {
                logo: '='
            },
            link: function (scope, _element, _attributes, ngModel) {
                ngModel.$validators.isLogoVisible = function (modelValue) {
                    return !modelValue || scope.logo;
                };

            }
        }
    }

    module.exports = fsValidateFirmLogo;
})();