﻿(function() {
    'use strict';

    function SessionStorageService() {
        let service = {
            setItem: setItem,
            getItem: getItem,
            removeItem: removeItem

        };

        return service;

        function setItem(key, value) {
            sessionStorage.setItem(key, JSON.stringify(value));
        }

        function getItem(key, defaultValue) {
            let item = sessionStorage.getItem(key);
            if (item === null && defaultValue !== undefined) {
                return defaultValue;
            }
            return JSON.parse(item);
        }

        function removeItem(key) {
            sessionStorage.removeItem(key);
        }
    }

    module.exports = SessionStorageService;
})();