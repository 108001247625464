﻿(function () {

    'use strict';

    function fsPagination() {
        return {
            restrict: 'A',
            replace: true,
            template: require('./fs-pagination.html'),
            scope: {
                currentPage: '@',
                totalPages: '@',
                delegateUpdate: '='
            },
            controller: 'PaginationController',
            controllerAs: 'vm',
            bindToController: true,
            link: function (scope, element) {
                scope.$on('update-pagination', function (event, args) {
                    element[0].style.display = args.totalPages > 0 ? 'inline' : 'none';

                    event.currentScope.vm.display(args.currentPage, args.totalPages);
                    event.currentScope.vm.update = args.delegateUpdate;

                });
            }
        }
    }

    module.exports = fsPagination;
})();