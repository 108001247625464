﻿(function () {
    'use strict';

    function ApiEndpointValidationService() {
        let whitelistedEndpoints = [];

        let service = {
            setWhitelistedEndPoints: setWhitelistedEndPoints,
            isWhitelisted: isWhitelisted
        };

        return service;

        function setWhitelistedEndPoints(endpoints) {
            whitelistedEndpoints = endpoints;
        }

        function isWhitelisted(url) {
            for (let i = 0; i < whitelistedEndpoints.length; i++) {
                if (url && url.indexOf(whitelistedEndpoints[i]) === 0) {
                    return true;
                }
            }

            return false;
        }
    }

    module.exports = ApiEndpointValidationService;
})();