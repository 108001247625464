﻿(function () {

    'use strict';

    fsTruncate.$inject = [
        '$compile'
    ];

    function fsTruncate($compile) {
        return {
            restrict: 'EA',
            scope: {
                displayLength: '@',
                displayValue: '='
            },
            link: function ($scope, $element) {
                let html;

                if ($scope.displayValue.length > $scope.displayLength) {
                    html = '<span fs-tooltip data-bs-toggle="tooltip" title="{{ ::displayValue }}">{{ ::displayValue | truncate: displayLength }}</span>';
                } else {
                    html = '<span>{{ ::displayValue }}</span>';
                }

                let element = $compile(html)($scope);
                $element.replaceWith(element);
            }
        }
    }

    module.exports = fsTruncate;

})();