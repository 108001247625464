﻿(function() {

    'use strict';

    function truncateFilter() {
        return function(text, length) {
            if (text.length > length) {
                return text.substr(0, length) + '...';
            } else {
                return text;
            }
        };
    }

    module.exports = truncateFilter;
})();