﻿(function () {

    'use strict';

    function fsFooter() {
        return {
            restrict: 'A',
            template: require('./fs-footer.html'),
            replace: true,
            scope: true,
            transclude: false,
            controller: 'FooterController',
            controllerAs: 'vm',
            bindToController: true
        };
    }

    module.exports = fsFooter;
})();