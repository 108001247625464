﻿(function () {

    'use strict';

    FirmService.$inject = ['$injector', 'fileShareApi', 'validGuid'];

    function FirmService($injector, fileShareApi, validGuid) {

        let service = {
            initialize: initialize,
            getFirm: getFirm,
            getFirmVersion: getFirmVersion,
            getFirmBranding: getFirmBranding,
            isFirmActive: isFirmActive,
            isValidFirmId: isValidFirmId,
            isValidFirmIdFormat: isValidFirmIdFormat,
            editFirm: editFirm,
            uploadLogo: uploadLogo,
            getFirmAdministrators: getFirmAdministrators,
            getStaffAndAdministrators: getStaffAndAdministrators,
            getNumberOfActiveUsers: getNumberOfActiveUsers,
            getStorageLimits: getStorageLimits,
            setIsVisibleToClientForUpload: setIsSelectedForUpload
        };

        return service;

        function initialize(firmId) {
            let scope = $injector.get('$rootScope');

            getFirmBranding(firmId)
                .then(function (firm) {
                    scope.$broadcast('firm-initialized', firm);
                    if (firm.isActive === false) {
                        scope.$emit('inactive-firm');
                    }
                }, function (response) {
                    if (response.status === 404) {
                        scope.$emit('invalid-firm');
                    }
                });
        }

        function isFirmActive(firmId) {
            return fileShareApi.isFirmActive(firmId);
        }

        function isValidFirmId(firmId) {
            return fileShareApi.isValidFirmId(firmId);
        }

        function isValidFirmIdFormat(firmId) {
            return validGuid.test(firmId);
        }

        function getFirm() {
            return fileShareApi.getFirm();
        }

        function getFirmBranding(firmId) {
            return fileShareApi.getFirmBranding(firmId);
        }

        function editFirm(firm) {
            return fileShareApi.editFirm(firm);
        }

        function uploadLogo(logo) {
            return fileShareApi.uploadLogo(logo);
        }

        function getFirmAdministrators() {
            return fileShareApi.getFirmAdministrators();
        }

        /**
         * Get the Firm Version.
         * @returns {Promise<number>} The firm version (4).
         */
        function getFirmVersion() {
            return fileShareApi.getFirmVersion();
        }

        function getStaffAndAdministrators(pageNumber, pageSize, filterBy, orderBy, isDescending) {
            return fileShareApi.getStaffAndAdministrators(pageNumber, pageSize, filterBy, orderBy, isDescending);
        }

        function getNumberOfActiveUsers() {
            return fileShareApi.getNumberOfActiveUsers();
        }

        function getStorageLimits() {
            return fileShareApi.getFirmStorageLimits();
        }


        function setIsSelectedForUpload(userId, isSelectedForUpload) {
            return fileShareApi.setIsVisibleToClientForUpload(userId, isSelectedForUpload);
        }
    }

    module.exports = FirmService;
})();