﻿(function () {

    'use strict';

    FileSelectionController.$inject = [
        '$scope',
        '$filter',
        'growl',
        'fileSelectionService',
        'fileUploadStorageService',
        'maximumUploadSize',
        'maximumNumberOfFilesPerAction'
    ];

    let vm;
    let initialStorageAvailable;
    let sizeOfFilesSelected;

    let _$scope;
    let _$filter;
    let _growl;
    let _fileSelectionService;
    let _fileUploadStorageService;
    let _maximumUploadSize;
    let _maximumNumberOfFilesPerAction;
    
    function FileSelectionController(
        $scope,
        $filter,
        growl,
        fileSelectionService,
        fileUploadStorageService,
        maximumUploadSize,
        maximumNumberOfFilesPerAction) {

        vm = this;
        initialStorageAvailable = 0;
        sizeOfFilesSelected = 0;
    
        _$scope = $scope;
        _$filter = $filter;
        _growl = growl;
        _fileSelectionService = fileSelectionService;
        _fileUploadStorageService = fileUploadStorageService;
        _maximumUploadSize = maximumUploadSize;
        _maximumNumberOfFilesPerAction = maximumNumberOfFilesPerAction;
    
        vm.fileDragHover = fileDragHover;
        vm.fileDrop = fileDrop;
        vm.fileSelect = fileSelect;

        vm.autoScroll = autoScroll;
        vm.getListItemClass = getListItemClass;
        vm.getWkIcon = getWkIcon;
        vm.removeFile = removeFile;

        vm.filesToProcess = [];
        vm.filesProcessed = [];
        vm.filesBeingUploaded = [];
        vm.filesNotExist = [];
        vm.isFileSelectionHidden = false;
        vm.isUploadInProgress = false;

        initialize();

        _$scope.$on('pre-upload-files', function () {
            vm.isFileSelectionHidden = true;
            vm.isUploadInProgress = true;
        });

        _$scope.$on('file-upload-started', function (_event, args) {
            let file = args.file;
            let length = vm.filesToProcess.length;
            removeItemFromList(vm.filesToProcess, file);
            if (length !== vm.filesToProcess.length) {
                vm.filesBeingUploaded.push({ 'file': file, 'progress': 0 });
                vm.autoScroll();
            }
        });

        _$scope.$on('file-upload-completed', function (_event, args) {
            let file = args.file;
            let status = args.status;
            let length = vm.filesBeingUploaded.length;
            removeItemFromList(vm.filesBeingUploaded, file);
            if (length !== vm.filesBeingUploaded.length) {
                vm.filesProcessed.push({ 'file': file, 'status': status, 'reason': args.reason === undefined ? '' : args.reason });
            }
        });

        _$scope.$on('file-upload-progress', function (_event, args) {
            let file = args.file;
            let progress = Math.round(args.progress);
            for (let i = 0; i < vm.filesBeingUploaded.length; i++) {
                if (vm.filesBeingUploaded[i].file === file) {
                    vm.filesBeingUploaded[i].progress = progress;
                }
            }
        });

        _$scope.$on('all-files-handled', function () {
            handleUnprocessedFiles();
        });

        function handleUnprocessedFiles() {
            for (let i = 0; i < vm.filesToProcess.length; i++) {
                let file = vm.filesToProcess[i].file;
                vm.filesProcessed.push({ 'file': file, 'status': 'error', 'reason': 'The file no longer exists.' });
            }
            vm.filesToProcess = [];
            vm.autoScroll();
        }
    }

    function initialize() {
        _fileUploadStorageService.getStorageAvailable()
            .then(function (storageAvailable) {
                initialStorageAvailable = storageAvailable;
            });
    }

    function stopDefaultEventHandling(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    function fileDragHover(e) {
        stopDefaultEventHandling(e);

        if (vm.isUploadInProgress) {
            e.originalEvent.dataTransfer.dropEffect = 'none';
            return;
        }

        _fileSelectionService.updateDragAndDropUiEffect(e, _$scope);
    }

    function fileDrop(e) {
        stopDefaultEventHandling(e);

        if (vm.isUploadInProgress) {
            return;
        }

        _fileSelectionService.updateDragAndDropUiEffect(e, _$scope);

        if (e.originalEvent.dataTransfer) {
            let items = e.originalEvent.dataTransfer.items;
            if (items) {
                for (let i = 0; i < items.length; i++) {
                    if (!items[i].webkitGetAsEntry().isFile) {
                        _growl.error('We\'re sorry, but a folder cannot be shared.');
                        return;
                    }
                }
            }

            let files = e.originalEvent.dataTransfer.files;

            addFiles(files);
        }
    }

    function fileSelect(e) {
        let files = e.target.files;
        if (files) {
            addFiles(files);
        }
    }

    function addFiles(files) {
        if (vm.filesToProcess.length + files.length > _maximumNumberOfFilesPerAction) {
            _growl.error('You cannot upload more than ' + _maximumNumberOfFilesPerAction + ' files at once.');
            return;
        }

        let i;
        let totalSize = 0;

        for (i = 0; i < files.length; i++) {
            totalSize += files[i].size;
        }

        if ((sizeOfFilesSelected + totalSize) > initialStorageAvailable) {
            _growl.error('The file(s) selected exceed the total storage you have available.');
            return;
        }

        sizeOfFilesSelected += totalSize;

        if (_fileSelectionService.hasFileNamesWithAsciiHtmlInName(files)) {
            _growl.error('You cannot share files with ASCII HTML in the name.');
            return;
        }

        for (i = 0; i < files.length; i++) {
            if (files[i].size === 0) {
                _growl.error('We\'re sorry, but 0 byte files cannot be shared.');
                return;
            }

            if (files[i].size > _maximumUploadSize) {
                let text = _$filter('fileSize')(_maximumUploadSize);
                _growl.error("We're sorry, but files " + text + " or larger cannot be shared.");
                return;
            }
        }

        let filesWithValidFilenames = _fileSelectionService.getFilesWithValidFilename(files);

        if (files.length !== filesWithValidFilenames.length) {
            _growl.error('We\'re sorry, but you cannot share the following types: ' + _fileSelectionService.getInvalidExtensions().join(', '));
            return;
        }

        let filesToUpload = [];
        for (i = 0; i < filesWithValidFilenames.length; i++) {
            vm.filesToProcess.push({ 'file': filesWithValidFilenames[i] });
            filesToUpload.push(filesWithValidFilenames[i]);
        }
        if (filesToUpload.length > 0) {
            _fileSelectionService.triggerAddFiles(filesToUpload);
        }
        _$scope.$apply();
    }

    function autoScroll() {
        let scrollableArea = $('#file-upload-status');
        let heightOfSingleElement = scrollableArea.find('li').outerHeight(true);
        let heightOfViewableArea = scrollableArea.outerHeight();
        let scrollTop = ((vm.filesProcessed.length * (heightOfSingleElement)) - heightOfViewableArea + heightOfSingleElement);
        scrollableArea[0].scrollTop = scrollTop;
    }

    function getListItemClass(status) {
        switch (status) {
            case 'success':
                return 'list-group-item-success';
            case 'error':
                return 'list-group-item-danger';
            default:
                return 'list-group-item-info';
        }
    }

    function getWkIcon(status) {
        switch (status) {
            case 'success':
                return 'file-check';
            case 'error':
                return 'forbid';
            default:
                return undefined;
        }
    }

    function removeFile(index) {
        sizeOfFilesSelected -= vm.filesToProcess[index].size;
        vm.filesToProcess.splice(index, 1);
        _fileSelectionService.triggerRemoveFiles(index);
    }

    function removeItemFromList(list, value) {
        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (JSON.stringify(fileToJson(item.file)) === JSON.stringify(fileToJson(value))) {
                list.splice(i, 1);
                break;
            }
        }

        return list;
    }

    function fileToJson(file) {
        return {
            'lastModified': file.lastModified,
            'lastModifiedDate': file.lastModifiedDate,
            'name': file.name,
            'size': file.size,
            'type': file.type
        };
    }

    module.exports = FileSelectionController;
})();