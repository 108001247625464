﻿(function () {

    'use strict';

    fsUserSelectedForUpload.$inject = [
        'growl',
        'fileShareApi',
        'errorService'
    ];

    function fsUserSelectedForUpload (growl, fileShareApi, errorService) {
          return {
            restrict: 'EA',
            replace: true,
            template: '<input type="checkbox" ng-model="user.isVisibleToClientForUpload">',
            link: function (scope) {
                scope.$watch('user.isVisibleToClientForUpload', function (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        fileShareApi.setIsVisibleToClientForUpload(scope.user.id, newValue)
                            .then(null, function (response) {
                                if (response.data.message === 'Must have at least one user selected.') {
                                    scope.user.isVisibleToClientForUpload = true;
                                    growl.error('You must have at least one user selected.');
                                } else {
                                    errorService.handleError(response.data.message);
                                }
                            });
                    }
                });
            }
        }
    }

    module.exports = fsUserSelectedForUpload;
})();