﻿(function() {

    'use strict';

    PublicFileListController.$inject = [
        '$controller',
        '$scope',
        'errorService',
        'fileService'
    ];

    function PublicFileListController($controller, $scope, errorService, fileService) {
        $scope.viewSlug = 'public-files';
        
        let vm = $controller('FileListBaseController', {$scope: $scope});

        vm.getFiles = function () {
            return function () {
                fileService.getPublicFiles(vm.selectedPage, vm.filePageSize, vm.sortType, vm.isSortDescending, vm.searchTerm)
                    .then(vm.handleSuccessfulGetFiles(), errorService.handleError)
                    .finally(function () { vm.dataLoading = false; });
            };
        }

        // TODO: This is a temporary function. It will be replaced once the Web API returns sub-objects.
        // TODO: Replace this with a service or directive.
        vm.getDisplayName = function(firstName, lastName) {
            let displayName = '';

            if (firstName && lastName) {
                displayName = lastName + ', ' + firstName;
            }

            return displayName;
        }
                
        vm.isValidSortType = function (sortType) {
            let validSortTypes = ['Name', 'UploadDateTime', 'Size', 'Author', 'DownloadCount'];
            return validSortTypes.indexOf(sortType) > -1;
        }        
    
        return vm;
    }    

    module.exports = PublicFileListController;
})();