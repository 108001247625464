﻿(function () {

    'use strict';

    FileListController.$inject = [
        '$controller',
        '$scope'
    ];

    function FileListController($controller, $scope) {
        $scope.viewSlug = 'all-files';
        
        return $controller('FileListBaseController', {$scope: $scope});
    }

    module.exports = FileListController;
})();