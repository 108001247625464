﻿'use strict';

let angular = require('angular');

angular.module('app').controller('ChangePasswordController', require('./change-password.controller'));
angular.module('app').controller('RequestPasswordResetController', require('./request-password-reset.controller'));
angular.module('app').controller('ResetPasswordController', require('./reset-password.controller'));
angular.module('app').controller('UserAddController', require('./user-add.controller'));
angular.module('app').controller('UserEditController', require('./user-edit.controller'));
angular.module('app').controller('UserListController', require('./user-list.controller'));
angular.module('app').controller('UserProfileController', require('./user-profile.controller'));
angular.module('app').controller('UserRegisterController', require('./user-register.controller'));

angular.module('app').directive('fsCompare', require('./directives/fs-compare.directive'));
angular.module('app').directive('fsUserActive', require('./directives/fs-user-active.directive'));
angular.module('app').directive('fsUserDeleteConfirmDialog', require('./directives/fs-user-delete-confirm-dialog.directive'));
angular.module("app").directive('fsUserListActions', require('./directives/fs-user-list-actions.directive'));
angular.module('app').directive('fsUserListUserType', require('./directives/fs-user-list-user-type.directive'));
angular.module('app').directive('fsUserUpload', require('./directives/fs-user-upload.directive'));
