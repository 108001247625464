﻿(function () {

    'use strict';

    run.$inject = [
        '$rootScope',
        '$location',
        '$timeout',
        'appRunService',
        'roleService',
        'firmService',
    ];

    let $rootScope;
    let $location;
    let $timeout;
    let appRunService;
    let roleService;
    let firmService;

    let route;

    function run(
        _$rootScope_,
        _$location_,
        _$timeout_,
        _appRunService_,
        _roleService_,
        _firmService_) {
        
        appRunService = _appRunService_;
        $rootScope = _$rootScope_;
        $location = _$location_;
        $timeout = _$timeout_;
        roleService = _roleService_;
        firmService = _firmService_;
        
        route = null;

        $rootScope.userProfile = appRunService.getUserProfile();

        // TODO: This needs to be refactored using broadcasting instead of a 'catch-all' watch.
        // TODO: This can be handled better with angular-ui-router's $state params.
        $rootScope.$on('$routeChangeStart', handleRouteChange());
    }

    function handleRouteChange() {
        return function (_event, next) {
            if (!appRunService.isOnline()) {
                $location.path('/offline').replace();
                return;
            }

            if (!appRunService.isSupportedBrowser()) {
                $location.path('/unsupportedBrowser').replace();
                return;
            }

            let firmId = next.params.firmId;

            if (!firmService.isValidFirmIdFormat(firmId)) {
                $location.path('/notFound').replace();
                return;
            }

            let isPublicAccess = isPublicPageAccess(next.isPublicAccess);
            let loginPath = '/' + firmId + '/login';

            let isAuthenticated = appRunService.isAuthenticated();

            if (isNotAuthenticated(isPublicAccess, isAuthenticated)) {
                route = $location.path();
                appRunService.setRedirectPath(route);
                $location.path(loginPath).replace();
                return;
            } else if (isAuthenticatedWithRedirection(isPublicAccess, isAuthenticated)) {
                $location.path(route).replace();
                route = null;
                return;
            }

            if (!$rootScope.firm) {
                $timeout(function () {
                    firmService.initialize(firmId);
                }, 0);
            }

            if (isPublicAccess) {
                handlePublicPageAccess(loginPath, firmId);
            }

            if (isRoleRequired(next)) {
                validateRole(next.data.rolesAllowed, firmId);
            }
        };
    }

    function handlePublicPageAccess(loginPath, firmId) {
        if ($location.path() === loginPath) {
            appRunService.isInMaintenance(firmId);
        } else {
            validateFirmIsActive(firmId, loginPath);
        }
    }

    function isRoleRequired(next) {
        return next.data && next.data.rolesAllowed;
    }
    
    function isAuthenticatedWithRedirection(isPublicAccess, isAuthenticated) {
        return !isPublicAccess && route !== null && isAuthenticated;
    }

    function isNotAuthenticated(isPublicAccess, isAuthenticated) {
        return !isPublicAccess && !isAuthenticated;
    }

    function isPublicPageAccess(isPublicAccess) {
        return isPublicAccess || false;
    }
    
    function validateFirmIsActive(firmId, loginPath) {
        firmService.isFirmActive(firmId)
            .then(function (isAccessible) {
                if (!isAccessible) {
                    $location.path(loginPath);
                }
            }, function (data) {
                if (data.status === 404) {
                    $location.path(loginPath);
                }
            });
    }

    function validateRole(allowedRoles, firmId) {
        let isAllowed = roleService.hasRoleRequired(allowedRoles);
        if (!isAllowed) {
            $location.path('/accessDenied').search({ firmId: firmId }).replace();
        }
    }

    module.exports = run;
})();
