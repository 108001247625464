﻿(function () {

    'use strict';

    SharingOptionsController.$inject = [
        '$scope',
        'errorService',
        'firmService',
        'viewDisplaySettings'
    ];

    function SharingOptionsController($scope, errorService, firmService, viewDisplaySettings) {
        let vm = this;

        let viewSlug = 'sharing-options';

        vm.dataLoading = false;
        vm.users = [];
        vm.updatePage = updatePage;
        vm.selectedPage = 1;
        vm.searchTerm = '';
        vm.sort = sort;
        vm.isSortDescending = false;

        vm.sortType = 'lastname';
        vm.userPageSize = 25;

        function getUserPageSize() {
            return viewDisplaySettings.getUserPageSize(viewSlug)
                .then(function (userPageSize) {
                    vm.userPageSize = userPageSize;
                });
        }

        function getUserSort() {
            return viewDisplaySettings.getUserSort(viewSlug)
                .then(function (sortType) {
                    vm.sortType = sortType;
                    vm.isSortDescending = !viewDisplaySettings.getIsUserSortByDefaultOrder(viewSlug);
                });
        }

        function setUserSort(sortType) {
            if (vm.sortType !== sortType) {
                setSortType(sortType);
                viewDisplaySettings.setIsUserSortByDefaultOrder(viewSlug, true);
            } else {
                let isSortReverse = viewDisplaySettings.getIsUserSortByDefaultOrder(viewSlug);
                viewDisplaySettings.setIsUserSortByDefaultOrder(viewSlug, !isSortReverse);
            }
        }

        function getAll() {
            vm.dataLoading = true;

            getUserPageSize()
                .then(getUserSort())
                .then(function () {
                    let orderBy = (vm.isSortDescending ? '-' : '') + vm.sortType;
                    firmService.getStaffAndAdministrators(vm.selectedPage, vm.userPageSize, vm.searchTerm, orderBy)
                        .then(function (response) {
                            if (response) {
                                vm.users = response.users;
                                let totalPages = Math.ceil(response.totalUsers / vm.userPageSize);
                                updatePagination(vm.selectedPage, totalPages);
                            }
                        }, errorService.handleError)
                        .finally(function () {
                            vm.dataLoading = false;
                        });
                });
        }

        getAll();

        function sort(sortType) {
            setUserSort(sortType);
            getAll();
        }

        function updatePagination(currentPage, totalPages) {
            $scope.$broadcast('update-pagination', { currentPage: currentPage, totalPages: totalPages, delegateUpdate: vm.updatePage });
        }

        function updatePage(currentPage) {
            vm.selectedPage = currentPage;
            getAll();
        }

        function setSortType(sortType) {
            vm.sortType = sortType;
            viewDisplaySettings.setUserSort(viewSlug, sortType);
        }

        return vm;
    }

    module.exports = SharingOptionsController;
})();