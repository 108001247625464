﻿'use strict';

let angular = require('angular');

angular.module('app').controller('FileListBaseController', require('./file-list-base.controller'));
angular.module('app').controller('FileAssignmentController', require('./file-assignment.controller'));
angular.module('app').controller('UserFilesListController', require('./user-files-list.controller'));
angular.module('app').controller('SharedFilesListController', require('./shared-files-list.controller'));
angular.module('app').controller('ReceivedFilesListController', require('./received-files-list.controller'));
angular.module('app').controller('PublicFileListController', require('./public-file-list.controller'));
angular.module('app').controller('MyFilesListController', require('./my-files-list.controller'));
angular.module('app').controller('FileUploadController', require('./file-upload.controller'));
angular.module('app').controller('FileSelectionController', require('./file-selection.controller'));
angular.module('app').controller('FileListController', require('./file-list.controller'));
angular.module('app').controller('fileDownloadController', require('./file-download.controller'));
angular.module('app').directive('fsBulkDeleteConfirmDialog', require('./directives/fs-bulk-delete-confirm-dialog.directive'));
angular.module('app').directive('fsBulkFileActions', require('./directives/fs-bulk-file-actions.directive'));
angular.module('app').directive('fsFileDeleteConfirmDialog', require('./directives/fs-file-delete-confirm-dialog.directive'));
angular.module('app').directive('fsFileDownload', require('./directives/fs-file-download.directive'));
angular.module('app').directive('fsFileListActions', require('./directives/fs-file-list-actions.directive'));
angular.module('app').directive('fsFileListAssignmentDisplay', require('./directives/fs-file-list-assignment-display.directive'));
angular.module('app').directive('fsFileSelection', require('./directives/fs-file-selection.directive'));
angular.module('app').directive('fsSelectAllCheckbox', require('./directives/fs-select-all-checkbox.directive'));
