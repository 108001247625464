﻿(function() {
    'use strict';

    FileService.$inject = [
        'fileShareApi'
    ];

    function FileService(fileShareApi) {

        let service = {
            bulkDeleteFiles: bulkDeleteFiles,
            deleteFile: deleteFile,
            getAllFiles: getAllFiles,
            getReassignableUsers: getReassignableUsers,
            getFile: getFile,
            getMyFiles: getMyFiles,
            getPublicFiles: getPublicFiles,
            getReceivedFiles: getReceivedFiles,
            getUserFiles: getUserFiles,
            getSharedFiles: getSharedFiles,
            getTotalFileSizeByUser: getTotalFileSizeByUser
        };

        return service;

        function bulkDeleteFiles(fileIds) {
            return fileShareApi.bulkDeleteFiles(fileIds);
        }

        function getFile(fileId) {
            return fileShareApi.getFile(fileId);
        }

        function deleteFile(fileId) {
            return fileShareApi.deleteFile(fileId);
        }

        function getAllFiles(selectedPage, pageSize, sortBy, isDescending, searchTerm) {
            return fileShareApi.getAllFiles(selectedPage, pageSize, sortBy, isDescending, searchTerm);
        }

        function getMyFiles(selectedPage, pageSize, sortBy, isDescending, searchTerm) {
            return fileShareApi.getMyFiles(selectedPage, pageSize, sortBy, isDescending, searchTerm);
        }

        function getSharedFiles(selectedPage, pageSize, sortBy, isDescending, searchTerm) {
            return fileShareApi.getSharedFiles(selectedPage, pageSize, sortBy, isDescending, searchTerm);
        }

        function getPublicFiles(selectedPage, pageSize, sortBy, isDescending, searchTerm) {
            return fileShareApi.getPublicFiles(selectedPage, pageSize, sortBy, isDescending, searchTerm);
        }

        function getReceivedFiles(selectedPage, pageSize, sortBy, isDescending, searchTerm) {
            return fileShareApi.getReceivedFiles(selectedPage, pageSize, sortBy, isDescending, searchTerm);
        }

        function getUserFiles(userId, selectedPage, pageSize, sortBy, isDescending, searchTerm) {
            return fileShareApi.getUserFiles(userId, selectedPage, pageSize, sortBy, isDescending, searchTerm);
        }

        function getTotalFileSizeByUser() {
            return fileShareApi.getTotalFileSizeByUser();
        }

        function getReassignableUsers() {
            return fileShareApi.getReassignableUsers();
        }
    }

    module.exports = FileService;
})();