(function () {
    'use strict';

    let _$window;
    let _reCaptchaService;

    userRegistrationService.$inject = [
        '$window',
        'reCaptchaService'
    ];

    function userRegistrationService($window, reCaptchaService) {
        _$window = $window;
        _reCaptchaService = reCaptchaService;

        return {
            createReCaptcha: createReCaptcha,
            getReCaptchaResponse: getReCaptchaResponse,
            showTermsOfService: showTermsOfService
        };
    }

    function createReCaptcha(callback) {
        return _reCaptchaService.create('fs-recaptcha', callback);
    }

    function getReCaptchaResponse(recaptchaId) {
        return _reCaptchaService.getResponse(recaptchaId);        
    }

    function showTermsOfService() {
        _$window.open('/legal/terms-of-service.html', 'TermsOfService');
    }

    module.exports = userRegistrationService;

})();