﻿(function () {

    'use strict';

    RedirectService.$inject = [
        'sessionStorageService'
    ];

    function RedirectService(sessionStorageService) {

        let service = {
            getRedirectPath: getRedirectPath
        };

        return service;

        function getRedirectPath() {
            let key = 'redirectPath';
            let path = sessionStorageService.getItem(key, null);

            if (path) {
                sessionStorageService.removeItem(key);
            }

            return path;
        }
    }

    module.exports = RedirectService;
})();