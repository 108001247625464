﻿(function () {

    'use strict';

    UserRegisterController.$inject = [
        '$routeParams',
        '$location',
        '$scope',
        'growl',
        'errorService',
        'userService',
        'userRegistrationService'
    ];

    function UserRegisterController($routeParams, $location, $scope, growl, errorService, userService, userRegistrationService) {

        let vm = this;

        $scope.user = {};

        vm.firmId = $routeParams.firmId;
        vm.cancel = cancel;
        vm.dataLoading = false;
        vm.enableRegister = enableRegister;
        vm.isRegisterEnabled = false;
        vm.registerUser = registerUser;
        vm.showTermsOfService = showTermsOfService;
        vm.checkPasswordMatch = checkPasswordMatch;
        vm.recaptchaId = null;

        vm.recaptchaId = userRegistrationService.createReCaptcha(vm.enableRegister);

        function cancel() {
            $location.path('/' + vm.firmId + '/login');
        }

        function registerUser(user, registerUserForm) {

            if (!registerUserForm.$valid || !checkPasswordMatch(user, registerUserForm)) {
                // TODO We should change how the errors are handled.  Maybe inject an errorDisplayService.
                $scope.$broadcast('show-form-errors-check-validity');
                return;
            }

            user.reCaptchaResponse = userRegistrationService.getReCaptchaResponse(vm.recaptchaId);
            if (!user.reCaptchaResponse) {
                growl.error('Please complete the ReCaptcha.');
                return;
            }

            register(user);
        }

        function register(user) {
            vm.dataLoading = true;

            user.firmId = vm.firmId;

            userService.registerUser(user)
                .then(function () {
                    growl.success('Thank you for registering, an email has been sent to you with further instructions.');
                    $location.path('/' + vm.firmId + '/login');
                }, function (response) {
                    if (response === 'The ReCaptcha Response is required.') {
                        growl.error('Verification expired. Check the ReCaptcha checkbox again.');
                    } else {
                        errorService.handleError(response);
                    }
                })
                .finally(function () {
                    vm.dataLoading = false;
                });
        }

        function enableRegister() {
            vm.isRegisterEnabled = true;
            $scope.$apply();
        }

        function showTermsOfService() {
            userRegistrationService.showTermsOfService();
        }

        function checkPasswordMatch(user, registerUserForm) {
            let isMatching = user.password === user.confirmPassword;
            registerUserForm.confirmPassword.$setValidity('confirmPassword', isMatching);
            return isMatching;
        }

    }

    module.exports = UserRegisterController;
})();