﻿(function() {

    'use strict';

    fsFileListActions.$inject = [
        'roleService'
    ];
    
    function fsFileListActions (roleService) {
          return {
            restrict: 'A',
            scope: {
                file: '<'
            },
            template: require('./fs-file-list-actions.view.html'),
            replace: true,
            link: function (scope) {
                scope.confirmDeleteFile = function () {
                    scope.$parent.$parent.$broadcast('file-delete-confirm-dialog', { file: scope.file });
                }

                scope.canDeleteFile = function () {
                    if (scope.$root.userProfile.currentUser === undefined) {
                        return false;
                    }

                    if (roleService.canDeleteAdministratorFile()) return true;

                    return (scope.file.authorEmail === scope.$root.userProfile.currentUser.username);
                }

                scope.executeAction = function (event, action) {
                    if (event.which === 13) { // enter
                        event.preventDefault();
                        event.stopPropagation();
                        action();
                    }
                }
            }
        }
    }

    module.exports = fsFileListActions;
})();