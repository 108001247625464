﻿(function() {
    'use strict';

    UserProfileController.$inject = [
        '$q',
        'userService',
        '$scope',
        'growl',
        'globalDisplaySettings',
        'userProfileService',
        'timezoneService'
    ];

    let _$q;
    let _userService;
    let _$scope;
    let _growl;
    let _globalDisplaySettings;
    let _userProfileService;
    let _timezoneService;

    let vm;

    function UserProfileController(
        $q,
        userService,
        $scope,
        growl,
        globalDisplaySettings,
        userProfileService,
        timezoneService) {

        _$q = $q;
        _userService = userService;
        _$scope = $scope;
        _growl = growl;
        _globalDisplaySettings = globalDisplaySettings;
        _userProfileService = userProfileService;
        _timezoneService = timezoneService;

        vm = this;

        vm.userProfile = {};
        vm.userProfile.timezoneSelected = null;

        vm.changePassword = changePassword;
        vm.saveProfile = saveProfile;
        vm.cancel = cancel;

        vm.pageSizeOptions = [
            { name: '25', value: 25 },
            { name: '50', value: 50 },
            { name: '100', value: 100 },
            { name: 'All', value: 2147483647 }
        ];

        vm.fileSortOptions = [
            { name: 'File Name', value: 'Name' },
            { name: 'Shared On', value: 'UploadDateTime' },
            { name: 'File Size', value: 'Size' },
            { name: 'Shared With', value: 'AssignedTo' },
            { name: 'Owner', value: 'Author' },
            { name: 'Downloads', value: 'DownloadCount' }
        ];

        vm.userSortOptions = [
            { name: 'Email', value: 'Email' },
            { name: 'First Name', value: 'FirstName' },
            { name: 'Last Name', value: 'LastName' }
        ];

        vm.timezones = [];
        vm.timezoneSelected = null;

        vm.filePageSizeSelected = vm.pageSizeOptions[0];
        vm.fileSortSelected = vm.fileSortOptions[0];
        vm.userPageSizeSelected = vm.pageSizeOptions[0];
        vm.userSortSelected = vm.userSortOptions[2];

        vm.canEditUserViewSettings = _userProfileService.canEditUserViewSettings();

        initialize();
    }

    function changePassword() {
        _userProfileService.redirectToChangePasswordPage();
    }

    function saveProfile(userProfile, editUserForm) {
        // For showFormErrors directive.
        _$scope.$broadcast('show-form-errors-check-validity');

        if (editUserForm.$valid) {
            vm.dataLoading = true;

            userProfile.timezoneId = vm.timezoneSelected === null ? null : vm.timezoneSelected.id;

            setGlobalDisplaySettings()
                .then(function () {
                    _userService.editUserProfile(userProfile)
                        .then(function (result) {

                            _timezoneService.setTimezone(vm.timezoneSelected);

                            if (result === 'login-invalidated') {
                                _userProfileService.redirectToLoginPage();
                            } else {
                                _userProfileService.backToPreviousPage();
                            }
                        }, function (response) {
                            if (response === 'email-taken') {
                                _growl.error('The email address already exists.');
                            } else {
                                _userProfileService.handleError(response);
                            }
                        })
                        .finally(function () {
                            vm.dataLoading = false;
                        });
                });

        } else {
            return;
        }
    }

    function initialize() {
        let timezone;
        _$q.all([
                _userService.getUserProfile(),
                getFilePageSize(),
                getFileSort(),
                getUserPageSize(),
                getUserSort(),
                getTimezones()
            ])
            .then(function (data) {
                let userProfile = data[0];
                if (userProfile) {
                    vm.userProfile = userProfile;
                    vm.email = userProfile.email;
                    if (userProfile.timezoneId === null) {
                        vm.timezoneSelected = null;
                    } else {
                        timezone = vm.timezones[vm.timezones.map(function (e) { return e.id; })
                            .indexOf(userProfile.timezoneId)];
                        vm.timezoneSelected = timezone === undefined ? null : timezone;
                    }
                }
            }, _userProfileService.handleError);
    }

    function cancel() {
        _userProfileService.backToPreviousPage();
    }

    // TODO This feels hokey, maybe change to return all settings at once.
    // TODO Refactor to DRY, leaving as-is due to time constraints.
    function getFilePageSize() {
        return _globalDisplaySettings.getFilePageSize()
            .then(function(filePageSize) {
                let index = vm.pageSizeOptions.map(function(e) { return e.value; }).indexOf(filePageSize);
                if (index < 0) return;
                vm.filePageSizeSelected = vm.pageSizeOptions[index];
            });
    }

    function getFileSort() {
        return _globalDisplaySettings.getFileSort()
            .then(function (fileSort) {
                let index = vm.fileSortOptions.map(function (e) { return e.value; }).indexOf(fileSort);
                if (index < 0) return;
                vm.fileSortSelected = vm.fileSortOptions[index];
            });
    }

    function getUserPageSize() {
        return _globalDisplaySettings.getUserPageSize()
            .then(function (userPageSize) {
                let index = vm.pageSizeOptions.map(function (e) { return e.value; }).indexOf(userPageSize);
                if (index < 0) return;
                vm.userPageSizeSelected = vm.pageSizeOptions[index];
            });
    }

    function getUserSort() {
        let promise = _globalDisplaySettings.getUserSort();
        return promise
            .then(function (userSort) {
                let index = vm.userSortOptions.map(function (e) { return e.value; }).indexOf(userSort);
                if (index < 0) return;
                vm.userSortSelected = vm.userSortOptions[index];
            });
    }

    function getTimezones() {
        return _timezoneService.getTimezones()
            .then(function (timezones) {
                vm.timezones = timezones;
            });
    }

    function setGlobalDisplaySettings() {
        let settings = {
            filePageSize: vm.filePageSizeSelected.value,
            fileSort: vm.fileSortSelected.value,
            userPageSize: vm.userPageSizeSelected.value,
            userSort: vm.userSortSelected.value
        }
        return _globalDisplaySettings.saveDisplaySettings(settings);
    }

    module.exports = UserProfileController;
})();