﻿(function () {

    'use strict';

    fsKeyboardNavigation.$inject = [
        '$document'
    ];

    function fsKeyboardNavigation($document) {
        return {
            restrict: 'A',
            scope: false,
            link: function (_$scope, $element) {
                let dropdown = $element.find('.wk-navbar-dropdown-classic');

                $document.bind('focusin', function ($e) {
                    if ($element[0].contains($e.target)) {
                        dropdown.addClass('fs-dropdown-open');
                    } else {
                        dropdown.removeClass('fs-dropdown-open');
                    }
                });

                $document.bind('mousedown', function () {
                    dropdown.removeClass('fs-dropdown-open');
                });
            }
        }
    }

    module.exports = fsKeyboardNavigation;

})();