﻿(function () {

    'use strict';

    function FsSelectAllCheckbox() {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                items: '=',
                isAllSelected: ' ='
            },
            template: '<input title="Select all" aria-labelledby="select-all-checkbox" type="checkbox" ng-change="selectAll()" ng-model="isAllSelected">',
            link: function (scope) {
                scope.selectAll = function () {
                    angular.forEach(scope.items, function (item) { item.selected = scope.isAllSelected; });
                }
            }
        };
    }

    module.exports = FsSelectAllCheckbox;
})();