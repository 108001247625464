﻿(function () {
    'use strict';

    function fsDisableInput() {
        return {
            restrict: 'A',
            link: function (_scope, element, attrs) {
                attrs.$observe('fsDisableInput', function () {
                    let input;
                    if (attrs.elementId) {
                        input = element[0].querySelector('#' + attrs.elementId);
                    } else {
                        input = element[0];
                    }

                    let wrappedInput = angular.element(input);
                    let isDisabled = attrs.fsDisableInput;
                    if (isDisabled === 'true') {
                        wrappedInput.attr('disabled', 'disabled');
                    }
                });
            }
        }
    }

    module.exports = fsDisableInput;
})();