﻿(function () {

    'use strict';

    NavigationController.$inject = [
        '$scope', 
        '$route', 
        'authenticationService', 
        'roleService'
    ];

    function NavigationController($scope, $route, authenticationService, roleService) {
        let vm = this;

        vm.firmId = $route.current.params.firmId;
        vm.canAddUser = false;
        vm.canChangeSharingOptions = false;
        vm.canListAllFiles = false;
        vm.canListUsers = false;
        vm.canEditEmailTemplates = false;
        vm.canEditFirm = false;
        vm.canManage = false;
        vm.canSendFiles = false;
        vm.isUserLoggedIn = false;
        vm.isNavigationDisabled = false;
        
        updateNavigation($route.current.isPublicAccess, $route.current.isNavigationDisabled);

        function isUserLoggedIn(isPublicAccess) {
            // TODO: will correct this when Masa will correct isAuthenticated method 
            return authenticationService.isAuthenticated() && isPublicAccess !== true;
        }

        function updateNavigation(isPublicAccess, isNavigationDisabled) {
            vm.canAddUser = roleService.canAddUser();
            vm.canChangeSharingOptions = roleService.canChangeSharingOptions();
            vm.canListAllFiles = roleService.canViewAllFilesList();
            vm.canListUsers = roleService.canViewUsersList();
            vm.canEditEmailTemplates = roleService.canEditEmailTemplates();
            vm.canEditFirm = roleService.canEditFirm();
            vm.canManage = vm.canListUsers;
            vm.canSendFiles = roleService.canSendFiles();
            vm.isNavigationDisabled = isNavigationDisabled;

            vm.isUserLoggedIn = isUserLoggedIn(isPublicAccess);
        }

        $scope.$on('$routeChangeStart', function (_event, next) {
            updateNavigation(next.isPublicAccess, next.isNavigationDisabled);
        });
    }

    module.exports = NavigationController;
})();