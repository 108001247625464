﻿(function () {

    'use strict';

    let _$scope;
    let _$rootScope;
    let _$routeParams;
    let _firmService;
    let _userService;
    let _timezoneService;
    let _userEditService;

    let vm;

    UserEditController.$inject = [
        '$scope',
        '$rootScope',
        '$routeParams',
        'firmService',
        'userService',
        'timezoneService',
        'userEditService'
    ];

    function UserEditController(
        $scope,
        $rootScope,
        $routeParams,
        firmService,
        userService,
        timezoneService,
        userEditService) {

        _$scope = $scope;
        _$rootScope = $rootScope;
        _$routeParams = $routeParams;
        _firmService = firmService;
        _userService = userService;
        _timezoneService = timezoneService;
        _userEditService = userEditService;

        vm = this;

        vm.firmId = _$routeParams.firmId;
        vm.user = {};
        vm.canEditUser = _userEditService.canEditUser();

        vm.saveUser = saveUser;
        vm.cancel = cancel;

        vm.totalSpaceLimitInMegabytes = 1024;

        vm.timezones = [];
        vm.timezoneSelected = null;

        init();
    }

    function saveUser(user, editUserForm) {
        // For showFormErrors directive.
        _$scope.$broadcast('show-form-errors-check-validity');

        if (editUserForm.$valid) {
            vm.dataLoading = true;

            user.timezoneId = vm.timezoneSelected === null ? null : vm.timezoneSelected.id;

            if (!user.limitUploads)
                delete user.totalSpaceLimitInMegabytes;

            _userService.editUser(user)
                .then(function () {

                    // If the user being edited is the current user, then set the timezone
                    if (user.id === _$rootScope.userProfile.currentUser.userId) {
                        _timezoneService.setTimezone(vm.timezoneSelected);
                    }

                    _userEditService.redirectToUserPage(vm.firmId)
                }, function (response) {
                    _userEditService.growlError(response);
                })
                .finally(function () {
                    vm.dataLoading = false;
                });
        } else {
            return;
        }
    }

    function cancel() {
        _userEditService.redirectToUserPage(vm.firmId)
    }

    function init() {
        let timezone;

        _userService.getUser(_$routeParams.userId)
            .then(function (user) {
                _timezoneService.getTimezones()
                    .then(function (timezones) {
                        vm.timezones = timezones;
                        if (user.timezoneId !== null) {
                            timezone = vm.timezones[
                                vm.timezones.map(function (e) { return e.id; }).indexOf(user.timezoneId)
                            ];
                            vm.timezoneSelected = timezone === undefined? null : timezone;
                        }
                    });

                user.limitUploads = (user.totalSpaceLimitInMegabytes !== null);
                vm.user = user;
            }, function (response) {
                _userEditService.handleFailedInitialize(response);
            })
            .finally(function () {
                vm.dataLoading = false;
            });

        _firmService.getStorageLimits()
            .then(function (firmStorageLimits) {
                vm.totalSpaceLimitInMegabytes = firmStorageLimits.available / (1024 * 1024);
            });
    }

    module.exports = UserEditController;
})();