﻿(function () {

    'use strict';

    fsValidatePassword.$inject = [
        'passwordValidationService'
    ];

    function fsValidatePassword(passwordValidationService) {

        return {
            require: 'ngModel',
            restrict: 'A',
            link: function (_scope, _element, _attributes, controller) {
                controller.$validators.password = function (modelValue) {
                    if (angular.isUndefined(modelValue) || modelValue === null) return true;
                    return passwordValidationService.isValid(modelValue);
                };
            }
        };
    }

    module.exports = fsValidatePassword;
})();