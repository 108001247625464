(function () {
    'use strict';

    AppRunService.$inject = [
        'authenticationService',
        'browserService',
        'maintenanceService',
        'sessionStorageService'
    ];

    function AppRunService(authenticationService, browserService, maintenanceService, sessionStorageService) {
        let service = {
            getUserProfile: getUserProfile,
            isAuthenticated: isAuthenticated,
            isInMaintenance: isInMaintenance,
            isOnline: isOnline,
            isSupportedBrowser: isSupportedBrowser,
            setRedirectPath: setRedirectPath
        };

        function getUserProfile() {
            return sessionStorageService.getItem('userProfile', null) || {};
        }

        function isAuthenticated() {
            return authenticationService.isAuthenticated();
        }

        function isInMaintenance(firmId) {
            maintenanceService.isInMaintenance(firmId);
        }

        function isOnline() {
            return browserService.isOnline();
        }

        function isSupportedBrowser() {
            return browserService.isSupported();
        }

        function setRedirectPath(route) {
            sessionStorageService.setItem('redirectPath', route);
        }

        return service;
    }

    module.exports = AppRunService;

})();