﻿(function () {

    'use strict';

    EmailTemplateService.$inject = [
        'fileShareApi',
        'maximumEmailBodySize',
        'roleService',
        'summernoteFontNames',
        'summernoteFontNamesIgnoreCheck',
        'summernoteToolbarOptions'
    ];

    function EmailTemplateService(fileShareApi, maximumEmailBodySize, roleService, summernoteFontNames, summernoteFontNamesIgnoreCheck, summernoteToolbarOptions) {
        let service = {
            canEditEmailTemplates: canEditEmailTemplates,
            getAllTemplates: getAllTemplates,
            getMaximumEmailBodySize: getMaximumEmailBodySize,
            getSummernoteFontNames: getSummernoteFontNames,
            getSummernoteFontNamesIgnoreCheck: getSummernoteFontNamesIgnoreCheck,
            getSummernoteToolbarOptions: getSummernoteToolbarOptions,
            getTemplate: getTemplate,
            resetTemplate: resetTemplate,
            updateTemplate: updateTemplate
        };

        return service;

        function canEditEmailTemplates() {
            return roleService.canEditEmailTemplates();
        }

        function getAllTemplates() {
            return fileShareApi.getAllEmailTemplates();
        }

        function getMaximumEmailBodySize() {
            return maximumEmailBodySize;
        }

        function getSummernoteFontNames() {
            return summernoteFontNames;
        }

        function getSummernoteFontNamesIgnoreCheck() {
            return summernoteFontNamesIgnoreCheck;
        }

        function getSummernoteToolbarOptions() {
            return summernoteToolbarOptions;
        }

        function getTemplate(slug) {
            return fileShareApi.getEmailTemplate(slug);
        }

        function resetTemplate(slug) {
            return fileShareApi.resetEmailTemplate(slug);
        }

        function updateTemplate(template) {
            return fileShareApi.updateEmailTemplate(template);
        }
    }

    module.exports = EmailTemplateService;
})();