﻿(function () {

    'use strict';

    fsAutoFocus.$inject = [
        '$timeout'
    ];

    function fsAutoFocus($timeout) {

        return {
            restrict: 'A',
            link: function (_scope, element) {
                $timeout(function () {
                    element[0].focus();
                });
            }
        }
    }

    module.exports = fsAutoFocus;

})();