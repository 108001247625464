(function () {

    'use strict';

    MaintenanceService.$inject = ['$injector', 'maintenanceApiService', 'validGuid'];

    function MaintenanceService($injector, maintenanceApiService, validGuid) {

        let service = {
            isInMaintenance: isInMaintenance
        };

        function isInMaintenance(firmId) {
            if (!validGuid.test(firmId)) return;

            let scope = $injector.get('$rootScope');

            maintenanceApiService.isInMaintenance(firmId)
                .then(function (inProgress) {
                    if (inProgress === true) {
                        scope.$emit('in-maintenance');
                    }
                });
        }

        return service;
    }

    module.exports = MaintenanceService;
})();
