﻿/*global angular */
(function () {

    'use strict';

    function editEmail() {
        return {
            restrict: 'EA',
            scope: {
                form: '=',
                isDisabled: '=',
                templateSlug: '@'
            },
            replace: true,
            controller: 'EditEmailController',
            controllerAs: 'vm',
            template: require('./edit-email.view.html'),
            link: function (scope, _element, _attrs, vm) {
                scope.$watch('templateSlug', function () {
                    vm.loadTemplate(scope.templateSlug);
                });

                scope.$watch('isDisabled', function () {
                    if (scope.isDisabled) {
                        angular.element('#emailBody').summernote('disable');
                    } else {
                        angular.element('#emailBody').summernote('enable');
                    }
                });

                scope.$watch('vm.bodyError', function (error) {
                    let body = scope.form.body;
                    if (body) {
                        if (error) {
                            body.$setValidity('emailBody', false);
                        } else {
                            body.$setValidity('emailBody', true);
                        }
                    }
                });
            }
        };
    }

    module.exports = editEmail;

})();