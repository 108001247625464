﻿(function () {
    'use strict';

    function ErrorMessageService() {
        this.getMessageString = function (statusCode) {
            switch (statusCode) {
                case -1:
                    return 'Unable to connect to the server possibly due to maintenance. Log out and try again. If this persists, please contact Support.';
                case 500:
                    return 'Internal server error occurred.';
                default:
                    return 'An unexpected error has occurred. The data on your page may be out of date or there may be a server error.  Please refresh the page and try again.';
            }
        };
    }

    module.exports = ErrorMessageService;
})();