﻿(function () {
    'use strict';

    AuthInterceptor.$inject = [
        '$q',
        'sessionStorageService',
        'apiEndpointValidationService',
        'apiUrlPrefix',
        'apiUrlPort'
    ];

    function AuthInterceptor($q, sessionStorageService, apiEndpointValidationService, apiUrlPrefix, apiUrlPort) {
        return {
            request: function (config) {
                let whitelistedEndpoints = [apiUrlPrefix + (apiUrlPort ? ':' + apiUrlPort : '')];
                apiEndpointValidationService.setWhitelistedEndPoints(whitelistedEndpoints);
                if (apiEndpointValidationService.isWhitelisted(config.url)) {
                    config.headers = config.headers || {};
                    config.headers.Authorization = 'Bearer ' + sessionStorageService.getItem('access_token', null);
                }
                return config;
            },
            response: function (response) {
                return response || $q.resolve(response);
            },
            responseError: function (response) {
                return $q.reject(response || 'Unknown Error');
            }
        };
    }

    module.exports = AuthInterceptor;

})();