﻿(function() {
    'use strict';

    fileNameValidationService.$inject = [
        'invalidFileExtensions'
    ];

    function fileNameValidationService(invalidFileExtensions) {

        let lowercaseInvalidFileExtensions = undefined;

        let service = {
            getInvalidExtensions: getInvalidExtensions,
            hasAsciiHtmlInName: hasAsciiHtmlInName,
            isValid: isValid
        };

        initialize();

        return service;

        function initialize() {
            if (lowercaseInvalidFileExtensions === undefined) {
                lowercaseInvalidFileExtensions = getLowercaseArray(invalidFileExtensions);
            }
        }

        function getLowercaseArray(array) {
            const result = [];

            for (let value of array) {
                result.push(value.toLowerCase());
            }

            return result;
        }

        /**
         * Returns true if any ASCII HTML is found in filename.
         * @param {string} fileName The filename to test for ASCII HTML.
         * @returns {boolean} true if ASCII HTML found, false otherwise.
         */
        function hasAsciiHtmlInName(fileName) {
            const asciiHtmlRegexp = /&#\d{2,4};/;
            return asciiHtmlRegexp.test(fileName);
        }

        function isValid(fileName) {

            const fileNameParts = fileName.split('.');

            if (fileNameParts.length > 1) {
                const extension = fileNameParts[fileNameParts.length - 1].toLowerCase();
                if (lowercaseInvalidFileExtensions.indexOf(extension) >= 0) {
                    return false;
                }
            }

            return true;
        }

        function getInvalidExtensions() {
            return lowercaseInvalidFileExtensions;
        }
    }

    module.exports = fileNameValidationService;
})();