﻿(function () {

    'use strict';

    function fsHeader() {
        return {
            restrict: 'A',
            scope: true,
            template: require('./fs-header.html'),
            replace: true,
            transclude: false,
            controller: 'HeaderController',
            controllerAs: 'vm',
            bindToController: true,
            link: function (scope, element, _attrs, vm) {
                let nav = '.aside-nav .wk-nav';
                let moreMenu = '.aside-nav .wk-more-menu';

                scope.toggleMoreMenu = function () {
                    element.find(nav).toggleClass('open');
                    element.find(moreMenu).toggleClass('open');
                }

                scope.logout = function () {
                    element.find(nav).removeClass('open');
                    element.find(moreMenu).removeClass('open');
                    vm.logout();
                }
            }
        };
    }

    module.exports = fsHeader;
})();