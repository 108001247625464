﻿(function () {

    'use strict';

    function fsNavigation() {
        return {
            restrict: 'A',
            templateUrl: 'app/components/core/navigation/fs-navigation.html',
            controller: 'NavigationController',
            controllerAs: 'vm',
            bindToController: true,
            link: function (scope, element) {
                scope.toggleMenu = function () {
                    element.find('#fs-navigation .wk-nav').toggleClass('open');
                    element.find('#fs-navigation .wk-more-menu').toggleClass('open');
                }
            }
        };
    }

    module.exports = fsNavigation;
})();