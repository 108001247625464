﻿(function () {
    'use strict';

    ErrorService.$inject = [
        'growl',
        '$location',
        'sessionStorageService',
        'errorMessageService',
        '$log'
    ];

    function ErrorService(growl, $location, sessionStorageService, errorMessageService, $log) {
        let service = {
            handleError: handleError
        };

        return service;

        function handleError(error, message = null) {
            if (isAccessDeniedError(error)) {
                redirectToAccessDeniedPage();
            } else {
                growlError(error, message);

                $log.debug(error);
            }
        }

        function isAccessDeniedError(error) {
            return error && error.status === 403;
        }

        function redirectToAccessDeniedPage() {
            let firmId = sessionStorageService.getItem('firmId');
            $location.path('/accessDenied').search({ firmId: firmId }).replace();
        }

        function growlError(error, message) {
            if (!message) {
                let errorCode = error ? error.status : 0;
                message = errorMessageService.getMessageString(errorCode);
            }

            growl.error(message);
        }
    }

    module.exports = ErrorService;
})();