﻿(function() {
    'use strict';

    config.$inject = ['$routeProvider', '$locationProvider', 'userType', 'validGuid'];

    function config($routeProvider, $locationProvider, userType, validGuid) {
        $locationProvider.html5Mode(true);
        $routeProvider.caseInsensitiveMatch = true;
        $routeProvider
            .when('/:firmId/login/v2Redirect',
            {
                isPublicAccess: true,
                template: '',
                controller: ['$route', 'v2RedirectionService', function ($route, v2RedirectionService) {
                    v2RedirectionService.redirect($route.current.params.firmId, '/login');
                }]
            })
            .when('/:firmId/requestPasswordReset/v2Redirect',
            {
                isPublicAccess: true,
                template: '',
                controller: ['$route', 'v2RedirectionService', function ($route, v2RedirectionService) {
                    v2RedirectionService.redirect($route.current.params.firmId, '/requestPasswordReset');
                }]
            })
            .when('/accessDenied',
            {
                template: require('../app/components/core/error/access-denied-error.view.html')
            })
            .when('/unsupportedBrowser',
            {
                template: require('../app/components/core/error/unsupported-browser.view.html'),
                isPublicAccess: true
            })
            .when('/notFound',
            {
                template: require('../app/components/core/error/not-found-error.view.html'),
                isPublicAccess: true
            })
            .when('/offline',
            {
                template: require('../app/components/core/error/offline.view.html'),
                isPublicAccess: true
            })
            .when('/:firmId/mfaError',
            {
                template: require('../app/components/core/error/mfa-error.view.html'),
                isPublicAccess: true
            })
            .when('/:firmId/users',
            {
                template: require('../app/components/user/user-list.view.html'),
                controller: 'UserListController',
                controllerAs: 'vm',
                data: {
                    helpSlug: 'UserAdmin',
                    rolesAllowed: [userType.administrator]
                }
            })
            .when('/:firmId/addUser',
            {
                template: require('../app/components/user/user-add.view.html'),
                controller: 'UserAddController',
                controllerAs: 'vm',
                data: {
                    helpSlug: 'AddUser',
                    rolesAllowed: [userType.administrator, userType.staff]
                }
            })
            .when('/:firmId/editUser/:userId',
            {
                template: require('../app/components/user/user-edit.view.html'),
                controller: 'UserEditController',
                controllerAs: 'vm',
                data: {
                    helpSlug: 'EditUser',
                    rolesAllowed: [userType.administrator]
                },
                // TODO: This code will be removed once angular-ui-router is implemented.
                resolve: {
                    validation: ['$route', '$location', validateGuid()]
                }
            })
            .when('/:firmId/editFirm',
            {
                template: require('../app/components/firm/firm-edit.view.html'),
                controller: 'FirmEditController',
                controllerAs: 'vm',
                data: {
                    helpSlug: 'FirmInfo',
                    rolesAllowed: [userType.administrator]
                }
            })
            .when('/:firmId/emails',
            {
                template: require('../app/components/firm/email-template.view.html'),
                controller: 'EmailTemplateController',
                controllerAs: 'vm',
                data: {
                    helpSlug: 'EmailTemplates',
                    rolesAllowed: [userType.administrator]
                }
            })
            .when('/:firmId/files',
            {
                template: require('../app/components/file/file-list.view.html'),
                controller: 'FileListController',
                controllerAs: 'vm',
                data: {
                    helpSlug: 'FileAdmin',
                    rolesAllowed: [userType.administrator]
                }
            })
            .when('/:firmId/files/reassign',
            {
                template: require('../app/components/file/file-assignment.view.html'),
                controller: 'FileAssignmentController',
                controllerAs: 'vm',
                data: {
                    helpSlug: 'ReassignFiles',
                    rolesAllowed: [userType.administrator]
                }
            })
            .when('/:firmId',
            {
                template: require('../app/components/file/my-files-list.view.html'),
                controller: 'MyFilesListController',
                controllerAs: 'vm',
                data: {
                    helpSlug: 'MyFiles'
                }
            })
            .when('/:firmId/login',
            {
                template: require('../app/components/login/login.view.html'),
                controller: 'LoginController',
                controllerAs: 'vm',
                isPublicAccess: true
            })
            .when('/:firmId/partialLogin',
            {
                template: require('../app/components/login/partial-login.view.html'),
                controller: 'PartialLoginController',
                controllerAs: 'vm',
                isPublicAccess: true
            })
            .when('/:firmId/logout',
            {
                template: require('../app/components/login/login.view.html'),
                controller: 'LoginController',
                controllerAs: 'vm',
                isPublicAccess: true
            })
            .when('/:firmId/myFiles',
            {
                template: require('../app/components/file/my-files-list.view.html'),
                controller: 'MyFilesListController',
                controllerAs: 'vm',
                data: {
                    helpSlug: 'MyFiles'
                }
            })
            .when('/:firmId/publicFiles',
            {
                template: require('../app/components/file/public-file-list.view.html'),
                controller: 'PublicFileListController',
                controllerAs: 'vm',
                data: {
                    helpSlug: 'PublicFiles'
                }
            })
            .when('/:firmId/receivedFiles',
            {
                template: require('../app/components/file/received-files-list.view.html'),
                controller: 'ReceivedFilesListController',
                controllerAs: 'vm',
                data: {
                    helpSlug: 'ReceivedFiles'
                }
            })
            .when('/:firmId/sharedFiles',
            {
                template: require('../app/components/file/shared-files-list.view.html'),
                controller: 'SharedFilesListController',
                controllerAs: 'vm',
                data: {
                    helpSlug: 'SharedFilesList'
                }
            })
            .when('/:firmId/sharing',
            {
                template: require('../app/components/firm/sharing-options.view.html'),
                controller: 'SharingOptionsController',
                controllerAs: 'vm',
                data: {
                    helpSlug: 'SharingOptions',
                    rolesAllowed: [userType.administrator]
                }
            })
            .when('/:firmId/userFiles/:userId',
            {
                template: require('../app/components/file/user-files-list.view.html'),
                controller: 'UserFilesListController',
                controllerAs: 'vm',
                data: {
                    helpSlug: 'UsersFiles',
                    rolesAllowed: [userType.administrator]
                },
                // TODO: This code will be removed once angular-ui-router is implemented.
                resolve: {
                    validation: ['$route', '$location', validateGuid()]
                }
            })
            .when('/:firmId/register',
            {
                template: require('../app/components/user/user-register.view.html'),
                controller: 'UserRegisterController',
                controllerAs: 'vm',
                data: {
                    helpSlug: 'RegisterAcct'
                },
                isPublicAccess: true
            })
            .when('/:firmId/requestPasswordReset',
            {
                template: require('../app/components/user/request-password-reset.view.html'),
                controller: 'RequestPasswordResetController',
                controllerAs: 'vm',
                data: {
                    helpSlug: 'ResetPassword'
                },
                isPublicAccess: true
            })
            .when('/:firmId/resetPassword/:userId',
            {
                template: require('../app/components/user/reset-password.view.html'),
                controller: 'ResetPasswordController',
                controllerAs: 'vm',
                isPublicAccess: true,
                // TODO: This code will be removed once angular-ui-router is implemented.
                resolve: {
                    validation: ['$route', '$location', function ($route, $location) {
                        if (!validGuid.test($route.current.params.userId)) {
                            $location.path('/notFound');
                        }
                    }]
                }
            })
            .when('/:firmId/myProfile',
            {
                template: require('../app/components/user/user-profile.view.html'),
                controller: 'UserProfileController',
                controllerAs: 'vm',
                data: {
                    helpSlug: 'EditMyProfile'
                }
            })
            .when('/:firmId/changePassword',
            {
                template: require('../app/components/user/change-password.view.html'),
                controller: 'ChangePasswordController',
                controllerAs: 'vm'
            })
            .when('/:firmId/uploadFiles',
            {
                template: require('../app/components/file/file-upload.view.html'),
                controller: 'FileUploadController',
                controllerAs: 'vm',
                data: {
                    helpSlug: 'ShareFilesPage'
                }
            })
            .when('/:firmId/:unknownPage*',
            {
                template: require('../app/components/core/error/not-found-error.view.html')
            })
            .otherwise({
                template: require('../app/components/core/error/not-found-error.view.html'),
                isPublicAccess: true
            });

        function validateGuid() {
            return function ($route, $location) {
                if (!validGuid.test($route.current.params.userId)) {
                    $location.path('/notFound').replace();
                }
            };
        }
    }

    module.exports = config;

})();