﻿(function () {

    'use strict';

    RequestPasswordResetController.$inject = [
        '$routeParams',
        '$location',
        '$scope',
        'growl',
        'errorService',
        'userService',
        'dataShareService'
    ];

    function RequestPasswordResetController($routeParams, $location, $scope, growl, errorService, userService, dataShareService) {

        let vm = this;

        vm.firmId = $routeParams.firmId;
        vm.dataLoading = false;
        vm.cancel = cancel;
        vm.reset = reset;
        vm.email = dataShareService.getData('email');
         
        function cancel() {
            $location.path('/' + vm.firmId + '/login');
        }

        function reset(resetPasswordForm) {
            if (!resetPasswordForm.$valid) {
                $scope.$broadcast('show-form-errors-check-validity');
                return;
            }

            vm.dataLoading = true;

            userService.requestPasswordReset({ 'firmId': vm.firmId, 'email': vm.email })
                .then(function () {
                    growl.success('An email has been sent with further instructions.');
                    $location.url('/' + vm.firmId + '/login');
                }, errorService.handleError)
                .finally(function() {
                    vm.dataLoading = false;
                });
        }
    }

    module.exports = RequestPasswordResetController;
})();