﻿(function () {

    'use strict';

    fsMaxWidth.$inject = [
        '$interval'
    ];

    function fsMaxWidth($interval) {
        return {
            restrict: 'A',
            scope: true,
            link: function (_$scope, $element, $attributes) {
                // This is to handle async loading of the firm logo
                let checkForLogo = $interval(function () {
                    if ($element[0].querySelector('.logo-mark')) {
                        setFirmLogoWidth();
                        $interval.cancel(checkForLogo);
                    }
                }, 100);

                function setFirmLogoWidth() {
                    let width = $element[0].querySelector('.logo-mark').width;
                    $attributes.$set('style', 'max-width: ' + width + 'px');
                }
            }
        }
    }

    module.exports = fsMaxWidth;

})();