﻿(function () {

    'use strict';

    roleService.$inject = [
        'sessionStorageService'
    ];

    function roleService(sessionStorageService) {
        let addUser = 'addUser';
        let changeSharingOptions = 'changeSharingOptions';
        let changeUserType = 'changeUserType';
        let canUploadFilesKey = 'canUploadFiles';
        let deleteUser = 'deleteUser';
        let editEmailTemplates = 'editEmailTemplates';
        let editFirm = 'editFirm';
        let editUser = 'editUser';
        let editUserViewSettings = 'editUserViewSettings';
        let userTypeKey = 'userType';
        let viewUsersList = 'viewUsersList';
        let bulkDeleteAdministratorFiles = 'bulkDeleteAdministratorFiles';
        let bulkDownloadAllFiles = 'bulkDownloadAllFiles';
        let deleteAdministratorFile = 'deleteAdministratorFile';
        let sendFiles = 'sendFiles';
        let viewAllFilesList = 'viewAllFilesList';
        let viewUserFiles = 'viewUserFiles';
        let reassignAllFiles = 'reassignAllFiles';
        let userTypeName =
        {
            1: 'Administrator',
            2: 'Staff',
            3: 'Client'
        };

        let service = {
            canAddUser: canAddUser,
            canChangeSharingOptions: canChangeSharingOptions,
            canChangeUserType: canChangeUserType,
            canDeleteUser: canDeleteUser,
            canEditEmailTemplates: canEditEmailTemplates,
            canEditFirm: canEditFirm,
            canEditUser: canEditUser,
            canEditUserViewSettings: canEditUserViewSettings,
            canViewUsersList: canViewUsersList,
            canDeleteAdministratorFile: canDeleteAdministratorFile,
            canViewAllFilesList: canViewAllFilesList,
            canViewUserFiles: canViewUserFiles,
            canBulkDeleteAdministratorFiles: canBulkDeleteAdministratorFiles,
            canBulkDownloadAllFiles: canBulkDownloadAllFiles,
            canSendFiles: canSendFiles,
            canReassignAllFiles: canReassignAllFiles,
            getCanUploadFiles: getCanUploadFiles,
            getUserType: getUserType,
            hasRoleRequired: hasRoleRequired,
            setCanUploadFiles: setCanUploadFiles,
            setUserType: setUserType
        };

        let accessControlData = {
            Administrator: [
                viewUsersList,
                addUser,
                changeSharingOptions,
                changeUserType,
                editEmailTemplates,
                editFirm,
                editUser,
                editUserViewSettings,
                deleteUser,
                bulkDeleteAdministratorFiles,
                bulkDownloadAllFiles,
                deleteAdministratorFile,
                sendFiles,
                viewAllFilesList,
                viewUserFiles,
                reassignAllFiles
            ],
            Staff: [
                addUser,
                sendFiles,
                bulkDownloadAllFiles
            ],
            Client: [
                sendFiles,
                bulkDownloadAllFiles
            ]
        }

        function hasPermission(action) {
            let userType = sessionStorageService.getItem(userTypeKey, null);
            return $.inArray(action, accessControlData[userTypeName[userType]]) >= 0;
        }

        function canAddUser() {
            return hasPermission(addUser);
        }

        function canChangeSharingOptions() {
            return hasPermission(changeSharingOptions);
        }

        function canChangeUserType() {
            return hasPermission(changeUserType);
        }

        function canDeleteUser() {
            return hasPermission(deleteUser);
        }

        function canEditEmailTemplates() {
            return hasPermission(editEmailTemplates);
        }

        function canEditFirm() {
            return hasPermission(editFirm);
        }

        function canEditUser() {
            return hasPermission(editUser);
        }

        function canEditUserViewSettings() {
            return hasPermission(editUserViewSettings);
        }

        function canViewUsersList() {
            return hasPermission(viewUsersList);
        }

        function canBulkDeleteAdministratorFiles() {
            return hasPermission(bulkDeleteAdministratorFiles);
        }

        function canBulkDownloadAllFiles() {
            return hasPermission(bulkDownloadAllFiles);
        }

        function canDeleteAdministratorFile() {
            return hasPermission(deleteAdministratorFile);
        }

        function canViewAllFilesList() {
            return hasPermission(viewAllFilesList);
        }

        function canViewUserFiles() {
            return hasPermission(viewUserFiles);
        }

        function canSendFiles() {
            return hasPermission(sendFiles) && getCanUploadFiles();
        }

        function canReassignAllFiles() {
            return hasPermission(reassignAllFiles);
        }

        function setCanUploadFiles(canUploadFiles) {
            sessionStorageService.setItem(canUploadFilesKey, canUploadFiles);
        }

        function getCanUploadFiles() {
            return sessionStorageService.getItem(canUploadFilesKey);
        }

        function setUserType(userTypeValue) {
            sessionStorageService.setItem(userTypeKey, userTypeValue);
        }

        function getUserType() {
            return sessionStorageService.getItem(userTypeKey);
        }

        function hasRoleRequired(roles) {
            let userTypeAsString = sessionStorageService.getItem(userTypeKey, null);

            if (userTypeAsString === null) return false;

            let userType = parseInt(userTypeAsString);

            return (roles.indexOf(userType) > -1);
        }

        return service;
    }

    module.exports = roleService;
})();