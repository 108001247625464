﻿'use strict';

let angular = require('angular');

angular.module('app').controller('EmailTemplateController', require('./email-template.controller'));
angular.module('app').controller('FirmEditController', require('./firm-edit.controller'));
angular.module('app').controller('SharingOptionsController', require('./sharing-options.controller'));

angular.module('app').directive('emailTemplateDialog', require('./directives/email-template-dialog.directive'));
angular.module('app')
    .directive('fsUserSelectedForUpload', require('./directives/fs-user-selected-for-upload.directive'));