﻿(function () {

    'use strict';

    function fsScaleText() {
        return {
            restrict: 'A',
            scope: true,
            link: function ($scope, $element) {
                let container = angular.element($element[0]);
                let scalable = angular.element($element[0].querySelector('.scalable-text'));

                $scope.$watch(scalable, function () {
                    while (scalable.height() > container.height()) {
                        scalable.css('font-size', (parseInt(scalable.css('font-size')) - 1) + 'px');
                    }
                });
            }
        }
    }

    module.exports = fsScaleText;

})();