﻿(function () {

    'use strict';

    function dataUri() {
        return function (image) {
            return 'data:image/png;base64, ' + image;
        };
    }

    module.exports = dataUri;
})();