﻿(function () {

    'use strict';

    EditEmailController.$inject = [
        '$scope',
        '$filter',
        'summernoteFontNames',
        'summernoteFontNamesIgnoreCheck',
        'summernoteToolbarOptions',
        'maximumEmailBodySize',
        'emailTemplateService'
    ];

    function EditEmailController($scope, $filter, summernoteFontNames, summernoteFontNamesIgnoreCheck,
        summernoteToolbarOptions, maximumEmailBodySize, emailTemplateService) {
        let vm = this;

        vm.emailTemplate = {};
        vm.loadTemplate = loadTemplate;
        vm.maximumEmailBodySize = maximumEmailBodySize;

        vm.bodyError = null;
        vm.validateBody = validateBody;

        vm.summernoteOptions = {
            toolbar: summernoteToolbarOptions,
            buttons: {
                keywords: insertKeywordTags
            },
            disableDragAndDrop: true,
            followingToolbar: true,
            fontNames: summernoteFontNames,
            fontNamesIgnoreCheck: summernoteFontNamesIgnoreCheck
        };

        function insertKeywordTags(context) {
            let htmlTags = '';
            angular.forEach(vm.emailTemplate.tags, function (tag) {
                htmlTags += '<a class="note-dropdown-item" href="#" data-item="' + tag + '" data-value="{{' + tag + '}}" role="listitem">' + tag + '</a>';
            });

            let ui = $.summernote.ui;

            let keywordTags = ui.buttonGroup([
                ui.button({
                    className: 'dropdown-toggle',
                    contents: '<span>Keywords&nbsp;</span><span class="note-icon-caret"/>',
                    tooltip: 'Insert Keywords',
                    container: 'body',
                    click: function () {
                        context.invoke('editor.saveRange');
                    },
                    data: {
                        toggle: 'dropdown'
                    }
                }),
                ui.dropdown({
                    className: '',
                    contents: htmlTags,
                    callback: function ($dropdown) {
                        $dropdown.find('a')
                            .each(function () {
                                $(this)
                                    .on('click', function () {
                                        context.invoke('editor.restoreRange');
                                        context.invoke('editor.focus');
                                        context.invoke('editor.insertText', $(this).attr('data-value'));
                                    });
                            });
                    }
                })
            ]).render();

            return keywordTags;
        }

        function validateKeywordTags(text) {
            let keywordTags = [];
            text.replace(/\{\{(.*?)\}\}/g, function () {
                keywordTags.push(arguments[1]);
            });

            let invalidTag;
            let keywordTagsToCheck = new RegExp(vm.emailTemplate.tags.join('|'));
            keywordTags.forEach(function (tag) {
                if (!keywordTagsToCheck.test(tag)) {
                    invalidTag = $filter('truncate')(tag, 30);
                }
            });
            return invalidTag;
        }

        $scope.$watch('vm.emailTemplate.body', function () {
            vm.validateBody();
        });

        function validateBody() {
            vm.bodyError = null;
            let body = vm.emailTemplate.body;
            
            if (!body) {
                vm.bodyError = 'You must enter a valid Body.';
                return false;
            }

            let invalidTag = validateKeywordTags(body);
            if (invalidTag) {
                vm.bodyError = 'The keyword ' + invalidTag + ' is invalid.';
            }

            if (body.length > vm.maximumEmailBodySize) {
                vm.bodyError = 'Email body must be between 1 and ' + vm.maximumEmailBodySize + ' characters.';
            }
        }

        function loadTemplate(slug) {
            emailTemplateService.getTemplate(slug)
                .then(function (template) {
                    if (template) {
                        vm.emailTemplate = template;
                    }
                });
        }
    }

    module.exports = EditEmailController;

})();