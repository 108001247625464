﻿(function() {
    'use strict';

    FileDownloadController.$inject = [
        'v4FileDownloadService'
    ];
    
    function FileDownloadController(v4FileDownloadService) {
        let vm = this;

        vm.downloadFile = downloadFile;

        function downloadFile(fileId) {
            return v4FileDownloadService.downloadFile(fileId);
        }
    }

    module.exports = FileDownloadController;
})();