﻿(function () {

    'use strict';

    SharedFilesListController.$inject = [
        '$controller',
        '$scope',
        'errorService',
        'fileService'
    ];

    function SharedFilesListController($controller, $scope, errorService, fileService) {
        $scope.viewSlug = 'shared-files';
        
        let vm = $controller('FileListBaseController', {$scope: $scope});

        vm.getFiles = function () {
            return function () {
                fileService.getSharedFiles(vm.selectedPage, vm.filePageSize, vm.sortType, vm.isSortDescending, vm.searchTerm)
                    .then(vm.handleSuccessfulGetFiles(), errorService.handleError)
                    .finally(function () { vm.dataLoading = false; });
            };
        }

        vm.isValidSortType = function (sortType) {
            let validSortTypes = ['Name', 'UploadDateTime', 'Size', 'AssignedTo', 'DownloadCount'];
            return validSortTypes.indexOf(sortType) > -1;
        }
    
        return vm;
    }

    module.exports = SharedFilesListController;
})();