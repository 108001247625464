﻿(function() {
    'use strict';

    FileDownloadService.$inject = [
        '$sanitize',
        '$window',
        'growl',
        'v4FileDownloadService'
    ];

    /**
     * @deprecated since version 4.0
     * @param {any} $sanitize The $sanitize service.
     * @param {any} $window The Window
     * @param {any} growl The growl service.
     * @param {any} v4FileDownloadService The v4 download service.
     * @returns {FileDownloadService} The Service.
     */
    function FileDownloadService($sanitize, $window, growl, v4FileDownloadService) {
        let zipDownloadErrorMessage = 'One or more of your files could not be downloaded. Please refresh your page.';

        let service = {
            downloadZip: downloadZip,
            getUrl: getDownloadUrl
        };

        function downloadZip(fileIds) {
            return v4FileDownloadService.getZipDownloadUrl(fileIds)
                .then(function(data) {
                    let isPopupBlocked = false;
                    const newWindow = $window.open(data.url, '_self');
                    if(!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') 
                    {
                        isPopupBlocked = true;
                    }

                    if (isPopupBlocked) {
                        let message = `<strong>Incomplete download!</strong><p>Your browser\'s pop-up blocker prevented some files from downloading. Please allow pop-ups for ${$window.location.hostname} and retry the download again.</p>`;
                        message = $sanitize(message);
                        growl.error(message);
                    }
                }, function (reason) {
                    showGrowlMessage(reason);
                });
        }
               
        function getDownloadUrl(firmId, fileId) {
            return '/' + firmId + '/downloadFile/' + fileId;
        }

        function showGrowlMessage(reason) {
            let message = zipDownloadErrorMessage;
            if (reason.indexOf('size-exceeded:') === 0) {
                message = reason.substr(14);
            }
            growl.error(message);
        }

        return service;
    }

    module.exports = FileDownloadService;
})();