﻿(function () {
    'use strict';

    PartialLoginController.$inject = [
        '$q',
        '$location',
        '$routeParams',
        'growl',
        'authenticationService',
        'loginService',
        'userService'
    ];

    // ReSharper disable once InconsistentNaming
    function PartialLoginController(
        $q,
        $location,
        $routeParams,
        growl,
        authenticationService,
        loginService,
        userService) {
        let vm = this;

        vm.dataLoading = true;
        vm.redirectPath = null;

        vm.firmId = $routeParams.firmId;
        vm.payload = $routeParams.payload;
        vm.mfaCompletionId = $routeParams.mfaCompletionId;

        function init() {
            vm.redirectPath = loginService.getRedirectPath();
            if (vm.payload && vm.mfaCompletionId) {
                authenticationService.tokenLogin(vm.firmId, vm.payload, vm.mfaCompletionId)
                    .then(loadUserInfoAndRedirect, handleLoginError)
                    .finally(function () {
                        $location.search({});
                    });
            }
        }

        function handleLoginError(response) {
            switch (response) {
                case 'invalid-mfa-completion-id':
                    growl.error('Unable to complete 2-Step Verification. Please try again in a few minutes.');
                    $location.path('/' + vm.firmId + '/login');
                    break;
                default:
                    loginService.handleError(response);
                    break;
            }
        }

        function loadUserInfoAndRedirect() {
            $q.all([
                    userService.getUserType().then(setUserType),
                    userService.getCanUploadFiles().then(setFileUploadFlag),
                    userService.getTimezone().then(setTimezone)
                ])
                .then(function () {
                    if (vm.redirectPath) {
                        $location.path(vm.redirectPath).search({});
                    } else {
                        $location.path('/' + vm.firmId).search({});
                    }
                })
                .finally(function () {
                    vm.dataLoading = false;
                });
        }

        function setTimezone(timezone) {
            loginService.setTimezone(timezone);
        }

        function setUserType(userType) {
            loginService.setUserType(userType);
        }

        function setFileUploadFlag(canUploadFiles) {
            loginService.setCanUploadFiles(canUploadFiles);
        }

        init();
    }

    module.exports = PartialLoginController;
})();