﻿(function () {

    'use strict';

    RouteCheckerService.$inject = [
        'unauthenticatedRoutes'
    ];

    function RouteCheckerService(unauthenticatedRoutes) {

        let service = {
            isAuthenticationRequired: isAuthenticationRequired
        };

        return service;

        function isAuthenticationRequired(route) {
            let result = true;

            let i;

            for (i = 0; i < unauthenticatedRoutes.length; i++) {
                if (unauthenticatedRoutes[i].test(route)) {
                    result = false;
                    break;
                }
            }

            return result;
        }
    }

    module.exports = RouteCheckerService;
})();