﻿(function () {

    'use strict';

    fsFileSelection.$inject = [
        '$document'
    ];

    function fsFileSelection($document) {
        return {
            restrict: 'A',
            scope: true,
            replace: true,
            template: require('./fs-file-selection.view.html'),
            controller: 'FileSelectionController',
            controllerAs: 'vm',
            bindToController: true,
            link: function (scope, elem, _attrs, vm) {
                scope.fileDisplayLength = 50;

                let fileInput = angular.element(elem[0].querySelector('#file-input'));
                fileInput.on('change', vm.fileSelect);
                fileInput.on('click', clearFileInput);

                function clearFileInput() {
                    fileInput[0].value = '';
                }

                $document.on('dragover', vm.fileDragHover);
                $document.on('dragleave', vm.fileDragHover);
                $document.on('drop', vm.fileDrop);

                scope.$on('$destroy', function () {
                    unbindEventHandlers();
                });

                function unbindEventHandlers() {
                    fileInput.off('change');
                    fileInput.off('click');
                    $document.off('dragover');
                    $document.off('dragleave');
                    $document.off('drop');
                }
            }
        };
    }

    module.exports = fsFileSelection;
})();