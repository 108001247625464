/*globals angular*/
(function () {

    'use strict';

    MaintenanceApiService.$inject = [
        '$http',
        'apiUrlPrefix',
        'apiUrlPort'
    ];

    function MaintenanceApiService($http, apiUrlPrefix, apiUrlPort) {

        let service = {
            isInMaintenance: isInMaintenance,
        };

        let apiServerUrl = apiUrlPrefix + (apiUrlPort ? ':' + apiUrlPort : '');
        let baseApiUrl = apiServerUrl + '/api';

        function isInMaintenance(firmId) {
            return $http.get(baseApiUrl + '/maintenance/' + firmId + '/is-in-maintenance')
                .then(function (response) {
                    return response.data;
                });
        }

        return service;
    }

    module.exports = MaintenanceApiService;
})();