(function () {
    'use strict';

    let _$location;
    let _$window;
    let _$routeParams;
    let _authenticationService;
    let _errorService;
    let _growl;
    let _roleService;

    userProfileService.$inject = [
        '$location',
        '$routeParams',
        '$window',
        'authenticationService',
        'errorService',
        'growl',
        'roleService'
    ];

    function userProfileService($location, $routeParams, $window, authenticationService, errorService, growl, roleService) {
        _$location = $location;
        _$routeParams = $routeParams;
        _$window = $window;
        _authenticationService = authenticationService;
        _errorService = errorService;
        _growl = growl;
        _roleService = roleService;

        return {
            backToPreviousPage: backToPreviousPage,
            canEditUserViewSettings: canEditUserViewSettings,
            handleError: handleError,
            redirectToChangePasswordPage: redirectToChangePasswordPage,
            redirectToLoginPage: redirectToLoginPage
        };
    }

    function backToPreviousPage() {
        _$window.history.back();
    }

    function canEditUserViewSettings() {
        return _roleService.canEditUserViewSettings();
    }

    function handleError(response) {
        _errorService.handleError(response);        
    }

    function redirectToChangePasswordPage() {
        _$location.url('/' + _$routeParams.firmId + '/changePassword');        
    }

    function redirectToLoginPage() {
        _authenticationService.invalidate();
        _growl.info('Your email has been changed successfully. Please login again using the new email address.');
        _$location.url('/' + _$routeParams.firmId + '/login');
    }

    module.exports = userProfileService;

})();