﻿(function () {

    'use strict';

    fsConfirmDialog.$inject = [
        '$document'
    ];

    let _$document;

    function fsConfirmDialog($document) {
        return {
            restrict: 'A',
            scope: {
                title: '@',
                okButtonText: '@',
                okCallback: '&',
                primaryButton: '@'
            },
            replace: true,
            transclude: true,
            template: require('./fs-confirm-dialog.html'),
            link: function (scope, element) {
                _$document = $document;

                if (scope.title === undefined) {
                    scope.title = 'Are You Sure?';
                }
                if (scope.okButtonText === undefined) {
                    scope.okButtonText = 'OK';
                }

                scope.ok = function () {
                    scope.okCallback();
                    display(scope, element, false);
                };
                scope.cancel = function () {
                    display(scope, element, false);
                };
                scope.$on('show-confirm-dialog', function () {
                    display(scope, element, true);
                });
            }
        };
    }

    function display(scope, element, show) {
        show ? bindKeyEvent(scope) : unbindKeyEvent();
        element[0].style.display = show ? 'inline' : 'none';

        let id = scope.primaryButton === 'ok' ? '#confirm-dialog-ok' : '#confirm-dialog-cancel';
        element[0].querySelector(id).focus();
    }

    function bindKeyEvent(scope) {
        _$document.on('keydown keypress', function (event) {
            switch (event.which) {
            case 13:
                scope.primaryButton === 'ok' ? scope.ok() : scope.cancel();
                break;
            case 27:
                scope.cancel();
                break;
            default:
                return;
            }
            scope.$apply();
            event.preventDefault();
        });
    }

    function unbindKeyEvent() {
        _$document.off('keydown keypress');
    }
    
    module.exports = fsConfirmDialog;
})();
