﻿(function () {

    'use strict';

    UserFilesListController.$inject = [
        '$controller',
        '$location',
        '$scope',
        '$routeParams',
        'errorService',
        'fileService',
        'userService'
    ];

    let vm;

    function UserFilesListController($controller, $location, $scope, $routeParams, errorService, fileService, userService) {
        $scope.viewSlug = 'user-files';
    
        vm = $controller('FileListBaseController', {$scope: $scope});

        vm.userId = $routeParams.userId;
        vm.user = getUserInfo(vm.userId);

        vm.getFiles = function () {
            return function () {
                fileService.getUserFiles(vm.userId, vm.selectedPage, vm.filePageSize, vm.sortType, vm.isSortDescending, vm.searchTerm)
                    .then(vm.handleSuccessfulGetFiles(), function(response){ handleError(response); })
                    .finally(function () { vm.dataLoading = false; });
            };
        }

        vm.handleSearchTerm = function() {
            return function (newValue, oldValue) {
               if (newValue !== oldValue) {
                   getAll();
               }
            };
        }
   
        function getUserInfo(userId) {
            return userService.getUser(userId)
                .then(function (user) {
                    vm.user = user;
                    vm.getAll();
                }, function (response) {
                    handleError(response);
                });
        }
    
        function handleError(response) {
            if (response.status === 404) {
                $location.path('/notFound').replace();
            } else {
                errorService.handleError(response);
            }
        }
    
        return vm;
    }

    module.exports = UserFilesListController;
})();