﻿(function () {

    'use strict';

    fsUserUpload.$inject = [
        'fileShareApi'
    ];

    function fsUserUpload(fileShareApi) {
        return {
            restrict: 'E',
            replace: true,
            template: '<input type="checkbox" aria-label="User Can Upload Files" ng-model="user.canUploadFiles">',
            link: function ($scope) {
                $scope.$watch('user.canUploadFiles', function (newValue, oldValue) {
                    if (newValue !== oldValue) {
                        fileShareApi.setCanUploadFiles($scope.user.id, newValue);
                    }
                });
            }
        }
    }

    module.exports = fsUserUpload;
})();