﻿(function () {

    'use strict';

    IdleConfiguration.$inject = [
        'IdleProvider',
        'idleInSeconds',
        'idleTimeoutInSeconds'
    ];

    // ReSharper disable once InconsistentNaming
    function IdleConfiguration(IdleProvider, idleInSeconds, idleTimeoutInSeconds) {
        IdleProvider.idle(idleInSeconds);
        IdleProvider.timeout(idleTimeoutInSeconds);
        IdleProvider.keepalive(false);
    }

    module.exports = IdleConfiguration;

})();