﻿(function () {

    'use strict';

    function fsTooltip() {
        return {
            restrict: 'EA',
            link: function (_$scope, $element) {
                $element.ready(function() {
                    $element.tooltip();
                });
            }
        }
    }

    module.exports = fsTooltip;

})();