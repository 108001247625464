﻿(function () {

    'use strict';

    TimezoneService.$inject = [
        'fileShareApi',
        'sessionStorageService',
        'moment'
    ];

    function TimezoneService(fileShareApi, sessionStorageService, moment) {

        const defaultTimezone = {
                id: 12,
                displayName: 'Central Time (US & Canada)',
                momentName: 'America/Chicago'
        };

        let service = {
            defaultTimezone: Object.freeze(defaultTimezone),
            getMomentName: getMomentName,
            getTimezoneTooltip: getTimezoneTooltip,
            getTimezones: getTimezones,
            getTimezone: getTimezone,
            setTimezone: setTimezone
        };

        return service;

        function getMomentName(timezone) {
            if (timezone) {
                return timezone.momentName;
            } else {
                return moment.tz.guess() || defaultTimezone.momentName;
            }
        }

        function getTimezone() {
            return sessionStorageService.getItem('timezone', null);
        }

        function getTimezoneTooltip(timezone) {
            return timezone ? timezone.displayName : 'The time zone selection can be changed in your user profile.';
        }

        function getTimezones() {
            return fileShareApi.getTimezones();
        }

        function setTimezone(timezone) {
            sessionStorageService.setItem('timezone', timezone);
        }
    }

    module.exports = TimezoneService;
}());