﻿(function () {

    'use strict';

    FirmEditController.$inject = [
        '$q',
        '$scope',
        '$filter',
        'growl',
        'errorService',
        'firmEditService',
        'firmService'
    ];
    
    let _$q;
    let _$scope;
    let _$filter;
    let _growl;
    let _errorService;
    let _firmEditService;
    let _firmService;
    
    let vm;

    // ReSharper disable once InconsistentNaming
    function FirmEditController($q,
        $scope,
        $filter,
        growl,
        errorService,
        firmEditService,
        firmService) {

        _$q = $q;
        _$scope = $scope;
        _$filter = $filter;
        _growl = growl;
        _errorService = errorService;
        _firmEditService = firmEditService;
        _firmService = firmService;
                    
        vm = this;

        vm.firm = {};
        vm.administrators = [];
        vm.firmAdministrator = null;
        vm.canEditFirm = _firmEditService.canEditFirm();

        vm.updateFirm = updateFirm;
        vm.cancel = cancel;

        vm.maximumLogoFileSize = _firmEditService.getMaximumLogoFileSize();
        vm.maximumLogoDimension = _firmEditService.getMaximumLogoDimension();
        vm.maximumLogoHeight = _firmEditService.getMaximumLogoHeight();
        vm.maximumLogoWidth = _firmEditService.getMaximumLogoWidth();

        vm.firmLogoWidth = _firmEditService.getDefaultLogoWidth();

        vm.isFirmAdministratorDirty = false;
        vm.isFirmAdministratorValid = isFirmAdministratorValid;

        // TODO This is temporary until the flexbox css is addressed/redone
        _$scope.$watch(function () {
            return angular.element('.preview-area .logo-mark')[0].src;
        }, function (src, prev) {
            if (src !== prev) {
                if (vm.firm.logo) {
                    let image = new Image();
                    image.onload = function () {
                        vm.firmLogoWidth = image.width;
                        _$scope.$apply();
                    };
                    image.src = src;
                }
            }
        });

        _$scope.$watch('vm.firm.logo', function () {
            if (_$scope.editFirmForm.firmLogoUpload.$error.maxSize) {
                _growl.error('The firm logo is limited to ' + vm.maximumLogoFileSize + '.');
            }

            if (_$scope.editFirmForm.firmLogoUpload.$error.pattern) {
                _growl.error('The firm logo is limited to image files.');
            }
        });

        // TODO: rewrite picklist so that form can detect change without watching it.
        _$scope.$watch('vm.firmAdministrator', function (_newValue, oldValue) {
            if (oldValue != null) {
                vm.isFirmAdministratorDirty = true;
            }
        });

        _$q.all({
                admins: _firmService.getFirmAdministrators(),
                firm: _firmService.getFirm()
            })
            .then(function (response) {
                loadFirmAdministrators(response.admins);
                hydrateFirm(response.firm);
                setFirmAdministrator(response.firm.administratorId);
            }, _errorService.handleError)
            .finally(function () {
                vm.dataLoading = false;
            });

    }

    // TODO: Taken from file-upload controller, needs to be consolidated.
    function loadFirmAdministrators(admins) {
        for (let i = 0; i < admins.length; i++) {
            let admin = admins[i];
            vm.administrators.push({
                value: admin.firstName + ' ' + admin.lastName + ' ' + '(' + admin.email + ')',
                id: admin.id
            });
        }
    }

    function setFirmAdministrator(adminId) {
        let admin = vm.administrators.filter(function (a) { return a.id === adminId })[0];
        vm.firmAdministrator = admin.value;
    }

    function getAdministratorId(value) {
        // TODO: Needs refactoring. When should users return null?
        let admins = vm.administrators.filter(function (admin) { return admin.value === value });
        return admins.length === 1 ? admins[0].id : null;
    }

    function isFirmAdministratorValid() {
        return getAdministratorId(vm.firmAdministrator) !== null;
    }

    // TODO: Workaround for ng-file-upload not appending to src.
    // TODO: Modify/Override ng-file-upload to include on src attribute.
    function hydrateFirm(firm) {
        if (firm.logo) {
            firm.logo = _$filter('dataUri')(firm.logo);
        }
        vm.firm = firm;
    }

    function uploadLogo(logo) {
        if (typeof logo === 'object' && logo !== null) {
            return _firmService.uploadLogo(logo);
        } else {
            return _$q.resolve();
        }
    }

    function isFormValid(editFirmForm) {
        return editFirmForm.$valid && isFirmAdministratorValid();
    }

    function updateFirm(firm, editFirmForm) {
        // For showFormErrors directive.
        _$scope.$broadcast('show-form-errors-check-validity');

        if (isFormValid(editFirmForm)) {
            vm.dataLoading = true;

            firm.administratorId = getAdministratorId(vm.firmAdministrator);

            // Don't pass firm logo to firmService.editFirm because passing the logo already saved is data URI and is blocked by WAF.
            // Logo will be updated in updateFirmLogo only when new logo file is selected.
            let editFirm = angular.copy(firm);
            editFirm.logo = null;

            _firmService.editFirm(editFirm)
                .then(updateFirmLogo, _errorService.handleError)
                .finally(function () {
                    vm.dataLoading = false;
                });
        }
    }

    function updateFirmLogo() {
        uploadLogo(vm.firm.logo)
            .then(function () {
                if (_$scope.$root !== null) {
                    if (isVisibleInformationChanged()) {
                        _$scope.$root.$broadcast('firm-updated');
                    }
                    _firmEditService.backToPreviousPage();
                }
            }, _errorService.handleError);
    }

    function cancel() {
        _firmEditService.backToPreviousPage();
    }

    function isVisibleInformationChanged() {
        return _$scope.editFirmForm.isLogoVisible.$dirty ||
            _$scope.editFirmForm.isNameVisible.$dirty ||
            _$scope.editFirmForm.isWebsiteVisible.$dirty ||
            _$scope.editFirmForm.firmLogoUpload.$dirty ||
            _$scope.editFirmForm.firmName.$dirty ||
            _$scope.editFirmForm.webAddress.$dirty || 
            _$scope.editFirmForm.webDisplayText.$dirty;
    }

    module.exports = FirmEditController;
})();