﻿(function () {

    'use strict';

    ChangePasswordController.$inject = [
        '$location',
        '$routeParams',
        '$scope',
        'growl',
        'errorService',
        'userService'
    ];

    function ChangePasswordController($location, $routeParams, $scope, growl, errorService, userService) {

        let vm = this;

        vm.firmId = $routeParams.firmId;
        vm.dataLoading = false;
        vm.cancel = cancel;
        vm.changePassword = changePassword;

         function cancel() {
            $location.path('/' + vm.firmId + '/myProfile');
        }

        function changePassword(changePasswordForm, passwordData) {
            if (!changePasswordForm.$valid) {
                // TODO We should change how the errors are handled.  Inject an errorDisplayService.
                $scope.$broadcast('show-form-errors-check-validity');
                return;
            }

            vm.dataLoading = true;

            userService.changePassword(passwordData)
                .then(function() {
                    growl.success('Your password has been changed successfully.');
                    $location.path('/' + vm.firmId + '/myProfile');
                }, function (response) {
                    if (response === 'invalid-old-password') {
                        growl.error('The Old Password was not valid. Please enter a valid password.');
                    } else {
                        errorService.handleError(response);
                    }
                })
                .finally(function() {
                    vm.dataLoading = false;
                });
        }

    }

    module.exports = ChangePasswordController;
})();