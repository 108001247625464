﻿'use strict';

let angular = require('angular');

angular.module('app').directive('fsConfirmDialog', require('./dialog/fs-confirm-dialog.directive'));
angular.module('app').directive('fsDisableInput', require('./disable/fs-disable-input.directive'));
angular.module('app').directive('fsAutoFocus', require('./focus/fs-auto-focus.directive'));
angular.module('app').controller('FooterController', require('./footer/footer.controller'));
angular.module('app').directive('fsFooter', require('./footer/fs-footer.directive'));
angular.module('app').directive('fsHeader', require('./header/fs-header.directive'));
angular.module('app').controller('HeaderController', require('./header/header.controller'));
angular.module('app').directive('fsHelp', require('./help/fs-help.directive'));
angular.module('app').directive('fsHistoryBack', require('./history-back/fs-history-back.directive'));
angular.module('app').directive('fsLazyList', require('./lazy-list/fs-lazy-list.directive'));
angular.module('app').directive('fsNavigation', require('./navigation/fs-navigation.directive'));
angular.module('app').directive('fsKeyboardNavigation', require('./navigation/fs-keyboard-navigation.directive'));
angular.module('app').controller('NavigationController', require('./navigation/navigation.controller'));
angular.module('app').directive('fsPageTitle', require('./page-title/fs-page-title.directive'));
angular.module('app').directive('fsPagination', require('./pagination/fs-pagination.directive'));
angular.module('app').controller('PaginationController', require('./pagination/pagination.controller'));
angular.module('app').directive('fsPicklist', require('./picklist/fs-picklist.directive'));
angular.module('app').controller('PicklistController', require('./picklist/picklist.controller'));
angular.module('app').directive('fsPhoneNumber', require('./validation/fs-phone-number.directive'));
angular.module('app').directive('fsValidateEmail', require('./validation/fs-validate-email.directive'));
angular.module('app').directive('fsValidateFirmLogo', require('./validation/fs-validate-firm-logo.directive'));
angular.module('app').directive('fsValidatePassword', require('./validation/fs-validate-password.directive'));
