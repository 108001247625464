﻿(function () {

    'use strict';

    function NumbersOnly() {
        return {
            restrict: 'A',
            link: function (_scope, element) {
                element.on('keypress', function (event) {
                    let regex = new RegExp('\\d');
                    let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
                    if (!regex.test(key)) {
                        event.preventDefault();
                        return false;
                    }
                    return true;
                });
            }
        }
    }

    module.exports = NumbersOnly;

})();