﻿(function () {

    'use strict';

    function ShowFormErrorsDirective() {
        return {
            restrict: 'A',
            require: '^form',
            link: function (scope, element, _attr, formField) {
                let fieldElement = element[0].querySelector('[name]');
                let inputElement = angular.element(fieldElement);
                let inputName = inputElement.attr('name');
                let input = formField[inputName];

                inputElement.on('blur', function () {
                    element.toggleClass('wk-field-has-error', input.$dirty && input.$invalid);
                });

                inputElement.on('focus', function () {
                    element.toggleClass('wk-field-has-error', input.$dirty && input.$invalid);
                });

                scope.$on('show-form-errors-check-validity', function () {
                    element.toggleClass('wk-field-has-error', input.$invalid);
                });
            }
        }
    }

    module.exports = ShowFormErrorsDirective;
})();