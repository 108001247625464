﻿(function () {

    'use strict';

    fsFileDeleteConfirmDialog.$inject = [
        'errorService',
        'fileService',
        'minimumFileDisplayPercent',
        'growl'
    ];

    function fsFileDeleteConfirmDialog(errorService, fileService, minimumFileDisplayPercent, growl) {
          return {
            restrict: 'A',
            scope: {
                title: '@',
                okButtonText: '@',
                okCallback: '&'
            },
            template: require('./fs-file-delete-confirm-dialog.html'),
            link: function (scope) {
                scope.deleteFile = function () {
                    let vm = scope.$parent.vm;
                    vm.dataLoading = true;
                    fileService.deleteFile(scope.fileId)
                        .then(function () {
                            handleSuccessfulDeletion(vm);
                        }, function (response) {
                            if (response.status === 404) {
                                handleSuccessfulDeletion(vm);
                            } else {
                                errorService.handleError(response);
                            }
                        })
                        .finally(function () {
                            vm.dataLoading = false;
                        });
                }

                function updatePage(vm) {
                    let filesDisplayedPercent = Math.floor((vm.files.length / vm.filePageSize) * 100);
                    if (filesDisplayedPercent > minimumFileDisplayPercent) {
                        let files = vm.files.filter(function (file) {
                            return file.id !== scope.fileId;
                        });
                        vm.files = files;
                    } else {
                        if (vm.files.length === 1 && vm.selectedPage > 1) {
                            vm.selectedPage--;
                        }
                        vm.updatePage(vm.selectedPage);
                    }
                }

                function handleSuccessfulDeletion(vm) {
                    growl.success('Your file was successfully deleted.');
                    updatePage(vm);
                }

                scope.$on('file-delete-confirm-dialog', function (_event, args) {
                    let file = angular.fromJson(args.file);
                    scope.fileId = file.id;
                    scope.fileName = file.name;
                    scope.$broadcast('show-confirm-dialog');
                });
            }
        }
    }

    module.exports = fsFileDeleteConfirmDialog;

})();