﻿(function () {

    'use strict';

    function fsCopyright() {
        return {
            restrict: 'E',
            template: require('./fs-copyright.directive.html'),
            link:function(scope) {
                scope.currentYear = new Date().getFullYear();
            }
        }
    }

    module.exports = fsCopyright;

})();