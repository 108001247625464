﻿(function() {
    'use strict';

    ViewDisplaySettings.$inject = [
        '$q',
        'globalDisplaySettings'
    ];

    function ViewDisplaySettings($q, globalDisplaySettings) {
        let service = {
            setFilePageSize: setFilePageSize,
            getFilePageSize: getFilePageSize,

            setFileSort: setFileSort,
            getFileSort: getFileSort,

            setIsFileSortByDefaultOrder: setIsFileSortByDefaultOrder,
            getIsFileSortByDefaultOrder: getIsFileSortByDefaultOrder,

            setUserPageSize: setUserPageSize,
            getUserPageSize: getUserPageSize,

            setUserSort: setUserSort,
            getUserSort: getUserSort,

            setIsUserSortByDefaultOrder: setIsUserSortByDefaultOrder,
            getIsUserSortByDefaultOrder: getIsUserSortByDefaultOrder
        };

        return service;

        function setSlugInSessionStorage(slug, key, value) {
            sessionStorage.setItem([slug, key].join('.'), value);
        }

        // TODO This code was broken, need to write unit tests for this change.
        function getSlugFromSessionStorage(slug, key) {
            return sessionStorage.getItem([slug, key].join('.'));
        }

        function setFilePageSize(viewSlug, value) {
            setSlugInSessionStorage(viewSlug, 'page-size', value);
        }

        function getFilePageSize(viewSlug) {
            let value = getSlugFromSessionStorage(viewSlug, 'page-size');
            if (value !== null) {
                return $q.resolve(value);
            }
            return globalDisplaySettings.getFilePageSize();
        }

        function setFileSort(viewSlug, value) {
            setSlugInSessionStorage(viewSlug, 'sort-by', value);
        }

        function getFileSort(viewSlug) {
            let value = getSlugFromSessionStorage(viewSlug, 'sort-by');
            if (value !== null) {
                return $q.resolve(value);
            }
            return globalDisplaySettings.getFileSort();
        }

        function setIsFileSortByDefaultOrder(viewSlug, value) {
            setSlugInSessionStorage(viewSlug, 'is-sort-descending', value);
        }

        function getIsFileSortByDefaultOrder(viewSlug) {
            let value = getSlugFromSessionStorage(viewSlug, 'is-sort-descending');
            if (value === null) {
                value = true;
            }
            return JSON.parse(value);
        }

        function setUserPageSize(viewSlug, value) {
            setSlugInSessionStorage(viewSlug, 'page-size', value);
        }

        function getUserPageSize(viewSlug) {
            let value = getSlugFromSessionStorage(viewSlug, 'page-size');
            if (value !== null) {
                return $q.resolve(value);
            }
            return globalDisplaySettings.getUserPageSize();
        }

        function setUserSort(viewSlug, value) {
            setSlugInSessionStorage(viewSlug, 'sort-by', value);
        }

        function getUserSort(viewSlug) {
            let value = getSlugFromSessionStorage(viewSlug, 'sort-by');
            if (value !== null) {
                return $q.resolve(value);
            }
            return globalDisplaySettings.getUserSort();
        }

        function setIsUserSortByDefaultOrder(viewSlug, value) {
            setSlugInSessionStorage(viewSlug, 'is-sort-reverse', value);
        }

        function getIsUserSortByDefaultOrder(viewSlug) {
            let value = getSlugFromSessionStorage(viewSlug, 'is-sort-reverse');
            if (value === null) {
                value = true;
            }
            return JSON.parse(value);
        }
    }

    module.exports = ViewDisplaySettings;
})();