(function () {
    'use strict';

    let _fileUploadStorageService;
    let _firmService;
    let _userService;

    fileUploadPromiseHelperService.$inject = [
        'fileUploadStorageService',
        'firmService',
        'userService'
    ];

    function fileUploadPromiseHelperService(fileUploadStorageService, firmService, userService) {
        _fileUploadStorageService = fileUploadStorageService;
        _firmService = firmService;
        _userService = userService;

        return {
            getCanUploadFiles: getCanUploadFiles,
            getNumberOfActiveUsers: getNumberOfActiveUsers,
            getStorageAvailable: getStorageAvailable,
            getUserProfile: getUserProfile
        };
    }

    function getCanUploadFiles() {
        return _userService.getCanUploadFiles()
    }

    function getNumberOfActiveUsers() {
        return _firmService.getNumberOfActiveUsers();
    }

    function getStorageAvailable() {
        return _fileUploadStorageService.getStorageAvailable();
    }

    function getUserProfile() {
        return _userService.getUserProfile();
    }

    module.exports = fileUploadPromiseHelperService;

})();