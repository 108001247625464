(function () {

    'use strict';

    FileListBaseController.$inject = [
        '$filter',
        '$scope',
        'errorService',
        'fileService',
        'viewDisplaySettings',
        'filterDelayModelOptions',
        'timezoneService'
    ];

    let _$filter;
    let _$scope;
    let _errorService;
    let _fileService;
    let _viewDisplaySettings;
    let _filterDelayModelOptions;
    let _timezoneService;

    let vm;
    let viewSlug;

    const defaultSortType = 'Name';

    function FileListBaseController($filter,
        $scope,
        errorService,
        fileService,
        viewDisplaySettings,
        filterDelayModelOptions,
        timezoneService) {

        _$filter = $filter;
        _$scope = $scope;
        _errorService = errorService;
        _fileService = fileService;
        _viewDisplaySettings = viewDisplaySettings;
        _filterDelayModelOptions = filterDelayModelOptions;
        _timezoneService = timezoneService;
        
        viewSlug = _$scope.viewSlug;

        vm = this;

        vm.selectedPage = 1;

        vm.isAllSelected = false;
        vm.dataLoading = false;
        vm.files = [];
        vm.getAll = getAll;
        vm.getFiles = getFiles;
        vm.getSelected = getSelected;
        vm.handleSearchTerm = handleSearchTerm;
        vm.handleSuccessfulGetFiles = handleSuccessfulGetFiles;
        vm.isSortDescendingOrder = isSortDescendingOrder;
        vm.isSortDescending = false;
        vm.isValidSortType = isValidSortType
        vm.searchTerm = '';
        vm.selectAll = selectAll;
        vm.selectedFileIndex = -1;
        vm.sort = sort;
        vm.updatePage = updatePage;
        vm.select = select;

        vm.sortType = 'Name';
        vm.filePageSize = 25;
        vm.filterDelayModelOptions = _filterDelayModelOptions;

        initialize();

        return vm;
    }

    function getFilePageSize() {
        return _viewDisplaySettings.getFilePageSize(viewSlug)
            .then(function (filePageSize) {
                vm.filePageSize = filePageSize;
            });
    }

    function getFileSort() {
        return _viewDisplaySettings.getFileSort(viewSlug)
            .then(function (sortType) {
                if (vm.isValidSortType(sortType)) {
                    vm.sortType = sortType;
                } else {
                    vm.sortType = defaultSortType;
                }
                vm.isSortDescending = vm.isSortDescendingOrder();
            });
    }

    function setFileSort(sortType) {
        if (vm.sortType !== sortType) {
            setSortType(sortType);
            _viewDisplaySettings.setIsFileSortByDefaultOrder(viewSlug, true);
        } else {
            let isSortReverse = _viewDisplaySettings.getIsFileSortByDefaultOrder(viewSlug);
            _viewDisplaySettings.setIsFileSortByDefaultOrder(viewSlug, !isSortReverse);
        }
    }

    function getAll() {
        vm.dataLoading = true;

        getFilePageSize()
            .then(getFileSort())
            .then(vm.getFiles());
    }

    function getFiles() {
        return function () {
            _fileService.getAllFiles(vm.selectedPage, vm.filePageSize, vm.sortType, vm.isSortDescending, vm.searchTerm)
                .then(vm.handleSuccessfulGetFiles(), _errorService.handleError)
                .finally(function () { vm.dataLoading = false; });
        };
    }

    function handleSearchTerm() {
        return function () {
            vm.getAll();
        };
    }

    function handleSuccessfulGetFiles() {
        return function (response) {
            vm.files = response.files;
            let totalPages = Math.ceil(response.totalFiles / vm.filePageSize);
            if (totalPages < vm.selectedPage && totalPages > 0) {
                vm.selectedPage = 1;
            }
            updatePagination(vm.selectedPage, totalPages);
        };
    }

    function initialize() {
        let timezone = _timezoneService.getTimezone();
        vm.timezoneTooltip = _timezoneService.getTimezoneTooltip(timezone);
        vm.timezoneMomentName = _timezoneService.getMomentName(timezone);

        _$scope.$watch('vm.searchTerm', vm.handleSearchTerm());

        _$scope.isFileChosen = function () {
            return vm.files && _$filter('filter')(vm.files, { selected: true }).length > 0;
        }
    }

    // TODO: The isAllSelected is a bit of an anti-pattern. Needs refactored.
    function selectAll() {
        angular.forEach(vm.files, function(file) { file.selected = vm.isAllSelected });
    }

    function select() {
        vm.isAllSelected = vm.files.length > 0 &&
            _$filter('filter')(vm.files, { selected: true }).length === vm.files.length;
    }

    function getSelected() {
        let selectedFiles = [];
        angular.forEach(vm.files, function(file) {
            if (file.selected === true) {
                selectedFiles.push(file);
            }
        });
        return selectedFiles;
    }

    function updatePagination(currentPage, totalPages) {
        // TODO Need to convert this so we are not using eventing.
        vm.isAllSelected = false;
        _$scope.$broadcast('update-pagination', { currentPage: currentPage, totalPages: totalPages, delegateUpdate: vm.updatePage });
    }

    function updatePage(currentPage) {
        vm.selectedPage = currentPage;
        vm.isAllSelected = false;
        vm.getAll();
    }

    function sort(sortType) {
        vm.isAllSelected = false;

        setFileSort(sortType);

        vm.getAll();
    }

    function setSortType(sortType) {
        vm.sortType = sortType;
        _viewDisplaySettings.setFileSort(viewSlug, sortType);
    }

    function isValidSortType(_sortType) {
        return true;
    }

    function isSortDescendingOrder() {
        let isSortByDefaultOrder = _viewDisplaySettings.getIsFileSortByDefaultOrder(viewSlug);
        let isDefaultSortOderDescending = ['Size', 'UploadDateTime', 'DownloadCount'].indexOf(vm.sortType) > -1;

        return isSortByDefaultOrder ? isDefaultSortOderDescending : !isDefaultSortOderDescending;
    }

    module.exports = FileListBaseController;
})();