﻿(function () {

    'use strict';

    EmailTemplateController.$inject = [
        '$scope',
        '$window',
        '$filter',
        'growl',
        'errorService',
        'emailTemplateService',
        'authenticationService'
    ];

    let vm;

    let _$scope;
    let _$window;
    let _$filter;
    let _growl;
    let _errorService;
    let _emailTemplateService;
    let _authenticationService;

    let summernoteFontNames;
    let summernoteFontNamesIgnoreCheck;
    let summernoteToolbarOptions;
    let maximumEmailBodySize;

    function EmailTemplateController(
        $scope,
        $window,
        $filter,
        growl,
        errorService,
        emailTemplateService,
        authenticationService) {

        _$scope = $scope;
        _$window = $window;
        _$filter = $filter;
        _growl = growl;
        _errorService = errorService;
        _emailTemplateService = emailTemplateService;
        _authenticationService = authenticationService;

        summernoteFontNames = emailTemplateService.getSummernoteFontNames();
        summernoteFontNamesIgnoreCheck = emailTemplateService.getSummernoteFontNamesIgnoreCheck();
        summernoteToolbarOptions = emailTemplateService.getSummernoteToolbarOptions();
        maximumEmailBodySize = emailTemplateService.getMaximumEmailBodySize();

        vm = this;

        vm.keywords = [];
        vm.templates = [];
        vm.currentTemplate = {};
        vm.defaultTemplate = {};
        vm.canEditEmails = emailTemplateService.canEditEmailTemplates();

        vm.loadTemplate = loadTemplate;
        vm.saveTemplate = saveTemplate;
        vm.resetTemplate = resetTemplate;
        vm.cancel = cancel;

        vm.isDirty = false;
        vm.bodyError = null;
        vm.validateBody = validateBody;
        vm.subjectError = null;
        vm.validateSubject = validateSubject;
        vm.setCurrentTemplate = setCurrentTemplate;
        vm.updateTemplateList = updateTemplateList;

        vm.maximumEmailBodySize = maximumEmailBodySize;

        vm.summernoteOptions = {
            toolbar: summernoteToolbarOptions,
            buttons: {
                keywords: insertKeywordTags
            },
            fontNames: summernoteFontNames,
            fontNamesIgnoreCheck: summernoteFontNamesIgnoreCheck,
            disableDragAndDrop: true,
            followingToolbar: true,
            prettifyHtml: false,
            callbacks: {
                onKeyup: function () {
                    vm.isDirty = true;
                },
                onChange: function () {
                    vm.validateBody();
                }
            }
        };

        _$scope.$watch('vm.currentTemplate.subject', function () {
            vm.validateSubject();
        });

        _$scope.$on('$routeChangeStart', function (event, next) {
            if (_authenticationService.isAuthenticated() && vm.isDirty) {
                _$scope.$broadcast('email-template-confirm-dialog', { next: next });
                event.preventDefault();
            }
        });

        _emailTemplateService.getAllTemplates()
            .then(function (templates) {
                setTemplateList(templates);
                setCurrentTemplate(templates[0]);
            }, _errorService.handleError);
    }

    function insertKeywordTags(context) {
        let htmlTags = '';
        angular.forEach(vm.currentTemplate.tags, function (tag) {
            htmlTags += '<a class="note-dropdown-item" href="#" data-item="' + tag + '" data-value="{{' + tag + '}}" role="listitem">' + tag + '</a>';
        });

        let ui = $.summernote.ui;

        let keywordTags = ui.buttonGroup([
                ui.button({
                    className: 'dropdown-toggle',
                    contents: '<span>Keywords&nbsp;</span><span class="note-icon-caret"/>',
                    tooltip: 'Insert Keywords',
                    container: 'body',
                    click: function () {
                        context.invoke('editor.saveRange');
                    },
                    data: {
                        toggle: 'dropdown'
                    }
                }),
                ui.dropdown({
                    className: '',
                    contents: htmlTags,
                    callback: function ($dropdown) {
                        $dropdown.find('a')
                            .each(function () {
                                $(this)
                                    .on('click', function () {
                                        context.invoke('editor.restoreRange');
                                        context.invoke('editor.focus');
                                        context.invoke('editor.insertText', $(this).attr('data-value'));
                                        vm.isDirty = true;
                                    });
                            });
                    }
                })
            ])
            .render();

        return keywordTags;
    }

    function validateKeywordTags(text) {
        let keywordTags = [];
        text.replace(/\{\{(.*?)\}\}/g, function () {
            keywordTags.push(arguments[1]);
        });

        let invalidTag;
        let keywordTagsToCheck = new RegExp('^(' + vm.currentTemplate.tags.join('|') + ')$');
        keywordTags.forEach(function (tag) {
            if (!keywordTagsToCheck.test(tag)) {
                invalidTag = _$filter('truncate')(tag, 30);
            }
        });
        return invalidTag;
    }

    function stripLinebreaks(value) {
        if (value === null || value === undefined) {
            return value;
        }

        return value.replace(/(?:\r\n|\r|\n)/g, '');
    }

    function validateBody() {
        if (!_$scope.editEmailForm.body) return;

        vm.bodyError = null;
        let body = _$scope.editEmailForm.body;

        if (stripLinebreaks(vm.currentTemplate.body) !== stripLinebreaks(vm.defaultTemplate.body)) {
            vm.isDirty = true;
        }

        if (body.$error.required) {
            vm.bodyError = 'You must enter a valid Body.';
        }

        if (!vm.isDirty) return;

        if (body.$viewValue) {
            let invalidTag = validateKeywordTags(body.$viewValue);
            if (invalidTag) {
                body.$setValidity('keywords', false);
                vm.bodyError = 'The Keyword ' + invalidTag + ' is invalid.';
            } else {
                body.$setValidity('keywords', true);
            }

            if (body.$viewValue.length > vm.maximumEmailBodySize) {
                body.$setValidity('length', false);
                vm.bodyError = 'Email body must be between 1 and ' + vm.maximumEmailBodySize + ' characters.';
            } else {
                body.$setValidity('length', true);
            }
        }
    }

    function validateSubject() {
        if (!_$scope.editEmailForm.subject) return;

        vm.subjectError = null;
        let subject = _$scope.editEmailForm.subject;

        if (vm.currentTemplate.subject !== vm.defaultTemplate.subject) {
            vm.isDirty = true;
        }

        if (subject.$error.required) {
            vm.subjectError = 'You must enter a valid Subject.';
        }

        if (!vm.isDirty) return;

        if (vm.currentTemplate.subject) {
            if (vm.currentTemplate.subject.match(/\{\{(.*?)\}\}/g)) {
                subject.$setValidity('keywords', false);
                vm.subjectError = 'You cannot use Keywords in the Subject.';
            } else {
                subject.$setValidity('keywords', true);
            }
        }
    }

    function setCurrentTemplate(template) {
        if (vm.isDirty) {
            _$scope.$broadcast('email-template-confirm-dialog', { template: template });
            return false;
        }

        vm.currentTemplate = template;
        vm.defaultTemplate = angular.copy(template);

        _$window.scrollTo(0, 0);
        angular.element('#emailSubject').focus();

        vm.isDirty = false;

        return true;
    }

    function setTemplateList(templates) {
        vm.templates = templates;
    }

    function updateTemplateList(template) {
        angular.forEach(vm.templates, function (item, key) {
            if (item.slug === template.slug) {
                vm.templates[key] = template;
            }
        });
    }

    function loadTemplate(slug) {
        _emailTemplateService.getTemplate(slug)
            .then(function (template) {
                resetForm();
                setCurrentTemplate(template);
                updateTemplateList(template);
            });
    }

    function resetForm() {
        vm.isDirty = false;
        _$scope.editEmailForm.$setUntouched();
        _$scope.editEmailForm.subject.$setValidity('keywords', true);
        _$scope.editEmailForm.body.$setValidity('length', true);
    }

    function resetTemplate(slug) {
        _emailTemplateService.resetTemplate(slug)
            .then(function () {
                loadTemplate(slug);
                _growl.success('Email template has been reset.');
            });
    }

    function saveTemplate(template) {
        // For showFormErrors directive.
        _$scope.$broadcast('show-form-errors-check-validity');

        if (!vm.isDirty) {
            return;
        }

        validateSubject();
        validateBody();

        if (_$scope.editEmailForm.$valid) {
            vm.dataLoading = true;

            let bodyChunks = [];
            let chunkSize = 1024;

            for (let i = 0, length = template.body.length; i < length; i += chunkSize) {
                bodyChunks.push(template.body.slice(i, i + chunkSize));
            }

            let emailTemplate = {
                slug: template.slug,
                subject: template.subject,
                bodyChunks: bodyChunks,
                name: template.name,
                description: template.description
            }

            _emailTemplateService.updateTemplate(emailTemplate)
                .then(
                    function (response) {
                        vm.isDirty = false;
                        template.isOverride = true;
                        template.subject = response.data.subject;
                        template.body = response.data.body;
                        setCurrentTemplate(template);
                        _growl.success('Email template has been updated.');
                    }, 
                    function (response) {
                        if (response === 'malformed-template') {
                            _growl.error(
                                'Unable to save the email template.  Please check all the KEYWORDS to make sure they are correct and properly closed with }}.');
                        } else {
                            _growl.error(response);
                        }
                    })
                .finally(function () {
                    vm.dataLoading = false;
                });
        }
    }

    function cancel() {
        if (vm.isDirty) {
            _emailTemplateService.getTemplate(vm.currentTemplate.slug)
                .then(function (template) {
                    if (!angular.equals(vm.currentTemplate, template)) {
                        vm.isDirty = false;
                        updateTemplateList(template);
                        setCurrentTemplate(template);
                    }
                });
        }
    }

    module.exports = EmailTemplateController;
})();