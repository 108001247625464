﻿(function () {
    'use strict';

    AuthenticationService.$inject = [
        '$rootScope',
        '$q',
        'growl',
        'authenticationHelperService',
        'authService',
        'fileShareApi',
        'Idle'
    ];

    let _$rootScope;
    let _$q;
    let _growl;
    let _authenticationHelperService;
    let _authService;
    let _fileShareApi;
    // ReSharper disable InconsistentNaming
    let _Idle;
    // ReSharper restore InconsistentNaming

    function AuthenticationService(
        $rootScope,
        $q,
        growl,
        authenticationHelperService,
        authService,
        fileShareApi,
// ReSharper disable InconsistentNaming
        Idle) {
// ReSharper restore InconsistentNaming
        _$rootScope = $rootScope;
        _$q = $q;
        _growl = growl;
        _authenticationHelperService = authenticationHelperService;
        _authService = authService;
        _fileShareApi = fileShareApi;
        _Idle = Idle;

        let service = {
            login: login,
            tokenLogin: tokenLogin,
            logout: logout,
            invalidate: invalidate,
            isAuthenticated: isAuthenticated
        };

        _$rootScope.$on('event:auth-loginRequired', function () {
            let refreshTokenValue = _authenticationHelperService.getRefreshToken();
            if (refreshTokenValue === undefined) {
                _authService.loginCancelled('failed', function () {
                    // Do not retry
                    return false;
                });
                return;
            }
            refreshToken();
        });

        // TODO Need to rewrite the tests or the initialization code so this can be unit tested.
        // TODO As-is, it cannot be easily unit tested.
        if (isUserLoggedIn()) {
            _Idle.watch();
        }

        let idleMessage;

        _$rootScope.$on('IdleStart', function () {
            idleMessage = _growl.info(getIdleMessage(_Idle.getTimeout()), { ttl: -1 });
        });

        _$rootScope.$on('IdleTimeout', function () {
            idleMessage.setText('You have been logged out due to inactivity.');
            _$rootScope.$apply();
            logout();
            _$rootScope.$apply();
        });

        _$rootScope.$on('IdleWarn', function (_e, countdown) {
            idleMessage.setText(getIdleMessage(countdown));
            _$rootScope.$apply();
        });

        _$rootScope.$on('IdleEnd', function () {
            idleMessage.destroy();
            _$rootScope.$apply();
            idleMessage = undefined;
        });

        _$rootScope.$on('upload-start', function () {
            _Idle.unwatch();
        });

        _$rootScope.$on('upload-complete', function () {
            _Idle.watch();
        });

        return service;
    }

    function getIdleMessage(seconds) {
        let timePart = (seconds === 1) ? 'second' : 'seconds';
        return 'Your session has been idle.  You will be logged out in ' + seconds + ' ' + timePart + '.';
    }

    function login(firmId, username, password) {
        return _fileShareApi.login(firmId, username, password)
            .then(handleSuccessfulLogin(firmId), function (response) {
                if (response && response.reason === 'require-mfa') {
                    _authenticationHelperService.redirectUrl(response.uri);
                    return _$q.reject(response.reason);
                }
                return _$q.reject(response);
            });
    }

    function handleSuccessfulLogin(firmId) {
        return function (response) {
            if (response) {
                loginUser(response, firmId);
            }
        };
    }

    function tokenLogin(firmId, token, mfaCompletionId) {
        return _fileShareApi.tokenLogin(firmId, token, mfaCompletionId)
            .then(handleSuccessfulLogin(firmId), function (response) {
                return _$q.reject(response);
            });
    }

    function loginUser(response, firmId) {
        setUserInfo(response.userName, response.userId);
        setAccessToken(response.access_token);
        setRefreshToken(response.refresh_token);
        setFirmId(firmId);
        _authenticationHelperService.destroyAllMessages();
        _authenticationHelperService.restoreTitle();
        _$rootScope.$broadcast('login');
        _Idle.watch();
    }

    function logout() {
        _Idle.unwatch();
        _$rootScope.$broadcast('logout');
        let refreshTokenValue = _authenticationHelperService.getRefreshToken();
        return _fileShareApi.logout(refreshTokenValue)
            .then(null, _authenticationHelperService.handleError)
            .finally(function () {
                let firmId = _authenticationHelperService.getFirmId();
                invalidate();
                _authenticationHelperService.redirectToLogin(firmId);
            });
    }

    function invalidate() {
        removeToken();
        clearUserInfo();
        clearFirmInfo();
        clearRedirectPath();
    }

    // TODO This function is unclear and needs to be fixed (future fix).
    // TODO It should not return true for pages that don't require authentication.
    function isAuthenticated() {
        let isAuthenticationRequired = _authenticationHelperService.isAuthenticationRequired();
        return !isAuthenticationRequired || isUserLoggedIn();
    }

    function isUserLoggedIn() {
        return _authenticationHelperService.getAccessToken() !== null;
    }

    function refreshToken() {
        let token = _authenticationHelperService.getRefreshToken();
        let userId = getUserId();

        _fileShareApi.refreshToken(token, userId).then(function (response) {
            if (response.data) {
                setAccessToken(response.data.access_token);
                setRefreshToken(response.data.refresh_token);
                _authService.loginConfirmed('success', function (config) {
                    config.headers['Authorization'] = 'Bearer ' + response.data.access_token;
                    return config;
                });
            }
        }, function () {
            _authService.loginCancelled();
            logout();
            _growl.info(
                'For security purposes we require you to log in after long periods of activity.' +
                '  Please log in again.',
                {disableCloseButton: true });
        });
    }

    function setAccessToken(token) {
        _authenticationHelperService.setAccessToken(token);
    }

    function setRefreshToken(token) {
        _authenticationHelperService.setRefreshToken(token);
    }

    function setFirmId(firmId) {
        _authenticationHelperService.setFirmId(firmId);
    }

    function setUserInfo(username, userId) {
        _$rootScope.userProfile = {
            currentUser: {
                username: username,
                userId: userId
            }
        };

        _authenticationHelperService.setUserProfile(_$rootScope.userProfile);
    }

    function getUserId() {
        return _$rootScope.userProfile.currentUser.userId;
    }

    function removeToken() {
        _authenticationHelperService.removeTokens();
    }

    function clearUserInfo() {
        _$rootScope.userProfile = {};
        _authenticationHelperService.removeUserProfile();
    }

    function clearFirmInfo() {
        _authenticationHelperService.removeFirmId();
    }

    function clearRedirectPath() {
        _authenticationHelperService.removeRedirectPath();
    }

    module.exports = AuthenticationService;

})();