﻿/*globals angular*/
(function () {

    'use strict';

    FileShareApi.$inject = [
        '$q',
        '$http', 
        '$httpParamSerializer',
        'Upload',
        'apiUrlPrefix',
        'apiUrlPort'
    ];

    let _$q, _$http, _$httpParamSerializer, _Upload, _apiUrlPrefix, _apiUrlPort;

    const accountsServicePath = '/accounts';
    const emailTakenPromiseValue = 'email-taken';
    const emailTakenErrorCode = 1001;

    let apiServerUrl;
    let baseApiUrl;
    let tokenApiUrl;
    let userProfileApiUrl;

    // ReSharper disable once InconsistentNaming
    function FileShareApi($q, $http, $httpParamSerializer, Upload, apiUrlPrefix, apiUrlPort) {
        _$q = $q;
        _$http = $http;
        _$httpParamSerializer = $httpParamSerializer;
        _Upload = Upload;
        _apiUrlPrefix = apiUrlPrefix;
        _apiUrlPort = apiUrlPort;

        // TODO Write an interceptor to handle general errors (-1 = cannot connect etc.)

        let service = {
            addUser: addUser,
            bulkDeleteFiles: bulkDeleteFiles,
            changePassword: changePassword,
            editFirm: editFirm,
            editUser: editUser,
            editUserProfile: editUserProfile,
            deleteFile: deleteFile,
            deleteUser: deleteUser,
            getAllFiles: getAllFiles,
            getAllUsers: getAllUsers,
            getFirmAdministrators: getFirmAdministrators,
            getStaffAndAdministrators: getStaffAndAdministrators,
            getAssignableUsers: getAssignableUsers,
            getReassignableUsers: getReassignableUsers,
            getCanUploadFiles: getCanUploadFiles,
            getAllEmailTemplates: getAllEmailTemplates,
            getEmailTemplate: getEmailTemplate,
            getFile: getFile,
            getFirm: getFirm,
            getFirmBranding: getFirmBranding,
            getFirmStorageLimits: getFirmStorageLimits,
            getFirmVersion: getFirmVersion,
            getNumberOfActiveUsers: getNumberOfActiveUsers,
            getMyFiles: getMyFiles,
            getPublicFiles: getPublicFiles,
            getReceivedFiles: getReceivedFiles,
            getUserFiles: getUserFiles,
            getSharedFiles: getSharedFiles,
            getTimezone: getTimezone,
            getTimezones: getTimezones,
            getTotalFileSizeByUser: getTotalFileSizeByUser,
            getUser: getUser,
            getUserType: getUserType,
            getUserProfile: getUserProfile,
            isFirmActive: isFirmActive,
            isResetTokenValid: isResetTokenValid,
            isValidFirmId: isValidFirmId,
            login: login,
            tokenLogin: tokenLogin,
            logout: logout,
            reassignFiles: reassignFiles,
            refreshToken: refreshToken,
            registerUser: registerUser,
            requestPasswordReset: requestPasswordReset,
            resetPassword: resetPassword,
            resetEmailTemplate: resetEmailTemplate,
            setIsActive: setIsActive,
            setIsVisibleToClientForUpload: setIsVisibleToClientForUpload,
            setCanUploadFiles: setCanUploadFiles,
            setUserType: setUserType,
            updateEmailTemplate: updateEmailTemplate,
            uploadComplete: uploadComplete,
            uploadLogo: uploadLogo,
            uploadFileChunk: uploadFileChunk // TODO DEPRECATED
        };

        apiServerUrl = _apiUrlPrefix + (_apiUrlPort ? ':' + _apiUrlPort : '');
        baseApiUrl = apiServerUrl + '/api';
        tokenApiUrl = apiServerUrl + '/token';
        userProfileApiUrl = baseApiUrl + accountsServicePath + '/userprofile';

        return service;
    }

    function addUser(newUser) {
        return _$http.post(baseApiUrl + accountsServicePath, newUser)
            .then(null, handleUserProfileError());
    }

    // TODO: Move to 'bulk actions' service.
    function bulkDeleteFiles(fileIds) {
        return _$http.post(baseApiUrl + '/files/delete-files', fileIds)
            .then(function (response) {
                return response.data;
            }, function (response) {
                return _$q.reject(response.data.message);
            });
    }

    function changePassword(passwordData) {
        return _$http.put(baseApiUrl + '/accounts/changepassword', passwordData)
            .then(null,
                function (response) {
                    if (response.status === 400) {
                        if (response.data.message === 'invalid-old-password') {
                            return _$q.reject(response.data.message);
                        }
                    }
                    return _$q.reject('Unknown error: ' + response.data.message);
                });
    }

    function editFirm(firm) {
        return _$http.put(baseApiUrl + '/firms/current', firm);
    }

    function editUser(userObj) {
        return _$http.put(baseApiUrl + accountsServicePath, userObj)
            .then(null, handleUserProfileError());
    }

    function editUserProfile(userProfile) {
        return _$http.put(userProfileApiUrl, userProfile)
            .then(function (response) {
                    return response.data;
                },
                handleUserProfileError());
    }

    function deleteFile(fileId) {
        return _$http.delete(baseApiUrl + '/files/' + fileId);
    }

    function deleteUser(userId) {
        return _$http.delete(baseApiUrl + '/accounts/' + userId)
            .then(function () { return _$q.resolve(); }, function (response) {
                if (response.status === 400) {
                    if (response.data.message === 'unable-to-delete' ||
                        response.data.message === 'unable-to-delete-firm-administrator' ||
                        response.data.message === 'unable-to-delete-last-user-visible-for-upload') {
                        return _$q.reject(response.data.message);
                    }
                }
                return _$q.reject('Unknown error');
            });
    }

    function handleUserProfileError() {
        return function (response) {
            if (isEmailTakenResponse(response.data)) {
                return _$q.reject(emailTakenPromiseValue);
            }
            return _$q.reject(response);
        };
    }

    function isEmailTakenResponse(data) {
        return data.errorCode === emailTakenErrorCode;
    }

    function getAllFiles(pageNumber, pageSize, sortBy, isDescending, searchTerm, onlyPublicFiles) {
        let path = '/files';

        return getFiles(pageNumber, pageSize, searchTerm, sortBy, isDescending, onlyPublicFiles, path)
    }
    
    function getAllUsers(pageNumber, pageSize, filterBy, sortBy, isDescending) {
        let path = accountsServicePath;

        return getUsers(pageNumber, pageSize, filterBy, sortBy, isDescending, path);
    }

    function getFirmAdministrators() {
        return _$http.get(baseApiUrl + accountsServicePath + '/administrators')
            .then(function (response) {
                return response.data;
            });
    }

    function getStaffAndAdministrators(pageNumber, pageSize, filterBy, sortBy, isDescending) {
        let path = accountsServicePath + '/staff-and-administrators';

        return getUsers(pageNumber, pageSize, filterBy, sortBy, isDescending, path);

    }

    function getAssignableUsers() {
        return _$http.get(baseApiUrl + '/fileusers/assignable-users')
            .then(function (response) {
                return response.data;
            });
    }

    function getReassignableUsers() {
        return _$http.get(baseApiUrl + accountsServicePath + '/active-users')
            .then(function (response) {
                return response.data;
            });
    }

    function getCanUploadFiles() {
        return _$http.get(baseApiUrl + accountsServicePath + '/can-upload-files')
            .then(function(response) {
                return response.data;
            });
    }

    function getAllEmailTemplates() {
        return _$http.get(baseApiUrl + '/email-templates/')
            .then(function (response) {
                return response.data;
            });
    }

    function getEmailTemplate(slug) {
        return _$http.get(baseApiUrl + '/email-templates/' + slug)
            .then(function(response) {
                return response.data;
            });
    }

    function getFile(fileId) {
        return _$http.get(baseApiUrl + '/files/' + fileId)
            .then(function(response) {
                return response.data;
            });
    }

    function getFirm() {
        return _$http.get(baseApiUrl + '/firms/current')
            .then(function (response) {
                return response.data;
            });
    }

    function getFirmBranding(firmId) {
        return _$http.get(baseApiUrl + '/firms/' + firmId + '/branding')
            .then(function (response) {
                return response.data;
            });
    }

    function getFirmStorageLimits() {
        return _$http.get(baseApiUrl + '/firms/current/storage-limits')
            .then(function (response) {
                return response.data;
            });
    }

    /**
     * Returns the firm version.
     * @return {number} The firm version
     */
        function getFirmVersion() {
        return _$http.get(baseApiUrl + '/firms/current/version')
            .then(function (response) {
                return response.data.version;
            });
    }

    function getNumberOfActiveUsers() {
        return _$http.get(baseApiUrl + accountsServicePath + '?isActiveOnly=true&metaDataOnly=true')
            .then(function (response) {
                return parseInt(response.headers('X-total-records'));
            });
    }

    function getMyFiles(pageNumber, pageSize, sortBy, isDescending, searchTerm) {
        let path = '/files/my-files';

        return getFiles(pageNumber, pageSize, searchTerm, sortBy, isDescending, null, path);
    }

    function getPublicFiles(pageNumber, pageSize, sortBy, isDescending, searchTerm) {
        return getAllFiles(pageNumber, pageSize, sortBy, isDescending, searchTerm, true);
    }

    function getReceivedFiles(pageNumber, pageSize, sortBy, isDescending, searchTerm) {
        let path = '/files/received-files';

        return getFiles(pageNumber, pageSize, searchTerm, sortBy, isDescending, null, path);
    }

    function getUserFiles(userId, pageNumber, pageSize, sortBy, isDescending, searchTerm) {
        let path = '/files/user-files/' + userId;

        return getFiles(pageNumber, pageSize, searchTerm, sortBy, isDescending, null, path);
    }

    function getSharedFiles(pageNumber, pageSize, sortBy, isDescending, searchTerm) {
        let path = '/files/shared-files';
        
        return getFiles(pageNumber, pageSize, searchTerm, sortBy, isDescending, null, path);
    }

    function getUsers(pageNumber, pageSize, filterBy, sortBy, isDescending, path) {
        path += getUserParameters(pageNumber, pageSize, filterBy, sortBy, isDescending);
        
        return _$http.get(baseApiUrl + path)
            .then(function (response) {
                return {
                    users: response.data,
                    totalUsers: parseInt(response.headers('X-total-records'))
                };
            });
    }

    function getFiles(pageNumber, pageSize, searchTerm, sortBy, isDescending, onlyPublicFiles, path) {
        path += getFileParameters(pageNumber, pageSize, onlyPublicFiles, searchTerm, sortBy, isDescending);

        return _$http.get(baseApiUrl + path)
            .then(function (response) {
                return {
                    files: response.data,
                    totalFiles: parseInt(response.headers('X-total-records'))
                };
            });
    }

    function getFileParameters(pageNumber, pageSize, onlyPublicFiles, searchTerm, sortBy, isDescending) {
        let parameters = {};

        if (pageNumber != null) {
            parameters['pageNumber'] = pageNumber;
        }

        if (pageSize != null) {
            parameters['pageSize'] = pageSize;
        }

        if (onlyPublicFiles != null) {
            parameters['onlyPublicFiles'] = onlyPublicFiles;
        }

        if (searchTerm != null) {
            parameters['searchTerm'] = searchTerm;
        }

        if (sortBy != null) {
            parameters['sortBy'] = sortBy;
        }

        if (isDescending != null) {
            parameters['isDescending'] = isDescending;
        }

        return (Object.keys(parameters).length > 0) ? '?' + _$httpParamSerializer(parameters) : '';
    }

    function getUserParameters(pageNumber, pageSize, filterBy, sortBy, isDescending) {
        let parameters = {};

        if (pageNumber) {
            parameters['pageNumber'] = pageNumber;
        }

        if (pageSize) {
            parameters['pageSize'] = pageSize;
        }

        if (filterBy) {
            parameters['filterBy'] = filterBy;
        }

        if (sortBy) {
            parameters['sortBy'] = sortBy;
        }

        if (isDescending) {
            parameters['isDescending'] = isDescending;
        }

        return (Object.keys(parameters).length > 0) ? '?' + _$httpParamSerializer(parameters) : '';
    }    

    // Get the current user's timezone.
    function getTimezone() {
        return _$http.get(baseApiUrl + '/accounts/timezones/timezone')
            .then(function (response) {
                return response.data;
            });
    }

    function getTimezones() {
        return _$http.get(baseApiUrl + '/timezones')
            .then(function (response) {
                return response.data;
            });
    }

    function getTotalFileSizeByUser() {
        return _$http.get(baseApiUrl + '/files/storage-used-by-user')
            .then(function (response) {
                return response.data;
            });
    }

    function getUser(userId) {
        return _$http.get(baseApiUrl + accountsServicePath + '/' + userId)
            .then(function(response) {
                return response.data;
            });
    }

    function getUserType() {
        return _$http.get(baseApiUrl + accountsServicePath + '/user-type')
            .then(function(response) {
                return response.data;
            });
    }

    function getUserProfile() {
        return _$http.get(baseApiUrl + accountsServicePath + '/userprofile')
            .then(function (response) {
                return response.data;
            });
    }

    function isFirmActive(firmId) {
        return _$http.get(baseApiUrl + '/firms/' + firmId + '/is-active')
            .then(function (response) {
                return response.data;
            });
    }

    function isResetTokenValid(firmId, userId, token) {
        let parameters = {};
        parameters['firmId'] = firmId;
        parameters['token'] = token;

        let url = baseApiUrl + '/accounts/' + userId + '/is-reset-token-valid?' + _$httpParamSerializer(parameters);
        return _$http.get(url)
            .then(function (response) {
                return response.data.isValid;
            });
    }

    function isValidFirmId(firmId) {
        return _$http.get(baseApiUrl + '/firms/' + firmId + '/exists')
            .then(function () {
                return true;
            }, function (reason) {
                if (reason.status === 404) {
                    return false;
                } else {
                    return _$q.reject(reason);
                }
            });
    }

    function login(firmId, username, password) {
        let data = 'grant_type=password&username=' +
            encodeURIComponent(username) +
            '&password=' +
            encodeURIComponent(password) + 
            '&firmId=' + encodeURIComponent(firmId);
        return _$http.post(tokenApiUrl, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .then(function (response) {
                return response.data;
            }, function (response) {
                if (response.status === 400) {
                    if (response.data.error === 'invalid_grant') return _$q.reject('invalid-credentials');
                    if (response.data.error === 'inactive_user') return _$q.reject('inactive-user');
                    if (response.data.error === 'require_mfa') {
                        return _$q.reject({ reason: 'require-mfa', uri: response.data.error_description });
                    }
                }
                return _$q.reject(response);
            });
    }

    function tokenLogin(firmId, payload, mfaCompletionId) {
        let data = 'grant_type=token&payload=' +
            encodeURIComponent(payload) +
            '&mfaCompletionId=' +
            encodeURIComponent(mfaCompletionId) +
            '&firmId=' + encodeURIComponent(firmId);
        return _$http.post(tokenApiUrl, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .then(function (response) {
                return response.data;
            }, function (response) {
                if (response.status === 400) {
                    if (response.data.error === 'invalid_mfa_completion_id') return _$q.reject('invalid-mfa-completion-id');
                }
                return _$q.reject(response);
            });
    }

    function logout(refreshTokenValue) {
        return _$http.post(baseApiUrl + '/accounts/logout', '"' + refreshTokenValue + '"');
    }

    function reassignFiles(fileIds, authorId, assigneeId) {
        return _$http.post(baseApiUrl + '/files/reassign', { 'ids': fileIds, 'authorId': authorId, 'assigneeId': assigneeId })
            .then(function(response) {
                return response.data;
            });
    }

    function refreshToken(token, userid) {
        let parameters = {
            grant_type: 'refresh_token',
            refresh_token: token,
            client_id: userid
        };
        let data = _$httpParamSerializer(parameters);
        return _$http.post(tokenApiUrl, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
    }

    function registerUser(user) {
        return _$http.post(baseApiUrl + '/accounts/registration/register', user)
            .then(null, function (response) {
                if (response.status === 400 && response.data.errors !== undefined) {
                    return _$q.reject(response.data.errors[0].modelStateErrors[0].messages[0]);
                } else {
                    return _$q.reject(response);
                }
            });
    }

    function requestPasswordReset(user) {
        return _$http.post(baseApiUrl + '/accounts/send-password-reset-email', user)
            .then(function (response) {
                return response.data;
            });
    }

    function resetPassword(data) {
        return _$http.post(baseApiUrl + '/accounts/reset-password', data);
    }

    function resetEmailTemplate(slug) {
        return _$http.delete(baseApiUrl + '/email-templates/' + slug, slug);
    }

    function setIsActive(userId, isActive) {
        return _$http.put(baseApiUrl + '/accounts/' + userId + '/is-active/' + isActive);
    }

    function setIsVisibleToClientForUpload(userId, isVisibleToClientForUpload) {
        return _$http.put(baseApiUrl + '/accounts/' + userId + '/is-visible-to-client-for-upload/' + isVisibleToClientForUpload);
    }

    function setCanUploadFiles(userId, canUploadFiles) {
        return _$http.put(baseApiUrl + '/accounts/' + userId + '/can-upload-files/' + canUploadFiles);
    }

    function setUserType(userId, userType) {
        // TODO Should we check (userType) and throw exception if out of range?
        return _$http.put(baseApiUrl + '/accounts/' + userId + '/user-type/' + userType);
    }

    function updateEmailTemplate(template) {
        return _$http.put(baseApiUrl + '/email-templates', template)
            .then(null,
                function (response) {
                    if (response.status === 400) {
                        if (response.data.message === 'malformed-template') {
                            return _$q.reject('malformed-template');
                        }
                    } else {
                        return response.data;
                    }
                    return _$q.reject('Unknown error: ' + response.data.message);
                });
    }

    function uploadComplete(formData) {
        return _$http.post(baseApiUrl + '/files/upload-complete', formData);
    }

    function uploadLogo(logo) {
        return _Upload.upload({
            url: baseApiUrl + '/firms/current/upload-logo',
            data: { file: logo }
        });
    }

    // TODO DEPRECATED
    function uploadFileChunk(formData) {
        return _$http.post(baseApiUrl + '/files', formData,
            {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            })
            .then(
                null,
                function (response) {
                    if (response.status === 400) {
                        return _$q.reject(response.data.message);
                    }
                    return _$q.reject('Unknown error');
                });
    }

    module.exports = FileShareApi;
})();