(function () {
    'use strict';

    let _$location;
    let _$window;
    let _errorService;
    let _growlMessages;
    let _routeCheckerService;
    let _sessionStorageService;
    // ReSharper disable InconsistentNaming
    let _Title;
    // ReSharper restore InconsistentNaming

    const firmIdKey = 'firmId';
    const accessTokenKey = 'access_token';
    const refreshTokenKey = 'refresh_token';

    authenticationHelperService.$inject = [
        '$location',
        '$window',
        'errorService',
        'growlMessages',
        'routeCheckerService',
        'sessionStorageService',
        'Title'
    ];

    function authenticationHelperService($location,
        $window,
        errorService,
        growlMessages,
        routeCheckerService,
        sessionStorageService,
// ReSharper disable InconsistentNaming
        Title) {
// ReSharper restore InconsistentNaming

        _$location = $location;
        _$window = $window;
        _errorService = errorService;
        _growlMessages = growlMessages;
        _routeCheckerService = routeCheckerService;
        _sessionStorageService = sessionStorageService;
        _Title = Title;

        return {
            destroyAllMessages: destroyAllMessages,
            getAccessToken: getAccessToken,
            getFirmId: getFirmId,
            getRefreshToken: getRefreshToken,
            handleError: handleError,
            isAuthenticationRequired: isAuthenticationRequired,
            redirectToLogin: redirectToLogin,
            redirectUrl: redirectUrl,
            removeFirmId: removeFirmId,
            removeTokens: removeTokens,
            removeRedirectPath: removeRedirectPath,
            removeUserProfile: removeUserProfile,
            restoreTitle: restoreTitle,
            setAccessToken: setAccessToken,
            setFirmId: setFirmId,
            setRefreshToken: setRefreshToken,
            setUserProfile: setUserProfile
        };
    }

    function destroyAllMessages() {
        _growlMessages.destroyAllMessages();
    }

    function getAccessToken() {
        return _sessionStorageService.getItem(accessTokenKey, null);
    }

    function getFirmId() {
        return _sessionStorageService.getItem(firmIdKey);
    }

    function getRefreshToken() {
        return _sessionStorageService.getItem(refreshTokenKey);  
    }

    function handleError(error, message) {
        _errorService.handleError(error, message);
    }

    function isAuthenticationRequired() {
        return _routeCheckerService.isAuthenticationRequired(_$location.path());
    }

    function redirectToLogin(firmId) {
        _$location.path('/' + firmId + '/login');
    }

    function redirectUrl(url) {
        _$window.location.href = url;
    }

    function removeFirmId() {
        _sessionStorageService.removeItem(firmIdKey);
    }

    function removeTokens() {
        _sessionStorageService.removeItem(accessTokenKey);
        _sessionStorageService.removeItem(refreshTokenKey);
    }

    function removeRedirectPath() {
        _sessionStorageService.removeItem('redirectPath');
    }

    function restoreTitle() {
        _Title.restore();
    }

    function removeUserProfile() {
        _sessionStorageService.removeItem('userProfile');
    }

    function setAccessToken(token) {
        _sessionStorageService.setItem(accessTokenKey, token);
    }

    function setFirmId(firmId) {
        _sessionStorageService.setItem(firmIdKey, firmId);
    }

    function setRefreshToken(token) {
        _sessionStorageService.setItem(refreshTokenKey, token);
    }

    function setUserProfile(userProfile) {
        _sessionStorageService.setItem('userProfile', userProfile);
    }

    module.exports = authenticationHelperService;

})();
