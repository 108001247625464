﻿(function () {

    'use strict';

    Configuration.$inject = [
        '$httpProvider',
        '$logProvider',
        'corsWithCredential',
        'growlProvider',
        'growlPosition',
        'growlTimeToLive',
        'logDebugMessagesToConsole'
    ];

    function Configuration($httpProvider,
        $logProvider,
        corsWithCredential,
        growlProvider,
        growlPosition,
        growlTimeToLive,
        logDebugMessagesToConsole) {
        $httpProvider.defaults.useXDomain = true;
        $httpProvider.defaults.withCredentials = corsWithCredential;
        delete $httpProvider.defaults.headers.common['X-Requested-With'];
        $httpProvider.interceptors.push('authInterceptor');

        $logProvider.debugEnabled(logDebugMessagesToConsole);

        growlProvider.globalPosition(growlPosition);
        growlProvider.globalTimeToLive(growlTimeToLive);
        growlProvider.globalDisableCountDown(true);
    }

    module.exports = Configuration;

})();