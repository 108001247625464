﻿(function () {
    'use strict';

    LoginController.$inject = [
        '$q',
        '$location',
        '$rootScope',
        'authenticationService',
        'growl',
        'loginService',
        'userService'
    ];

    function LoginController(
        $q,
        $location,
        $rootScope,
        authenticationService,
        growl,
        loginService,
        userService) {

        let vm = this;

        vm.isFirmActive = true;
        vm.isValidFirmId = true;
        vm.isInMaintenance = false;
        vm.isLoginAllowed = isLoginAllowed;
        vm.login = login;
        vm.register = register;
        vm.resetPassword = resetPassword;

        vm.firmId = loginService.getFirmId();

        let email = loginService.getEmail();
        if (email) {
            vm.email = decodeURIComponent(email);
        } else {
            vm.email = '';
        }

        $rootScope.$on('inactive-firm', function () {
            vm.isFirmActive = false;
            growl.error('This File Share account has been deactivated.');
        });

        $rootScope.$on('invalid-firm', function () {
            vm.isValidFirmId = false;
            growl.error(
                'You have used a File Share address that does not link to a ' +
                'valid File Share firm. Please return to the firm\'s website to get an updated link.',
                { title: 'Invalid File Share Address', disableCloseButton: true }
            );
        });

        $rootScope.$on('in-maintenance', function () {
            vm.isInMaintenance = true;
            growl.error(
                'Maintenance is in progress. Please try to log in later.',
                { title: 'Maintenance In Progress', disableCloseButton: true }
            );
        });

        function isLoginAllowed() {
            return vm.isFirmActive && vm.isValidFirmId && !vm.isInMaintenance;
        }

        function login(form) {
            // For showFormErrors directive.
            $rootScope.$broadcast('show-form-errors-check-validity');

            if (!form.$valid) return;

            vm.dataLoading = true;

            authenticationService.login(vm.firmId, vm.email, vm.password)
                .then(loadUserInfoAndRedirect, handleLoginError)
                .finally(function() {
                    vm.dataLoading = false;
                });
        }

        function handleLoginError(response) {
            switch (response) {
                case 'inactive-user':
                    growl.error('Your account is inactive. Please contact us to activate your account.');
                    break;
                case 'invalid-credentials':
                    growl.error('Your email address or password were incorrect.');
                    break;
                case 'require-mfa':
                    break;
                default:
                    loginService.handleError(response);
                    break;
            }
        }

        function loadUserInfoAndRedirect() {
            $q.all([
                    userService.getUserType().then(setUserType),
                    userService.getCanUploadFiles().then(setFileUploadFlag),
                    userService.getTimezone().then(setTimezone)
                ])
                .then(function () {
                    $location.path('/' + vm.firmId).search({});
                })
                .finally(function () {
                    vm.dataLoading = false;
                });
        }

        function setTimezone(timezone) {
            loginService.setTimezone(timezone);
        }

        function setUserType(userType) {
            loginService.setUserType(userType);
        }

        function setFileUploadFlag(canUploadFiles) {
            loginService.setCanUploadFiles(canUploadFiles);
        }

        function register() {
            $location.path('/' + vm.firmId + '/register');
        }

        function resetPassword() {
            if (vm.email !== undefined) {
                loginService.setEmailToDataShare(vm.email);
            }
            $location.path('/' + vm.firmId + '/requestPasswordReset');
        }
    }

    module.exports = LoginController;
})();