﻿(function () {
    'use strict';

    HeaderController.$inject = [
        'authenticationService',
        'sessionStorageService',
        '$rootScope',
        '$scope',
        '$filter',
        'growl'
    ];

    function HeaderController(
        authenticationService,
        sessionStorageService,
        $rootScope,
        $scope,
        $filter,
        growl) {
        let vm = this;

        vm.logout = logout;
        vm.isLogoutDisplayed = false;
        vm.getUserName = getUserName();

        function updateHeaderStatus(isPublicAccess) {
            vm.isLogoutDisplayed = authenticationService.isAuthenticated() && isPublicAccess !== true;
            vm.firmId = sessionStorageService.getItem('firmId');
        }

        function logout() {
            authenticationService.logout();
        }

        function getUserName() {
            if ($rootScope.userProfile.currentUser !== undefined) {
                return $rootScope.userProfile.currentUser.username;
            }

            return '';
        }

        $scope.$on('$routeChangeStart', function (_event, next) {
            updateHeaderStatus(next.isPublicAccess);
        });

        $scope.$on('firm-initialized', function (_event, firm) {
            $rootScope.firm = firm;

            $scope.isFirmBrandingEnabled = firm.isLogoVisible || firm.isNameVisible;

            if (firm.isLogoVisible) {
                firm.logo = $filter('dataUri')(firm.logo);
            }
        });

        $scope.$on('firm-updated', function () {
            delete $rootScope.firm;
            growl.success('The changes you made will be visible to other users after they refresh.');
        });
    }

    module.exports = HeaderController;

})();