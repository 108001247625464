﻿(function () {

    'use strict';

    BrowserService.$inject = ['$window', '$document', 'ieMinimumVersion'];

    function BrowserService($window, $document, ieMinimumVersion) {
        let service = {
            isOnline: isOnline,
            isSupported: isSupported
        };

        return service;

        function isOnline() {
            return $window.navigator.onLine;
        }

        function isSupported() {
            let isValid = true;
            let ie = $document[0].documentMode;

            if (ie) {
                isValid = ie >= ieMinimumVersion;
            }

            return isValid;
        }
    }

    module.exports = BrowserService;
})();