﻿/*global angular */
(function () {
    'use strict';

    fsPicklist.$inject = [
        '$timeout',
        '$filter'
    ];

    let _$timeout;
    let _$filter;

    const directionUp = 'up';
    const directionDown = 'down';

    function fsPicklist($timeout, $filter) {
        return {
            restrict: 'A',
            scope: {
                focus: '@',
                picklistId: '@',
                placeholder: '@',
                displayLength: '@',
                selectedValue: '=selectedValue',
                listItems: '='
            },
            replace: true,
            template: require('./fs-picklist.html'),
            controller: 'PicklistController',
            controllerAs: 'vm',
            link: {
                    post: function (scope, element, _attrs, vm) {
                        _$timeout = $timeout;
                        _$filter = $filter;

                        let htmlList = element.find('ul');

                        scope.filterValue = null;

                        scope.selectedIndex = -1;
                        scope.picklistId = scope.picklistId || 'selectedValue';

                        scope.html = '';

                        scope.maximumIndex = -1;

                        scope.listNeedsUpdate = 0;

                        scope.$watch('listNeedsUpdate', function (newValue, oldValue) {
                            updateList(scope, newValue, oldValue);
                        });

                        scope.$watch('filterValue', function (newValue, oldValue) {
                            if (newValue === oldValue) return;

                            if (newValue === '') {
                                scope.selectedIndex = -1;
                            }
                            scope.listNeedsUpdate++;
                        });

                        scope.keydown = function ($event) {
                            handleKeyDown(scope, element, vm, $event);
                        };

                        scope.$watch('listItems', function (_newValue, _oldValue) {
                            scope.listNeedsUpdate++;
                        }, true);

                        scope.$watch('selectedValue', function (newValue, oldValue) {
                            handleSelectedValue(scope, newValue, oldValue)
                        });

                        vm.listItems = scope.listItems;

                        let input = element.find('input[type="text"]');

                        if (scope.focus === 'true') {
                            _$timeout(function () {
                                input.focus();
                            });
                        }

                        scope.$watch('selectedIndex', function (newIndex, oldIndex) {
                            if (newIndex === oldIndex) return;

                            unselectSelectedItem(element, oldIndex);
                            selectItem(element, newIndex);
                        });

                        htmlList.on('click', function (e) {
                            vm.select(e.target.getAttribute('value'));
                        });
                    }
            }
        };
    }

    function ensureCurrentItemInScrolledVisibleArea(scope, element) {
        if (!scope.isOpen) return;

        let list = element.find('ul');
        const listItem = element.find('li');
        const listTop = list[0].scrollTop;
        const listHeight = list.height();
        const itemHeight = listItem.height();
        const itemTop = itemHeight * scope.selectedIndex;

        const isItemBelowVisibleArea = (itemTop + itemHeight) > (listTop + listHeight);
        const isItemAboveVisibleArea = itemTop < listTop;

        if (isItemBelowVisibleArea) {
            const newTop = (itemTop + itemHeight) - listHeight;
            list[0].scrollTop = newTop;
        } else {
            if (isItemAboveVisibleArea) {
                list[0].scrollTop = itemTop;
            }
        }
    }

    function getList(scope) {
        return scope.filterValue ? _$filter('filter')(scope.listItems, { value: scope.filterValue }) : scope.listItems;
    }

    function getListAsHtml(scope, list) {
        let html = '';

        list.forEach(function (item) {
            let title = item.value.length > scope.displayLength ? item.value : '';
            title = encode(title);

            let truncatedItem = _$filter('truncate')(item.value, scope.displayLength);
            truncatedItem = encode(truncatedItem);

            let value = item.id;
            value = encode(value);

            html += `<li role="option" title="${title}" value="${value}">${truncatedItem}</li>`;
        });

        return html;
    }

    function changeSelectedItem(scope, direction) {
        if (direction === directionUp && scope.selectedIndex <= 0) return;

        if (direction === directionDown && scope.selectedIndex === scope.maximumIndex) return;

        if (direction === directionDown) {
            scope.selectedIndex++;
        } else {
            scope.selectedIndex--;
        }
    }

    function setSelectedItem(scope, vm) {
        let list = getList(scope);
        let item = list[scope.selectedIndex];
        if (item) {
            vm.select(item.id);
        }
        scope.selectedIndex = -1;
        scope.filterValue = null;
    }

    function openDropdown(scope) {
        scope.isOpen = true;
    }

    function closeDropdown(scope) {
        scope.isOpen = false;
        scope.selectedIndex = -1;
    }

    function selectItem(element, index) {
        let currentListItem = element.find('li')[index];
        angular.element(currentListItem).addClass('active');
    }

    function unselectSelectedItem(element, index) {
        if (index < 0) return;
        let previousListItem = element.find('li')[index];
        angular.element(previousListItem).removeClass('active');
    }

    function handleKeyDown(scope, element, vm, $event) {
        switch ($event.keyCode) {
            case 38: // up arrow
                $event.preventDefault();
                changeSelectedItem(scope, directionUp);
                openDropdown(scope);
                ensureCurrentItemInScrolledVisibleArea(scope, element);
                break;
            case 40: // down arrow
                $event.preventDefault();
                changeSelectedItem(scope, directionDown);
                openDropdown(scope);
                ensureCurrentItemInScrolledVisibleArea(scope, element);
                break;
            case 13: // enter key
                if (scope.isOpen) {
                    $event.preventDefault();
                    setSelectedItem(scope, vm);
                    closeDropdown(scope);
                }
                break;
            case 27: // escape key
                closeDropdown(scope);
                break;
            case 37: // left key
            case 39: // right key
                break;
            default:
                scope.selectedIndex = -1;
                break;
        }
    }

    function updateList(scope, newValue, oldValue) {
        if (newValue === oldValue) return;

        const list = getList(scope);
        if (list.length === 0) {
            scope.maximumIndex = -1;
            scope.html = '';
            closeDropdown(scope);
        } else {
            scope.maximumIndex = list.length - 1;
            scope.html = getListAsHtml(scope, list);
        }

    }

    function handleSelectedValue(scope, newValue, oldValue) {
        if (newValue === oldValue) return;

        if (!scope.hasFocus) return;

        scope.filterValue = newValue;

        let list = getList(scope);

        if (list.length === 0) {
            closeDropdown(scope);
        } else {
            if (list[scope.selectedIndex] !== undefined) {
                scope.selectedIndex = -1;
            }

            openDropdown(scope);
        }
    }
    
    function encode(value) {
        let newValue = value.replace(/['"<>]/g, function (s) {
            return '&#' + s.charCodeAt(0) + ';';
        });

        return newValue;
    }

    module.exports = fsPicklist;

})();
