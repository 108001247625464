﻿(function () {
    'use strict';

    fsHistoryBack.$inject = [
        '$window'
    ];

    function fsHistoryBack($window) {
        return {
            restrict: 'A',
            link: function (_scope, element) {
                element.bind('click', function () {
                    $window.history.back();
                });
            }
        }
    }

    module.exports = fsHistoryBack;
})();