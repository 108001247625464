﻿(function () {
    'use strict';

    ExceptionHandler.$inject = [
        '$injector',
        '$log'
    ];

    // TODO This may need to be converted to a decorator so that Application Insights can also process exceptions.
    function ExceptionHandler($injector, $log) {
        return function (exception, cause) {
            let growl = $injector.get('growl');

            growl.error('An unexpected error has occurred.  ' +
                'The data on the page may be out of date.  Please refresh the page and try again.');

            $log.error(exception, cause);
        };
    }

    module.exports = ExceptionHandler;
})();