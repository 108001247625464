﻿(function () {
    'use strict';

    PicklistController.$inject = [
        '$scope'
    ];

    function PicklistController ($scope) {
        let vm = this;

        vm.listItems = null;
        vm.select = select;

        function select(id) {
            let selectedItem = vm.listItems.filter(function (item) { return item.id === id; })[0];
            $scope.selectedValue = selectedItem.value;
        }
    }

    module.exports = PicklistController;

})();