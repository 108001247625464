(function () {
    'use strict';

    BulkFileActionsService.$inject = [
        '$location',
        'fileService',
        'growl',
        'maximumNumberOfFilesPerAction',
        'sessionStorageService'
    ];

    let _$location;
    let _fileService;
    let _growl;
    let _maximumNumberOfFilesPerAction;
    let _sessionStorageService;

    function BulkFileActionsService($location, fileService, growl, maximumNumberOfFilesPerAction, sessionStorageService) {
        _$location = $location;
        _fileService = fileService;
        _growl = growl;
        _maximumNumberOfFilesPerAction = maximumNumberOfFilesPerAction;
        _sessionStorageService = sessionStorageService;

        return {
            bulkReassignFiles: bulkReassignFiles,
            getBulkActionDisabledMessage: getBulkActionDisabledMessage,
            handleSelectFile: handleSelectFile
        };
    }

    function bulkReassignFiles(scope) {
        if (scope.bulkActionDisabled) return;

        let selectedFiles = [];
        angular.forEach(scope.files, function (file) {
            if (file.selected === true) {
                selectedFiles.push(file);
            }
        });

        _fileService.selectedFiles = selectedFiles;
        let firmId = _sessionStorageService.getItem('firmId');
        _$location.url('/' + firmId + '/files/reassign');
    }

    function getBulkActionDisabledMessage() {
        return 'Limited to ' + _maximumNumberOfFilesPerAction + ' files or less';
    }

    function handleSelectFile(scope) {
        let numberOfSelectedItems = scope.files.filter(function (item) {
            return item.selected === true;
        }).length;
        let bulkActionDisabled = numberOfSelectedItems > _maximumNumberOfFilesPerAction;
        if (!scope.bulkActionDisabled && bulkActionDisabled) {
            _growl.error('You cannot download, reassign or delete more than ' + _maximumNumberOfFilesPerAction + ' files at once.');
        }
        scope.bulkActionDisabled = numberOfSelectedItems > _maximumNumberOfFilesPerAction;
    }

    module.exports = BulkFileActionsService;

})();