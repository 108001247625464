(function () {
    'use strict';

    UserEditService.$inject = [
        'errorService',
        'growl',
        '$location',
        'roleService'
    ];

    function UserEditService(errorService, growl, $location, roleService) {

        let service = {
            canEditUser: canEditUser,
            growlError: growlError,
            handleFailedInitialize: handleFailedInitialize,
            redirectToUserPage: redirectToUserPage
        };

        function canEditUser() {
            return roleService.canEditUser();            
        }

        function growlError(response) {
            if (response === 'email-taken') {
                growl.error('The email address already exists.');
            } else if (response.data.message === 'unable-to-change-firm-administrator') {
                growl.error('You cannot change the primary administrator\'s user type.');
            } else if (response.data.message === 'unable-to-deactivate-firm-administrator') {
                growl.error('You cannot deactivate the primary administrator.');
            } else if (response.data.message === 'unable-to-deactivate-last-user-visible-for-upload') {
                growl.error('You cannot deactivate last user with sharing option enabled.');
            } else if (response.data.message === 'unable-to-change-last-user-visible-for-upload') {
                growl.error('You cannot change user type to client of last user with sharing option enabled.');
            } else {
                growl.error('Something went wrong.  Please try again.');
            }
        }
        
        function handleFailedInitialize(response) {
            if (response.status === 404) {
                $location.path('/notFound').replace();
            } else {
                errorService.handleError(response);
            }
        }

        function redirectToUserPage(firmId) {
            $location.url('/' + firmId + '/users');
        }

        return service;
    }

    module.exports = UserEditService;
})();