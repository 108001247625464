(function () {
    'use strict';

    let _$routeParams;
    let _dataShareService;
    let _errorService;
    let _redirectService;
    let _roleService;
    let _timezoneService;

    LoginService.$inject = [
        '$routeParams',
        'dataShareService',
        'errorService',
        'redirectService',
        'roleService',
        'timezoneService'
    ];

    function LoginService($routeParams, dataShareService, errorService, redirectService, roleService, timezoneService) {
        _$routeParams = $routeParams;
        _dataShareService = dataShareService;
        _errorService = errorService;
        _redirectService = redirectService;
        _roleService = roleService;
        _timezoneService = timezoneService;

        return {
            getEmail: getEmail,
            getFirmId: getFirmId,
            getRedirectPath: getRedirectPath,
            handleError: handleError,
            setCanUploadFiles:setCanUploadFiles,
            setEmailToDataShare: setEmailToDataShare,
            setTimezone: setTimezone,
            setUserType: setUserType
        };
    }

    function getEmail() {
        return _$routeParams.email;
    }

    function getFirmId() {
        return _$routeParams.firmId;
    }

    function getRedirectPath() {
        return _redirectService.getRedirectPath();
    }

    function handleError(response) {
        _errorService.handleError(response);        
    }

    function setCanUploadFiles(canUploadFiles) {
        _roleService.setCanUploadFiles(canUploadFiles);        
    }

    function setEmailToDataShare(email) {
        _dataShareService.setData('email', email);
    }

    function setTimezone(timezone) {
        _timezoneService.setTimezone(timezone);
    }

    function setUserType(userType) {
        _roleService.setUserType(userType);        
    }

    module.exports = LoginService;
})();