﻿(function () {

    'use strict';

    ReCaptchaService.$inject = [
        '$window',
        'reCaptchaPublicKey'
    ];

    function ReCaptchaService($window, reCaptchaPublicKey) {
        let service = {
            create: create,
            getResponse: getResponse
        };

        return service;

        function create(controlId, callback) {
            let captchaId = null;
            if ($window.grecaptcha !== undefined) {
                captchaId = $window.grecaptcha.render(controlId, { sitekey: reCaptchaPublicKey, callback: callback });
            }
            return captchaId;
        }

        function getResponse(captchaId) {
            let response = null;
            if ($window.grecaptcha !== undefined) {
                response = $window.grecaptcha.getResponse(captchaId);
            }
            return response;
        }
    }

    module.exports = ReCaptchaService;
})();